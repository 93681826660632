import React, { Component } from 'react';
import { BuildingPart } from './BuildingPart';

interface panelState {

}

interface panelProp {
  compoent: BuildingPart;
  clickComponent: (buildingPart: BuildingPart) => void;
}

export class BlockPanelUI extends Component<panelProp, panelState> {
  render = () => {
    return (
      <div style={{ paddingLeft: '10px', cursor: 'pointer', color: 'white' }} key={this.props.compoent.uuid}>
        <div key={this.props.compoent.uuid} onClick={() => this.props.clickComponent(this.props.compoent)}>{this.props.compoent.name}</div>
        {this.props.compoent.buildingType === 'group' && this.props.compoent.parts.map(p => {
          return <BlockPanelUI key={p.uuid} compoent={p} clickComponent={this.props.clickComponent} />
        })}
      </div>
    )
  }
}


import * as THREE from '@teneleven/three';
import { NaverPoint } from './NaverMapManager';
import wkx from 'wkx'
import { mapProjectionData, screenInfo } from './DataTypes';
import { OrbitControls } from '@teneleven/three/examples/jsm/controls/OrbitControls'
import * as jsts from 'jsts';
import { Field } from './Field';

const { reproject } = require('reproject');

const projFrom = '+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs';
const projTo = "+proj=tmerc +lat_0=38 +lon_0=127 +k=1 +x_0=200000 +y_0=600000 +ellps=GRS80 +units=m +no_defs";

export class SceneManager {
  renderer: THREE.WebGLRenderer;
  scene: THREE.Scene;
  orthoCamera: THREE.OrthographicCamera;
  orthoControl: OrbitControls;

  perspectiveCamera: THREE.PerspectiveCamera;
  perspectiveControl: OrbitControls;

  renderCamera: THREE.Camera;
  mainControl: any;
  canvasElement: HTMLCanvasElement;

  canvasBox2: THREE.Box2;

  constructor() {
    this.renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true, preserveDrawingBuffer: true });
    this.canvasBox2 = new THREE.Box2();
    this.scene = new THREE.Scene();
    this.orthoCamera = new THREE.OrthographicCamera(-50, 50, 50, -50, 0.001, 10000);
    this.orthoCamera.position.set(0, 0, 1);
    this.orthoControl = new OrbitControls(this.orthoCamera, this.renderer.domElement);

    this.perspectiveCamera = new THREE.PerspectiveCamera(60, 3 / 4, 0.1, 10000);
    this.perspectiveControl = new OrbitControls(this.perspectiveCamera, this.renderer.domElement);

    this.perspectiveCamera.position.set(0, 10, -10);
    this.perspectiveCamera.lookAt(new THREE.Vector3(0, 0, 0));
    this.orthoControl.enableRotate = false;
    this.renderCamera = this.orthoCamera;
    this.canvasElement = this.renderer.domElement;
    this.mainControl = this.orthoControl;

    let rendererSize = new THREE.Vector2();
    this.renderer.getSize(rendererSize);
  }

  SceneInit = () => {
    this.renderer.setSize(window.innerWidth, window.innerHeight - 122);
    this.renderer.setClearColor(new THREE.Color(0x111a22), 1);
    this.renderer.state.setBlending(THREE.AdditiveBlending);

    this.orthoControl.screenSpacePanning = true;
    this.orthoControl.mouseButtons = {
      LEFT: THREE.MOUSE.RIGHT,
      MIDDLE: THREE.MOUSE.MIDDLE,
      RIGHT: THREE.MOUSE.LEFT
    }

    this.perspectiveControl.screenSpacePanning = true;
    this.perspectiveControl.mouseButtons = {
      LEFT: THREE.MOUSE.RIGHT,
      MIDDLE: THREE.MOUSE.MIDDLE,
      RIGHT: THREE.MOUSE.LEFT
    }
  }

  switchRenderCamera = (is2D: boolean) => {
    if (is2D) {
      this.renderCamera = this.orthoCamera;
      this.orthoControl.enabled = true;
      this.perspectiveControl.enabled = false;
    }
    else {
      this.renderCamera = this.perspectiveCamera;
      this.orthoControl.enabled = false;
      this.perspectiveControl.enabled = true;
    }
  }

  set3DViewerCameraPosition = (position: THREE.Vector3) => {
    this.perspectiveControl.target = position;
    this.perspectiveControl.object.position.set(position.x, position.y + 10, position.z + 10);
    this.perspectiveControl.update();
  }

  render = () => {
    this.renderer.render(this.scene, this.renderCamera);
  }

  addObjectToScene = (object: THREE.Object3D) => {
    this.scene.add(object);
  }

  CameraFrustumResize = (frustumHalfWidth: number, aspect: number) => {
    this.orthoCamera.left = -frustumHalfWidth;
    this.orthoCamera.right = frustumHalfWidth;
    this.orthoCamera.top = frustumHalfWidth / aspect;
    this.orthoCamera.bottom = -frustumHalfWidth / aspect;
    this.orthoCamera.updateProjectionMatrix();
    this.perspectiveCamera.aspect = aspect;
    this.perspectiveCamera.updateProjectionMatrix();

    this.resizeCanvasBBox()
  }

  resizeCanvasBBox = () => {
    this.canvasBox2.makeEmpty();
    let rect = this.canvasElement.getBoundingClientRect();
    let renderSize = new THREE.Vector2(0);
    this.renderer.getSize(renderSize);
    this.canvasBox2.expandByPoint(new THREE.Vector2(rect.left, rect.top));
    this.canvasBox2.expandByPoint(new THREE.Vector2(rect.left + renderSize.x, rect.top + renderSize.y));
  }

  getScreenCapture = (width: number, height: number, bbox: THREE.Box3) => {
    let preSize = new THREE.Vector2(0);
    this.renderer.getSize(preSize);
    let frustum = this.orthoCamera.right;
    let clearAlpha = this.renderer.getClearAlpha();
    this.renderer.setClearAlpha(0);
    let exPos = this.orthoCamera.position.clone();
    let exTarget = this.orthoControl.target.clone();
    let exZoom = this.orthoCamera.zoom;

    let bboxCenter = new THREE.Vector3();
    let bboxSize = new THREE.Vector3()
    bbox.getCenter(bboxCenter);
    bbox.getSize(bboxSize);

    this.orthoCamera.zoom = 1;
    this.orthoCamera.position.set(bboxCenter.x, bboxCenter.y, 1);
    this.orthoControl.target.set(bboxCenter.x, bboxCenter.y, bboxCenter.z);

    if (bboxSize.x > bboxSize.y) {
      let f = bboxSize.x * 0.55;
      this.orthoCamera.right = f;
      this.orthoCamera.left = -f;
      this.orthoCamera.top = f * (height / width);
      this.orthoCamera.bottom = -f * (height / width);
    }
    else {
      let f = bboxSize.y * 0.55;
      this.orthoCamera.top = f;
      this.orthoCamera.bottom = -f;
      this.orthoCamera.right = f * (width / height);
      this.orthoCamera.left = -f * (width / height);
    }

    this.orthoCamera.updateProjectionMatrix();
    this.renderer.setSize(width, height);

    this.renderer.render(this.scene, this.orthoCamera);

    let imgData = this.renderer.domElement.toDataURL("image/png");
    imgData = imgData.slice(22);

    this.renderer.setClearAlpha(clearAlpha);
    this.renderer.render(this.scene, this.orthoCamera);

    this.orthoCamera.zoom = exZoom;
    this.orthoCamera.position.set(exPos.x, exPos.y, exPos.z);
    this.orthoControl.target.set(exTarget.x, exTarget.y, exTarget.z);

    this.CameraFrustumResize(frustum, preSize.x / preSize.y);
    this.renderer.setSize(preSize.x, preSize.y);
    this.resizeCanvasBBox();

    return Buffer.from(imgData, 'base64');
  }

  setRendererAlpha = (value: number) => {
    this.renderer.setClearAlpha(value);
  }

  getControl = () => {
    return this.mainControl;
  }

  getScreenPosition(point: THREE.Vector3) {
    let mvp = this.orthoCamera.projectionMatrix.clone().multiply(this.orthoCamera.matrixWorldInverse);
    let offset = this.canvasElement.getBoundingClientRect() as DOMRect;
    let rendererSize = new THREE.Vector2();
    this.renderer.getSize(rendererSize);
    let np = point.clone().applyMatrix4(mvp);
    let nx = (np.x + 1) * 0.5 * rendererSize.x;
    let ny = (1 - (np.y + 1) * 0.5) * rendererSize.y;
    return new THREE.Vector2(nx + offset.left, ny + offset.top);
  }

  getLatLonPosition(point: THREE.Vector3, mapProjData: mapProjectionData) {
    let np = this.getScreenPosition(point);
    return mapProjData.projection.fromPageXYToCoord(NaverPoint(np.x, np.y));
  }

  getScreenInfomation = (): screenInfo => {
    let mvp = this.orthoCamera.projectionMatrix.clone().multiply(this.orthoCamera.matrixWorldInverse);
    let offset = this.canvasElement.getBoundingClientRect() as DOMRect;
    let rendererSize = new THREE.Vector2();
    this.renderer.getSize(rendererSize);

    return { mvp: mvp, offset: offset, rendererSize: rendererSize }
  }

  // to-do: delete funtion
  getFieldWKTFile = (field: Field, mapProjData: mapProjectionData, objectMatrix: THREE.Matrix4) => {
    let polygonWKTArray: string[] = [];
    if (field.getLayer()) {
      field.getLayer()!.polygons.forEach(bp => {
        if (!bp.motherPolygon) {
          let bodyPolygon: any[] = [];
          let polygon: any[] = []; 
          bp.vertices.forEach(v => {
            let pos = this.getLatLonPosition(v.clone().applyMatrix4(objectMatrix), mapProjData);
            bodyPolygon.push([pos.x + mapProjData.mapOffset.x, pos.y + mapProjData.mapOffset.y]);
          })

          polygon.push(bodyPolygon);
          field.getLayer()!.polygons.forEach(hp => {
            if (hp.motherPolygon === bp) {
              let hole: any[] = [];
              hp.vertices.forEach(v => {
                let pos = this.getLatLonPosition(v.clone().applyMatrix4(objectMatrix), mapProjData);
                hole.push([pos.x + mapProjData.mapOffset.x, pos.y + mapProjData.mapOffset.y]);
              })
              polygon.push(hole);
            }
          })
          let geoJSON = {
            type: 'Polygon',
            coordinates: polygon,
          }
          if (bp.selected && polygon[0].length > 3) {
            polygonWKTArray.push(GeotoWKT(geoJSON));
          }
        }
      })
    }
    return polygonWKTArray;
  }

  unionWKTPolygon = (wktArray: string[]) => {
    let reader = new jsts.io.WKTReader();
    let writer = new jsts.io.WKTWriter();
    let unionGeo: jsts.geom.Geometry = reader.read(wktArray[0]);
    wktArray.forEach(wkt => {
      unionGeo = unionGeo.union(reader.read(wkt));
    })
    //@ts-ignore
    return writer.write(unionGeo);
  }

  getPointToMap = (point: THREE.Vector3, mapProjData: mapProjectionData,) => {

    let llPoint = this.getLatLonPosition(point, mapProjData);

    let geoJSON = {
      type: 'Polygon',
      coordinates: [llPoint.x + mapProjData.mapOffset.x, llPoint.y + mapProjData.mapOffset.y],
    }

    let output = reproject(geoJSON, projFrom, projTo);

    return new THREE.Vector3(output.coordinates[0], output.coordinates[1], 0);
  }

  getPointArrayToMap = (points: THREE.Vector3[], mapProjData: mapProjectionData) => {
    let coords: any[] = [];
    points.forEach(p => {
      let llPoint = this.getLatLonPosition(p, mapProjData);
      coords.push([llPoint.x + mapProjData.mapOffset.x, llPoint.y + mapProjData.mapOffset.y]);
    });

    let geoJson = {
      type: 'Polygon',
      coordinates: coords,
    }

    let tmCoords = reproject(geoJson, projFrom, projTo).coordinates;
    let tmPoints: THREE.Vector2[] = [];

    tmCoords.forEach((c: any) => {
      tmPoints.push(new THREE.Vector2(c[0], c[1]))
    });

    return tmPoints;
  }
}

// latlng to wkt
export function GeotoWKT(geoJson: any) {
  if (geoJson.coordinates.length > 0)
    return wkx.Geometry.parseGeoJSON(reproject(geoJson, projFrom, projTo)).toWkt();
  else
    return '';
}

// wkt to latlng
export function WKTtoGeom(wkt: string) {
  return reproject(wkx.Geometry.parse(wkt).toGeoJSON(), projTo, projFrom);
}

export function jstsPolygontoWKT(polygon: jsts.geom.Geometry) {
  let coords: any[] = [];
  let holes: any[] = [];
  //@ts-ignore
  polygon.getExteriorRing().getCoordinates().forEach(c => {
    coords.push([c.x, c.y]);
  })
  //@ts-ignore
  polygon._holes.forEach(h => {
    let hole: any[] = [];
    h.getCoordinates().forEach((c: any) => {
      hole.push([c.x, c.y]);
    })
    holes.push(hole);
  })

  let geoJSON = {
    type: 'Polygon',
    coordinates: [coords].concat(holes),
  }
  return wkx.Geometry.parseGeoJSON(geoJSON).toWkt();
}

export function TMWKT2LanLatWKT(wkt: string[]) {
  let llWKT: string[] = [];

  wkt.forEach(w => {
    let js = reproject(wkx.Geometry.parse(w).toGeoJSON(), projTo, projFrom);
    llWKT.push(wkx.Geometry.parseGeoJSON(js).toWkt());
  });

  return llWKT;
}

export function latlng2tm(latlng: THREE.Vector2) {
  let geoJSON = {
    type: 'Polygon',
    coordinates: [[latlng.x, latlng.y]],
  }

  let x = reproject(geoJSON, projFrom, projTo);

  return new THREE.Vector2(x.coordinates[0][0], x.coordinates[0][1]);
}

export function tm2latlng(tm: THREE.Vector2) {
  let geoJSON = {
    type: 'Polygon',
    coordinates: [[tm.x, tm.y]],
  }

  let x = reproject(geoJSON, projTo, projFrom);

  return new THREE.Vector2(x.coordinates[0][0], x.coordinates[0][1]);
}

import React, { Component, HTMLAttributes, Fragment, ReactText } from 'react';
import SessionComponent from './SessionComponent';
import './css/ProjectFormMap.scss';

import DrawingManager2 from './DrawingManager2';
import { Map, MapOptions, Polygon, Circle, Polyline, Shape, ProjectSitePolygon, Marker, InfoWindowOptions, InfoWindow } from './Shape';
import { Button, Avatar, Checkbox, Input, InputAdornment, Snackbar, Paper, Popper, Grow, ClickAwayListener, MenuList, MenuItem, IconButton } from '@material-ui/core';

import RequireIcon from '@material-ui/icons/Lens';
import CheckIcon from '@material-ui/icons/Check';
import BackIcon from '@material-ui/icons/ArrowBack';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import WarningIcon from '@material-ui/icons/ReportProblem';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import HelpIcon from '@material-ui/icons/HelpOutline';
import GpsIcon from '@material-ui/icons/GpsFixed';

import { default as _ } from 'lodash';
import ProjectInput, { ProjectFormPropsBase } from './ProjectInput';
import { ProjectSiteType, ShapeType, Project, BuildingType, ProjectUseDistrict } from './model/Project';

import * as turf from '@turf/turf';
import Tabs from './Tabs';
import ProjectImport, { ProjectImportType } from './ProjectImport';
import AWSModule from './AWSModule';
import Modal, { ModalOptions } from './Modal';
import proj4 from 'proj4';
import graphlib, { Graph } from 'graphlib';

import lambdaSchema from '@teneleven/platform-buildit-schema'; // labmda schema
import App from './App';
import { ReferenceObject } from 'popper.js';
import BuilditInput from './BuilditInput';
import SearchPlaceInput, { PlaceResult, AddressResult, GeocodeResult } from './SearchPlaceInput';

import axios from 'axios';
import { DarkSwitch } from './CustomSwitch';
import ProjectFormMapRoad from './ProjectFormMapRoad';
import Tooltip from './Tooltip';
import Switch from './Switch';
import jquery from 'jquery';
import { windowMaterial } from './CADConverter/DataTypes';
import { JiguInfo, reset_type } from './ProjectForm';
import { projectInputValidCheckSome, projectInputValidCheck } from './Constraints';
import ConstructionLineInfo from './ConstructionLineInfo';
const $ = jquery;

const jsts = require('jsts');

export interface RoadPathSet {
  roadSpacePolygonPath: any[];
  roadVeritcalPolylinePath: any[];
  roadHorizontalPolylinePath: any[];
}
/*
  TODO: first and last vertex equal
  buildit-ai-project-road-site: road site lambda
*/



export interface ProjectFormMapProps extends ProjectFormPropsBase {
  resetNum: number;
  onResetProject: (fixField?: Partial<Project>, callback?: Function) => void;
  setModal: (open: boolean, options?: ModalOptions) => void;
  setOpenMapConfig: (open: boolean) => void;
  setOpenImport: (open: boolean) => void;
  setOpenRoadConfig: (open: boolean) => void;
  setOpenJiguInfo: (open: boolean) => void;
  onCreateProject: () => void;
  onUpdateAddress: (callback?: Function) => void;
  setLoading: (on: boolean, callback?: Function) => void;
  setOpenDetailConfig: (open: boolean) => void;
  onSaveProject: (callback?: Function) => void;
  setCadastralMap: (on: boolean) => void;
  updateJiguInfo: (jiguInfo: Array<Array<JiguInfo>>) => void;
  onUpdateDefaults: (set_buildingType?: BuildingType, set_use_distirct?: ProjectUseDistrict, reset?: reset_type) => void;
  openImport: boolean;
  openRoadConfig: boolean;
  openMapConfig: boolean;
  edit: boolean;
  cadastralMap: boolean;
  openJiguInfo: boolean;
  jiguInfo: Array<Array<JiguInfo>>;
}

export interface ProjectFormMapState {
  importType: ProjectImportType;
  projectSiteType: ProjectSiteType;
  shapeType: ShapeType | "NULL";
  resetNum: number;
  selectedRoads: Array<number>; // 인접도로설정 대지영역 check
  selectedRoad: Array<{ polygonIndex: number, roadIndex: number }>; // 대지영역 도로 check
  selectedRoadValue: number; // 선택된도로 input value
  drawable: boolean; // 중복그리기 방지
  openSnack: boolean;
  selectedJimog: string;
  searchAddress: string;
  searchFieldFocus: boolean;
  isRoadDrawing: boolean; // drawRoads 여러번 불리는것 방지
  preProject: Project;
  placeResult: Array<PlaceResult>;
  addressResult: Array<AddressResult>;
  geocodeResult: Array<GeocodeResult>;
  searchPlaceLoading: boolean;
  siteTooltip: boolean;
  roadActiveList: boolean[][];
  shapeVertexLength: number; // ESC, RightClick 으로 종료했을때 sync 맞춰주기위해서
  measureMode?: "DISTANCE" | "AREA";
  mapCenter?: [number, number],
  projectSiteOffset?: number, // 대지면적 오차
}



export default class ProjectFormMap extends SessionComponent<ProjectFormMapProps, ProjectFormMapState> {
  state: ProjectFormMapState = {
    importType: 'MY_SITE',
    projectSiteType: this.props.currentProject.project_site_type && this.props.currentProject.project_site_type || "SELECT",
    resetNum: this.props.resetNum,
    selectedRoads: [],
    selectedRoad: [],
    selectedRoadValue: 0,
    drawable: true,
    openSnack: false,
    searchAddress: "",
    selectedJimog: "",
    searchFieldFocus: false,
    isRoadDrawing: true,
    preProject: this.props.currentProject,
    placeResult: [],
    addressResult: [],
    geocodeResult: [],
    searchPlaceLoading: false,
    siteTooltip: window.localStorage.getItem("site_tooltip") === null ? true : window.localStorage.getItem("site_tooltip") === "true",
    roadActiveList: [],
    shapeVertexLength: 0,
    shapeType: this.props.currentProject.project_site_type !== "IMPORT" ?
      (this.props.currentProject.discountable ? "ROAD" : "PROJECT_SITE")
      : (this.props.currentProject.selected_my_building_plan_id === undefined ? "SKYLINE_CIRCLE" : "NULL"),
    projectSiteOffset: 0,
  }

  anchorEl?: null | HTMLElement | ReferenceObject | ((element: HTMLElement) => HTMLElement);
  dm: DrawingManager2;
  map?: Map;
  projectSite: ProjectSitePolygon[] = [];
  boundarySite: Array<Polygon | null> = [];
  boundaryExtendedSite: Array<Polygon | null> = [];
  vacancyInside: Polygon[] = [];
  vacancyOutside: Polygon[] = [];
  skylineCircle: Circle[] = [];
  skylineLine: Polyline[] = [];
  roadSite: Array<Polygon | null> = [];
  roadValue: number[][] = [];
  roadActive: boolean[][] = [];
  openRoad: boolean[] = [];
  startMarker: Marker[] = [];
  endMarker: Marker[] = [];
  pnuList: string[] = [];
  selectedProjectSiteGraph = new Graph();
  infoWindow?: InfoWindow;
  jiguInfoPolygonHover?: Polygon;
  jiguInfoPolygonActive?: Polygon;
  projectSiteOffset?= 0;

  suitProjectSiteType = (): ShapeType | "NULL" => {
    let type: ShapeType | "NULL";
    if (this.props.currentProject.project_site_type !== "IMPORT") {
      if (this.props.currentProject.discountable) {
        type = "ROAD";
      } else {
        type = "PROJECT_SITE";
      }
    } else {
      if (this.props.currentProject.project_type! === "AI") {
        if (this.props.currentProject.building_type === "아파트") {
          type = "SKYLINE_CIRCLE";
        } else {
          type = "NULL";
        }
      } else if (["DESIGNER", "SITE_PLAN"].includes(this.props.currentProject.project_type!)) {
        type = "NULL";
      } else if (this.props.currentProject.selected_my_building_plan_id === undefined && this.props.currentProject.selected_my_site_id === undefined) {
        type = "NULL";
      } else if (this.props.currentProject.building_type !== "아파트") {
        type = "NULL";
      } else {
        type = "SKYLINE_CIRCLE";
      }
    }

    return type;
  }

  constructor(props: ProjectFormMapProps) {
    super(props);
    this.dm = new DrawingManager2();

    this.handleKeyPress = this.handleKeyPress.bind(this);
  }



  componentWillMount = () => {
    document.addEventListener('keydown', this.handleKeyPress);
    this.redrawRoads = _.debounce(this.redrawRoads, 300);
    this.searchPlace = _.debounce(this.searchPlace, 200);
    this.onUpdateShape = _.debounce(this.onUpdateShape, 50);
  }
  componentWillUnmount = () => {
    document.removeEventListener('keydown', this.handleKeyPress);
  }
  componentDidMount = () => {
    this.initialMap();
    this.setInfoWindowOfSiteTooltip();
    try {
      this.setShapeOnMap();
    } catch (e) {
      console.log(e)
      this.props.setModal(true, {
        negative: "hidden",
        title: "오류 안내",
        content: <div>그릴 수 없는 나의 파일입니다.</div>,
        type: "SIMPLE",
        color: "DARK",
        positiveTitle: "확인",
      });
    }
    if (this.props.edit) {


      if (this.props.currentProject.project_site_area && this.state.shapeType === "PROJECT_SITE") {
        //console.log(this.props.currentProject.project_site_area);

        const polygonArea = this.dm.sumOfSite(this.projectSite.map(p => DrawingManager2.toWKT(p)));
        const offset = Math.abs(Number(this.props.currentProject.project_site_area!.toFixed(2)) - Number(polygonArea.toFixed(2))) / Number(this.props.currentProject.project_site_area!.toFixed(2));

        //   console.log(polygonArea.toFixed(2), this.props.currentProject.project_site_area.toFixed(2), offset, 'offset');
        this.setState({ projectSiteOffset: offset })
      } else {
        this.setState({ projectSiteOffset: 0 })
      }
    }
  }

  initialMap = (center?: number[]) => {
    const options: MapOptions = {
      ref: this.refs.map,
      cadastral: this.props.cadastralMap,
      maxZoom: 21,
      transitionOptions: {
        duration: 0,
        easing: "linear",
      },
      tileSpare: 1,
    }
    this.map = this.dm.createMap(options);




    this.map.on('click', (e) => {
      if (this.state.measureMode === undefined) {
        // 필지모드 동작
        if (this.state.projectSiteType === "SELECT" && this.state.shapeType === "PROJECT_SITE") {
          const coord = proj4(DrawingManager2.nProj, DrawingManager2.bProj, [e.coord.x, e.coord.y]);
          this.selectPNU({ x: coord[0], y: coord[1] });
        }
      } else {
        this.map!.emit('m_click', e);
      }

      if ((["PROJECT_SITE", "VACANCY_INSIDE", "VACANCY_OUTSIDE"].includes(this.state.shapeType) || this.state.measureMode !== undefined) && this.state.drawable === false) {
        this.setState({ shapeVertexLength: this.state.shapeVertexLength + 1 })
      }
    });

    this.map.on('idle', (e) => {
      this.setState({ mapCenter: this.map!.getCenter() })
    })

    // this.props.onUpdateAddress = _.debounce(this.props.onUpdateAddress, 1000);
  }


  setInfoWindowOfSiteTooltip = () => {
    let infoWindowStyle = [
      "width: 125px",
      "background-color: black",
      "color:#ccc",
      "font-size: 12px",
      "padding: 10px",
      "box-sizing: border-box",
    ]

    const infoWindowOptions: InfoWindowOptions = {
      pixelOffset: this.dm!.newPoint(150, 0),
      borderWidth: 0,
      disableAnchor: true,
      disableAutoPan: true,
    }

    this.infoWindow = this.dm!.addInfoWindow(infoWindowOptions);
    const infoWindowContent: string[] = [
      `<div style="${infoWindowStyle.join("; ")}">`,
      `필지를 클릭해주세요`,
      `</div>`
    ]

    this.map!.on('mousemove', _.debounce((e) => {
      // 면적재기 / 길이재기에대한 툴팁도 할지 고민
      if (this.state.siteTooltip && this.state.measureMode === undefined) {
        let msg = "";
        if (this.state.projectSiteType === "SELECT") {
          if (this.state.drawable) {
            switch (this.state.shapeType) {
              case "PROJECT_SITE":
                infoWindowStyle[0] = "width: 125px";
                msg = "필지를 클릭해주세요";
                break;
              case "ROAD":
                infoWindowStyle[0] = "width: 120px";
                if (this.projectSite.length > 0) {
                  msg = '인접도로 탭 오른쪽<br />"설정하기" 버튼을<br />클릭해주세요';
                } else {
                  msg = '대지영역을<br />먼저 설정해주세요';
                }
                break;
              case "VACANCY_INSIDE":
                infoWindowStyle[0] = "width: 115px";
                msg = '특수영역 탭 오른쪽<br />"설정하기" 버튼을<br />클릭해주세요';
                break;
              case "VACANCY_OUTSIDE":
                infoWindowStyle[0] = "width: 115px";
                msg = '공지영역 탭 오른쪽<br />"설정하기" 버튼을<br />클릭해주세요';
                break;
              case "SKYLINE_CIRCLE":
              case "SKYLINE_LINE":
                infoWindowStyle[0] = "width: 135px";
                msg = '스카이라인 탭 오른쪽<br />"범위" / "방향"<br />버튼을 클릭해주세요';
                break;
            }
          } else {
            switch (this.state.shapeType) {
              case "PROJECT_SITE":
                infoWindowStyle[0] = "width: 125px";
                msg = "필지를 클릭해주세요"; break;
              case "VACANCY_INSIDE":
              case "VACANCY_OUTSIDE":
                infoWindowStyle[0] = "width: 200px";
                msg = "마우스 오른쪽 클릭 또는 'ESC'키를<br/>눌러 마칩니다 (폴리곤)"; break;
              case "SKYLINE_CIRCLE":
              case "SKYLINE_LINE":
                infoWindowStyle[0] = "width: 85px";
                msg = "범위 (원)<br />방향 (라인)"
                break;
            }
          }
        } else if (this.state.projectSiteType === "DRAW") {
          if (this.state.drawable) {
            switch (this.state.shapeType) {
              case "PROJECT_SITE":
                infoWindowStyle[0] = "width: 125px";
                msg = '대지영역 탭 오른쪽<br />"설정하기" 버튼을<br />클릭해주세요'; break;
              case "ROAD":
                infoWindowStyle[0] = "width: 120px";
                if (this.projectSite.length > 0) {
                  msg = '인접도로 탭 오른쪽<br />"설정하기" 버튼을<br />클릭해주세요';
                } else {
                  msg = '대지영역을<br />먼저 설정해주세요';
                }
                break;
              case "VACANCY_INSIDE":
                infoWindowStyle[0] = "width: 115px";
                msg = '특수영역 탭 오른쪽<br />"설정하기" 버튼을<br />클릭해주세요';
                break;
              case "VACANCY_OUTSIDE":
                infoWindowStyle[0] = "width: 115px";
                msg = '공지영역 탭 오른쪽<br />"설정하기" 버튼을<br />클릭해주세요';
                break;
              case "SKYLINE_CIRCLE":
              case "SKYLINE_LINE":
                infoWindowStyle[0] = "width: 135px";
                msg = '스카이라인 탭 오른쪽<br />"범위" / "방향"<br />버튼을 클릭해주세요';
                break;
            }
          } else {
            switch (this.state.shapeType) {
              case "PROJECT_SITE":
              case "VACANCY_INSIDE":
              case "VACANCY_OUTSIDE":
                infoWindowStyle[0] = "width: 200px";
                msg = "마우스 오른쪽 클릭 또는 'ESC'키를<br/>눌러 마칩니다 (폴리곤)"; break;
              case "SKYLINE_CIRCLE":
              case "SKYLINE_LINE":
                infoWindowStyle[0] = "width: 85px";
                msg = "범위 (원)<br />방향 (라인)"
                break;
            }
          }
        } else if (this.state.projectSiteType === "IMPORT") {
          if (this.state.drawable) {
            if (this.props.openImport) {
              infoWindowStyle[0] = "width: 110px";
              msg = `${this.state.importType === "MY_SITE" && "나의 사업영역" || "나의 배치안"} 파일을<br />선택해주세요`
            } else {
              switch (this.state.shapeType) {
                case "SKYLINE_CIRCLE":
                case "SKYLINE_LINE":
                  infoWindowStyle[0] = "width: 135px";
                  msg = '스카이라인 탭 오른쪽<br />"범위" / "방향"<br />버튼을 클릭해주세요'; break;
              }
            }
          } else {
            switch (this.state.shapeType) {
              case "SKYLINE_CIRCLE":
              case "SKYLINE_LINE":
                infoWindowStyle[0] = "width: 85px";
                msg = "범위 (원)<br />방향 (라인)"
                break;
            }
          }
        }
        infoWindowContent[0] = `<div style="${infoWindowStyle.join(";")}">`;
        infoWindowContent[1] = msg;
        if (this.infoWindow) {
          if (this.props.openRoadConfig) {
            this.infoWindow.close();
          } else {
            this.infoWindow.show(infoWindowContent.join(""));
          }
        }
      } else {
        this.map!.emit('m_mousemove', e);
      }
    }, 0.5));

    this.map!.on('mouseout', () => {
      this.infoWindow && this.infoWindow.close();
    });
  }

  componentDidUpdate = async (pp: Readonly<ProjectFormMapProps>, ps: Readonly<ProjectFormMapState>) => {
    // 토지이용계획
    if (this.props.openJiguInfo || !_.isEqual(this.props.jiguInfo, pp.jiguInfo)) {

      let activeOutIndex = -1;
      let activeInIndex = -1;
      let hoverOutIndex = -1;
      let hoverInIndex = -1;

      activeOutIndex = this.props.jiguInfo.findIndex(r => r.findIndex(rr => rr.active === true) > -1);
      hoverOutIndex = this.props.jiguInfo.findIndex(r => r.findIndex(rr => rr.hover === true) > -1);

      activeOutIndex > -1 && (activeInIndex = this.props.jiguInfo[activeOutIndex].findIndex(r => r.active === true));
      hoverOutIndex > -1 && (hoverInIndex = this.props.jiguInfo[hoverOutIndex].findIndex(r => r.hover === true));
      this.jiguInfoPolygonActive && this.jiguInfoPolygonActive.remove();
      this.jiguInfoPolygonHover && this.jiguInfoPolygonHover.remove();

      if (activeOutIndex < 0 && hoverOutIndex < 0) {
        return;
      }



      if (activeInIndex > -1) {
        const path = DrawingManager2.toGeom(this.props.jiguInfo[activeOutIndex][activeInIndex].wkt!, "MultiPolygon").coordinates;
        this.jiguInfoPolygonActive = this.dm!.addPolygon(path[0], DrawingManager2.DrawingOption.JIGU_ACTIVE, false);
      }

      if (hoverInIndex > -1) {
        const path = DrawingManager2.toGeom(this.props.jiguInfo[hoverOutIndex][hoverInIndex].wkt!, "MultiPolygon").coordinates;
        this.jiguInfoPolygonHover = this.dm!.addPolygon(path[0], DrawingManager2.DrawingOption.JIGU_HOVER, false);
      }

    } else {
      this.jiguInfoPolygonActive && this.jiguInfoPolygonActive.remove();
      this.jiguInfoPolygonHover && this.jiguInfoPolygonHover.remove();
    }

    // 지적도 & 지도
    if (!_.isEqual(this.props.cadastralMap, pp.cadastralMap)) {
      this.dm!.setCadastralLayer(this.props.cadastralMap);
    }

    // 도로 관련
    // check된 내역 해제
    if (!this.props.openRoadConfig && pp.openRoadConfig) {
      this.setState({
        selectedRoads: [],
        selectedRoad: [],
        selectedRoadValue: 0,
        drawable: true,
      })
      this.roadActive.map((r, i) => r.map((rr, j) => this.roadActive[i][j] = false));
      if (this.props.currentProject.auto_road === false) {
        this.forceRedrawRoads();
      }
    }
    // 초기화 하는경우
    if (this.state.resetNum !== this.props.resetNum) {
      this.state.drawable === false && this.clearDrawingManager();
      this.props.setOpenImport(false);
      this.props.setOpenRoadConfig(false);
      this.props.setOpenDetailConfig(false);
      this.props.setOpenMapConfig(true);
      this.props.setOpenJiguInfo(false);

      // 그리는중에 reset하는 것
      // 사업영역설정 탭이 이동하지 안흔 것 (모드)
      // 사업영역설정 탭이 이동하지 앟는 것 (타입)
      // 상세설정이 열려있는데 안닫기는 것
      this.clearAllShapeOnMap();
      this.clearMeasureShapeOnMap();
      try {
        this.setShapeOnMap();
      } catch (e) {
        console.log(e)
        this.props.setModal(true, {
          negative: "hidden",
          title: "오류 안내",
          content: <div>그릴 수 없는 나의 파일입니다.</div>,
          type: "SIMPLE",
          color: "DARK",
          positiveTitle: "확인",
        });
      }


      let shapeType: ShapeType | "NULL" = this.suitProjectSiteType();

      this.setState({
        resetNum: this.props.resetNum,
        projectSiteType: this.props.currentProject.project_site_type!,
        preProject: this.props.currentProject,
        shapeType: shapeType,
      }, () => {
        this.props.onUpdateAddress(() => {
          // if (this.props.currentProject.auto_setting) {
          //   this.props.onUpdateDefaults();
          // }
          this.props.onUpdateDefaults(undefined, undefined, "All");
        });
      });
    } else {
      const pr = pp.currentProject.road_value;
      const r = this.props.currentProject.road_value;

      if (this.props.currentProject.road_value!.length > 0) {
        if (!_.isEqual(pr, r) && pr!.length > 0) {
          this.redrawRoads(pr, r);
        }
      }

      if (this.state.shapeType !== "ROAD" && this.props.openRoadConfig === true) {
        this.props.setOpenRoadConfig(false);
      }

      // Area Type이 바뀌면 ( z-index / clickable 설정 )
      if (this.state.shapeType !== ps.shapeType) {
        this.projectSite.map(p => { p.getEditable() === true && p.setEditable(false) });
        this.vacancyInside.map(p => { p.getEditable() === true && p.setEditable(false) });
        this.vacancyOutside.map(p => { p.getEditable() === true && p.setEditable(false) });
        this.skylineCircle.map(p => { p.getEditable() === true && p.setEditable(false) });
        // this.skylineLine.map((p, i) => {
        //   this.startMarker[i].getEditable() === true && this.startMarker[i].setEditable(false);
        //   this.endMarker[i].getEditable() === true && this.endMarker[i].setEditable(false);
        // });

        if (this.state.drawable === false) {
          this.clearDrawingManager();
          this.setState({ drawable: true });
        }
      }

      // AI 프로젝트에서 건물종류가 바뀌면 (스카이라인에 있었던 경우에만 변경한다)
      if (!_.isEqual(this.props.currentProject.building_type, pp.currentProject.building_type)) {
        if (ps.shapeType === "SKYLINE_CIRCLE" || ps.shapeType === undefined || ps.shapeType === "NULL") {
          this.setState({ shapeType: this.suitProjectSiteType() })
        }
      }


      if (this.state.shapeType !== "PROJECT_SITE" && this.state.projectSiteType === "SELECT") {
        this.selectedProjectSiteGraph.nodes().map(p => this.selectedProjectSiteGraph.node(p).setOptions({ visible: false, clickable: false }));
        this.projectSite.map(p => p.setOptions({ visible: true }));
      } else if (this.state.shapeType === "PROJECT_SITE" && this.state.projectSiteType === "SELECT") {
        this.selectedProjectSiteGraph.nodes().map(p => this.selectedProjectSiteGraph.node(p).setOptions({ visible: true, clickable: true }));
        this.projectSite.map(p => p.setOptions({ visible: false }));
      } else {
        this.projectSite.map(p => p.setOptions({ visible: true }));
      }
      this.selectedProjectSiteGraph.nodes().map(p => {
        this.selectedProjectSiteGraph.node(p).setOptions({
          clickable: this.state.shapeType === "PROJECT_SITE" && this.state.measureMode === undefined,
          zIndex: (this.state.shapeType === "PROJECT_SITE" && this.state.projectSiteType === "SELECT") ? 0 : DrawingManager2.DrawingOption.PROJECT_SITE.zIndex
        })
      });
      this.projectSite.map(p => p.setOptions({
        clickable: this.state.shapeType === "PROJECT_SITE" && this.state.measureMode === undefined,
        zIndex: this.state.shapeType === "PROJECT_SITE" ? 0 : DrawingManager2.DrawingOption.PROJECT_SITE.zIndex
      }));
      this.boundaryExtendedSite.map(p => p !== null && p.setOptions({ visible: this.state.shapeType === "ROAD" }))

      this.vacancyInside.map(p => p.setOptions({
        clickable: this.state.shapeType === "VACANCY_INSIDE" && this.state.measureMode === undefined,
        zIndex: this.state.shapeType === "VACANCY_INSIDE" ? 0 : DrawingManager2.DrawingOption.VACANCY_INSIDE.zIndex
      }));
      this.vacancyOutside.map(p => p.setOptions({
        clickable: this.state.shapeType === "VACANCY_OUTSIDE" && this.state.measureMode === undefined,
        zIndex: this.state.shapeType === "VACANCY_OUTSIDE" ? 0 : DrawingManager2.DrawingOption.VACANCY_OUTSIDE.zIndex
      }));
      this.skylineCircle.map(p => p.setOptions({
        clickable: ["SKYLINE_CIRCLE", "SKYLINE_LINE"].includes(this.state.shapeType) && this.state.measureMode === undefined,
        zIndex: ["SKYLINE_CIRCLE", "SKYLINE_LINE"].includes(this.state.shapeType) ? 0 : DrawingManager2.DrawingOption.SKYLINE_CIRCLE.zIndex
      }));
      this.skylineLine.map(p => p.setOptions({
        clickable: ["SKYLINE_CIRCLE", "SKYLINE_LINE"].includes(this.state.shapeType) && this.state.measureMode === undefined,
        zIndex: ["SKYLINE_CIRCLE", "SKYLINE_LINE"].includes(this.state.shapeType) ? 0 : DrawingManager2.DrawingOption.SKYLINE_LINE.zIndex
      }));
      this.startMarker.map(p => {
        p.setOptions({
          clickable: ["SKYLINE_CIRCLE", "SKYLINE_LINE"].includes(this.state.shapeType) && this.state.measureMode === undefined,
          visible: ["SKYLINE_CIRCLE", "SKYLINE_LINE"].includes(this.state.shapeType)
        })
      })
      this.endMarker.map(p => p.setOptions({
        clickable: ["SKYLINE_CIRCLE", "SKYLINE_LINE"].includes(this.state.shapeType) && this.state.measureMode === undefined,
        visible: ["SKYLINE_CIRCLE", "SKYLINE_LINE"].includes(this.state.shapeType)
      }))
    }


    if (!_.isEqual(pp.currentProject.building_type, this.props.currentProject.building_type)) {
      if (pp.currentProject.building_type! === "아파트" && (pp.currentProject.skyline_circle!.length > 0 || pp.currentProject.skyline_line!.length > 0)) {
        this.props.setModal(true, {
          positive: async () => {
            this.skylineCircle.map(p => p.remove(true));
            this.skylineLine.map(p => p.remove(true));
            this.skylineCircle = [];
            this.skylineLine = [];
            await this.onUpdateShape("SKYLINE_CIRCLE");
            await this.onUpdateShape("SKYLINE_LINE");
            this.props.setModal(false);
          },
          negative: "hidden",
          content: <div>스카이라인 설정은 아파트에서만 가능합니다. <br />설정하신 스카이라인은 초기화 됩니다.</div>
        })

      }

    }

    // this.props.currentProject.project_site!.map((s, i) => {
    //   const path = DrawingManager2.toGeom(s).coordinates;
    //   this.projectSite[i] ? undefined /*this.projectSite[i].setPath(path)*/ : 
    //   this.projectSite.push(this.dm.addPolygon(path, DrawingManager2.DrawingOption.PROJECT_SITE) as ProjectSitePolygon);
    // });

    // TODO: 현재 shape type아닌거 다 editdable false
    // TODO: 현재 shape 최상단으로 올리기
  }

  forceRedrawRoads = () => {
    this.projectSite.map(p => this.drawRoads(p));
  }

  redrawRoads = async (pr?: any, r?: any) => {
    if (pr === undefined || pr.length === 0) {
      return;
    } else if (pr!.length != r!.length) {
      this.projectSite.map((p) => this.drawRoads(p));
    } else {
      for (let i = 0; i < r!.length; i++) {
        if (r![i].length != pr![i].length) {
          await this.drawRoads(this.projectSite[i]);
        }

        let isDiffer = false;
        for (let j = 0; j < r![i].length; j++) {
          if (r![i][j] !== pr![i][j]) {
            isDiffer = true;
          }
        }

        if (isDiffer) {
          await this.drawRoads(this.projectSite[i]);
          return;
        }
      }
    }
  }

  render() {
    return (
      <div className="ProjectFormMap">
        <div className="absolute">

          {
            this.props.openMapConfig &&
            <div className="site-config-wrap">
              <div className="search-address">
                <SearchPlaceInput
                  placeholder="주소 입력"
                  className="input"
                  onFocus={() => this.setState({ searchFieldFocus: true }, () => this.searchPlace(this.state.searchAddress))}
                  onBlur={() => { this.setState({ searchFieldFocus: false }) }}
                  value={this.state.searchAddress}
                  placeResult={this.state.placeResult}
                  geocodeResult={this.state.geocodeResult}
                  addressResult={this.state.addressResult}
                  loading={this.state.searchPlaceLoading}
                  onSelectItem={(center: { lng: number, lat: number }, isLand: boolean) => {
                    if (this.map!.getZoom() < 18) {
                      this.map!.setZoom(18, true);
                    }
                    this.map!.setCenter([center.lng, center.lat]);
                    this.setState({
                      addressResult: [],
                      geocodeResult: [],
                      placeResult: [],
                      searchAddress: "",
                    })
                    if (this.state.shapeType === "PROJECT_SITE" && this.state.projectSiteType === "SELECT") {
                      if (isLand) {
                        const coord = proj4(DrawingManager2.nProj, DrawingManager2.bProj, [center.lng, center.lat]);
                        this.selectPNU({ x: coord[0], y: coord[1] });
                      }
                    }
                  }}
                  type="text"
                  onChange={(e: ReactText) => {
                    this.setState({ searchAddress: e as string }, () => {
                      this.searchPlace(this.state.searchAddress);
                    })
                  }}
                  endAdornment={<InputAdornment position="end">
                    {
                      this.state.searchAddress.length > 0 &&
                      <IconButton className="icon-btn close-btn" disableRipple={true} onClick={e => {
                        this.setState({ searchAddress: "" })
                      }}>
                        <CloseIcon className="icon" />
                      </IconButton>


                    }
                    <IconButton className="icon-btn search-btn" disableRipple={true} onClick={e => {
                      this.searchPlace(this.state.searchAddress);
                    }}>
                      <SearchIcon className="icon" />
                    </IconButton>

                  </InputAdornment>}
                />
              </div>
              <div className="header">
                <div className="content font font-18px bg-navy font-emphasis">
                  {/* <div className={`project-type-title ${this.props.currentProject.project_type === "AI" && "AI" || "DESIGNER"}`}>{this.props.currentProject.project_type === "AI" && "AI" || "DESIGNER"} 프로젝트</div> */}
                  <div className="title-header">
                    <div className="title">사업영역 설정</div>
                    <Tooltip id="ProjectFormMap-siteTooltip" className="m-l-a"
                      msg={<div>지도 위, 사업영역 설정 툴팁입니다</div>}
                    >
                      <Switch
                        projectType={(this.props.currentProject.project_type === "DESIGNER" || this.props.currentProject.project_type === "SITE_PLAN") ? 
                        "DESIGNER" : "AI"
                      }
                        checked={this.state.siteTooltip}
                        onClick={(checked) => {
                          this.setState({ siteTooltip: checked }, () => {
                            window.localStorage.setItem("site_tooltip", checked.toString());
                            if (this.state.siteTooltip === false) {
                              if (this.infoWindow) {
                                this.infoWindow.close();
                              }
                            }
                          })
                        }}
                        name="툴팁 설정"
                      />
                    </Tooltip>
                  </div>

                </div>
                <Tabs className="tabs bg-navy">
                  <Tooltip className="tab bg-navy" msg={`${this.props.currentProject.discountable && "할인이 적용된 프로젝트는 타입을 변경하실 수 없습니다" || "필지 기반의 설정 방법입니다"}`}>
                    <Button
                      onClick={e => this.setProjectSiteType('SELECT')} disableRipple={true}
                      className={`img-btn tab bg-navy tab-primary first-child ${this.state.projectSiteType === "SELECT" && "active"}`}
                    >
                      <img className="img" src='/img/project_site_type/icon_select.svg' />
                      <img className="img-active" src='/img/project_site_type/icon_select_active.svg' />
                    필지
                  </Button>
                  </Tooltip>
                  <Tooltip className="tab bg-navy" msg={`${this.props.currentProject.discountable && "할인이 적용된 프로젝트는 타입을 변경하실 수 없습니다" || "폴리곤 그리기 설정 방법입니다"}`}>
                    <Button
                      onClick={e => this.setProjectSiteType('DRAW')} disableRipple={true}
                      className={`img-btn tab bg-navy tab-primary ${this.state.projectSiteType === "DRAW" && "active"}`}
                    >
                      <img className="img" src='/img/project_site_type/icon_draw.svg' />
                      <img className="img-active" src='/img/project_site_type/icon_draw_active.svg' />
                    그리기
                  </Button>
                  </Tooltip>
                  <Tooltip className="tab bg-navy" msg={`${this.props.currentProject.discountable && "할인이 적용된 프로젝트는 타입을 변경하실 수 없습니다" || "캐드 설정 방법입니다"}`}>
                    <Button
                      onClick={e => this.setProjectSiteType('IMPORT')} disableRipple={true}
                      className={`img-btn tab bg-navy tab-primary last-child ${this.state.projectSiteType === "IMPORT" && "active"}`}
                    >
                      <img className="img" src='/img/project_site_type/icon_import.svg' />
                      <img className="img-active" src='/img/project_site_type/icon_import_active.svg' />
                    불러오기
                  </Button>
                  </Tooltip>

                </Tabs>
              </div>
              <div className="content">
                <div className="site-config">
                  <div className="config-set">
                    <div className="header">
                      <div className="type font bg-navy font-secondary font-12px">필수사항</div>
                      <div className="required font bg-navy font-emphasis"><RequireIcon className="require-icon" /></div>

                    </div>
                    {
                      this.props.currentProject.project_site_type! === "IMPORT" && this.props.currentProject.project_site!.length === 0 &&
                      <div className="font font-special font-12px">
                        불러오기 버튼으로 업로드한 캐드  목록을 확인 할 수 있습니다.
                    </div>
                    }
                    {

                      Object.values(this.props.projectErros!.errs).filter(e => e!.configType === "SITE").length > 0 &&
                      Object.values(this.props.projectErros!.errs).map(r => {
                        if (r!.configType === "SITE" && r!.field === "project_site") {
                          return (
                            <div className="font font-error font-12px">대지영역은 {r!.msg}</div>
                          )
                        } else if (r!.configType === "SITE" && r!.field === "road_value") {
                          return (
                            <div className="font font-error font-12px">인접도로는 {r!.msg}</div>
                          )
                        } else if (r!.configType === "SITE" && r!.field !== "road_site") {
                          return (
                            <div className="font font-error font-12px">{r!.msg}</div>
                          )
                        }
                      })
                    }
                    <div className="project-site config">
                      <div className="type">
                        <Button disableRipple={true} disabled={this.state.projectSiteType === "IMPORT" || this.props.currentProject.discountable}
                          className={`tab bg-navy tab-secondary site-btn ${(this.state.projectSiteType === "IMPORT" || this.props.currentProject.discountable) && "tab-disabled"} ${this.state.shapeType === "PROJECT_SITE" && 'active'}`}
                          onClick={e => this.setState({ shapeType: "PROJECT_SITE" })}>
                          <Avatar className={`num ${this.state.projectSiteType === "IMPORT" && "disabled"}
                          ${this.props.currentProject.project_type === "AI" ? "AI" : 'DESIGNER'}
                          `}>1</Avatar>
                          <div className="name">대지영역</div>
                          <div className="required font bg-navy font-emphasis"><RequireIcon className="require-icon" /></div>
                          <CheckIcon className={this.props.currentProject.project_site!.length === 0 && "complete-icon" || "complete-icon completed"} />
                        </Button>
                      </div>
                      <div className="action">
                        {
                          (this.state.shapeType === 'PROJECT_SITE' && this.state.projectSiteType === "DRAW") &&
                          <Button className="btn bg-navy btn-primary btn-small action-btn" onClick={e => this.drawShape('PROJECT_SITE')}>{this.state.drawable && "설정하기" || "취소"}</Button>
                        }

                        {
                          (this.state.shapeType === 'PROJECT_SITE' && this.state.projectSiteType === "SELECT") &&
                          <Fragment>
                            <Tooltip id="ProjectFormMap-SiteMerged" className="m-l-a"
                              msg={<div>2개 이상이 필지를 선택하는<br />경우에 사용됩니다</div>}
                            >
                              <Switch
                                projectType={(this.props.currentProject.project_type === "DESIGNER" || this.props.currentProject.project_type === "SITE_PLAN") ? 
                                "DESIGNER" : "AI"}
                                checked={this.props.currentProject.multi_select_project_site!}
                                onClick={(checked) => this.setSiteMultiSelect(checked)}
                                name="합필모드"
                              />
                            </Tooltip>
                          </Fragment>
                        }
                      </div>
                    </div>
                    <div className="road config">
                      <div className="type">
                        <Button disableRipple={true} disabled={this.state.projectSiteType === "IMPORT"}
                          className={`tab bg-navy tab-secondary site-btn ${this.state.projectSiteType === "IMPORT" && "tab-disabled"} ${this.state.shapeType === "ROAD" && 'active'}`}
                          onClick={e => this.setState({ shapeType: "ROAD" }, () => {
                            if (this.roadValue.length > 0) {
                              this.props.setOpenImport(false);
                            }
                          })}>
                        <Avatar className={`num ${this.state.projectSiteType === "IMPORT" && "disabled"}
                        ${this.props.currentProject.project_type === "AI" ? "AI" : 'DESIGNER'}
                        `}>2</Avatar>
                          <div className="name">인접도로</div>
                          {
                            this.state.projectSiteType !== "IMPORT" &&
                            <div className="required font bg-navy font-emphasis">
                              <RequireIcon className={`require-icon`} />
                            </div>
                          }

                          <CheckIcon
                            className={
                              `complete-icon
                            ${(
                                (
                                  this.props.currentProject.auto_road &&
                                  this.props.currentProject.boundary_site!.length > 0 &&
                                  this.props.currentProject.project_site!.length === this.props.currentProject.boundary_site!.length
                                ) ||
                                (
                                  (
                                    this.props.currentProject.auto_road !== true &&
                                    this.props.currentProject.road_site!.length > 0
                                  )
                                  &&
                                  (
                                    this.props.currentProject.project_site_type === "IMPORT" ||
                                    this.props.currentProject.road_value!.length === this.props.currentProject.road_site!.length
                                  )
                                )
                              ) && " completed " || ""}

                            ${(this.props.projectErros!.errs.road_value &&
                                this.props.projectErros!.errs.road_value.failed === "MAX") &&
                              " err " || ""
                              }`
                            }
                          />
                        </Button>
                      </div>
                      <div className="action">
                        {
                          (this.state.shapeType === 'ROAD' && this.state.projectSiteType !== "IMPORT") &&
                          <Button className="btn bg-navy btn-primary btn-small action-btn" onClick={e => {
                            if (this.projectSite.length === 0) {
                              this.props.setModal(true, {
                                positive: () => {
                                  this.props.setModal(false);
                                },
                                negative: () => {
                                  this.props.setModal(false);
                                },
                                content: <div>대지영역을 먼저 설정해주세요</div>
                              })
                            } else if (this.state.projectSiteType === "SELECT") {

                              if (this.props.currentProject.auto_road === false && this.props.currentProject.road_value!.length > 0) {
                                this.drawShape('ROAD');
                                // this.props.setModal(true, {
                                //   positiveTitle: "직접 설정을 계속..",
                                //   positive: () => {

                                //     this.props.setModal(false);
                                //   },
                                //   secondSelectTitle: "자동 설정으로 변경",
                                //   secondSelect: () => {
                                //     this.props.setModal(false);
                                //     this.props.setOpenRoadConfig(false);

                                //     this.drawShape('BOUNDARY_SITE');
                                //   },
                                //   negative: () => {
                                //     this.props.setModal(false);
                                //   },
                                //   content:
                                //     <div>
                                //       현재 인접 도로를 <b>직접 설정</b>중입니다.<br />
                                //       <b>자동 설정</b>으로 변경시 설정한 값이 초기화 됩니다.<br />
                                //       계속 <b>직접 설정</b>하시겠습니까?
                                //     </div>
                                // })
                              } else { // 인접대지경계선 모듈
                                this.drawShape('BOUNDARY_SITE');
                              }



                            } else {
                              this.drawShape('ROAD');
                            }

                          }}>{this.state.drawable && "설정하기" || "취소"}</Button>
                        }
                      </div>
                    </div>

                    <div className="vacancy-outside config">
                      <div className="type">
                        <Tooltip place="right" msg={this.props.currentProject.auto_road ? "인접대지경계선에 포함된 영역입니다." : undefined}>
                          <Button disableRipple={true} disabled={this.state.projectSiteType === "IMPORT" || this.props.currentProject.auto_road}
                            className={`tab bg-navy tab-secondary site-btn ${(this.state.projectSiteType === "IMPORT" || this.props.currentProject.auto_road) && "tab-disabled"} ${this.state.shapeType === "VACANCY_OUTSIDE" && 'active'}`}
                            onClick={e => this.setState({ shapeType: "VACANCY_OUTSIDE" })}>
                           
                           <Avatar className={`num ${(this.state.projectSiteType === "IMPORT" || this.props.currentProject.auto_road) && "disabled"}
         ${this.props.currentProject.project_type === "AI" ? "AI" : 'DESIGNER'}
        `}>3</Avatar>
                            <div className="name">공지영역</div>
                            <CheckIcon className={this.props.currentProject.vacancy_outside!.length === 0 && "complete-icon" || "complete-icon completed"} />
                          </Button>

                        </Tooltip>
                      </div>
                      <div className="action">
                        {
                          this.state.shapeType === 'VACANCY_OUTSIDE' &&
                          <Button className="btn bg-navy btn-primary btn-small action-btn" onClick={e => this.drawShape('VACANCY_OUTSIDE')}>{this.state.drawable && "설정하기" || "취소"}</Button>
                        }
                      </div>

                    </div>
                    <div className="vacancy-inside config">
                      <div className="type">
                        <Button disableRipple={true} disabled={this.state.projectSiteType === "IMPORT"}
                          className={`tab bg-navy tab-secondary site-btn ${this.state.projectSiteType === "IMPORT" && "tab-disabled"} ${this.state.shapeType === "VACANCY_INSIDE" && 'active'}`}
                          onClick={e => this.setState({ shapeType: "VACANCY_INSIDE" })}>
                         <Avatar className={`num ${this.state.projectSiteType === "IMPORT" && "disabled"} 
                        ${this.props.currentProject.project_type === "AI" ? "AI" : 'DESIGNER'}`}>4</Avatar>
                          <div className="name">특수영역</div>
                          <CheckIcon className={this.props.currentProject.vacancy_inside!.length === 0 && "complete-icon" || "complete-icon completed"} />
                        </Button>
                      </div>
                      <div className="action">
                        {

                          this.state.shapeType === 'VACANCY_INSIDE' &&
                          <Button className="btn bg-navy btn-primary btn-small action-btn" onClick={e => this.drawShape('VACANCY_INSIDE')}>{this.state.drawable && "설정하기" || "취소"}</Button>
                        }
                      </div>
                    </div>
                    {
                      this.props.currentProject.project_type === "AI" &&
                      <div className="skyline config">
                        <div className="type">
                          {
                            this.state.projectSiteType === "IMPORT" && this.props.currentProject.selected_my_building_plan_id !== undefined &&
                            <Tooltip place="right" msg={`${this.props.currentProject.project_type === "AI" && "건물 타입이 아파트인 경우에 설정할 수 있습니다." || "스카이라인 기능은 AI 프로젝트에서 설정할 수 있습니다."}`}>
                              <Button disableRipple={true} disabled={false} className={`tab bg-navy tab-secondary site-btn tab-disabled`}>
                                <Avatar className="num disabled">5</Avatar>
                                <div className="name">스카이라인</div>
                                <CheckIcon className={(this.props.currentProject.skyline_circle!.length === 0 && this.props.currentProject.skyline_line!.length === 0) && "complete-icon" || "complete-icon completed"} />
                              </Button>
                            </Tooltip>
                            ||
                            <Tooltip place="right" msg={`${this.props.currentProject.project_type === "AI" && "건물 타입이 아파트인 경우에 설정할 수 있습니다." || "스카이라인 기능은 AI 프로젝트에서 설정할 수 있습니다."}`}>
                              <Button disableRipple={true}
                                disabled={!(this.props.currentProject.project_type === "AI" && this.props.currentProject.building_type === "아파트")}
                                className={`tab bg-navy tab-secondary site-btn 

                                  ${(this.state.shapeType === 'SKYLINE_CIRCLE' || this.state.shapeType === 'SKYLINE_LINE') && 'active '}
                                  ${!(this.props.currentProject.project_type === "AI" && this.props.currentProject.building_type === "아파트") && "tab-disabled"
                                  }
                                `}

                                onClick={e => this.setState({ shapeType: 'SKYLINE_CIRCLE' })}>
                              <Avatar className={`num 
                                ${
                                  !(this.props.currentProject.project_type === "AI" && this.props.currentProject.building_type === "아파트") && "disabled"
                                }
                                AI
                                `
                              }
                              >5</Avatar>
                                <div className="name">스카이라인</div>
                                <CheckIcon className={(this.props.currentProject.skyline_circle!.length === 0 && this.props.currentProject.skyline_line!.length === 0) && "complete-icon" || "complete-icon completed"} />
                              </Button>
                            </Tooltip>
                          }
                        </div>
                        <div className="action">
                          {
                            (this.state.shapeType === 'SKYLINE_CIRCLE' || this.state.shapeType === 'SKYLINE_LINE') &&
                            (
                              this.state.drawable &&
                              <Fragment>
                                <Button className="btn bg-navy btn-primary btn-small action-btn half" onClick={e => this.drawShape('SKYLINE_CIRCLE')}>범위</Button>
                                <Button className="btn bg-navy btn-primary btn-small action-btn half m-l" onClick={e => this.drawShape('SKYLINE_LINE')}>방향</Button>
                              </Fragment> ||
                              <Button className="btn bg-navy btn-primary btn-small action-btn m-l" onClick={e => this.drawShape('SKYLINE_LINE')}>취소</Button>
                            )
                          }
                        </div>

                      </div>
                    }
                  </div>
                </div>
                {this.state.projectSiteOffset && this.state.projectSiteOffset >= 0.05 ?
                  <div className="font-error font-12px bottom-error ">
                    <div className="vector">
                      <WarningIcon className="icon area-warning-icon" />
                    </div>
                    <span>지적 상의 대지 면적과 실제 대지 면적이 다를 수 있습니다.<br />신축 전 현황 측량이 필요합니다.</span></div>

                  : ''}

                <div className="site-area">
                  <div className="project-site">
                    <div className="key">대지영역 면적</div>
                    <div className="value">
                      <span className="font bg-navy font-primary font-14px font-roboto">{Number((this.props.currentProject.project_site_area!).toFixed(2)).toLocaleString()}</span>㎡
                  </div>
                  </div>
                  <div className="vacancy-outside">
                    <div className="key">공지영역 면적</div>
                    <div className="value">
                      <span className="font bg-navy font-primary font-14px font-roboto">
                        {Number((this.props.currentProject.vacancy_outside_area!).toFixed(2)).toLocaleString()}
                      </span>㎡
                  </div>
                  </div>
                  <div className="vacancy-inside">
                    <div className="key">특수영역 면적</div>
                    <div className="value">
                      <span className="font bg-navy font-primary font-14px font-roboto">
                        {Number((this.props.currentProject.vacancy_inside_area!).toFixed(2)).toLocaleString()}
                      </span>㎡
                  </div>
                  </div>
                </div>
              </div>
            </div>
          }
          {
            this.props.openRoadConfig &&
            <ProjectFormMapRoad className="road-config-wrap" {...this.props}
              roadValue={this.roadValue}
              roadActive={this.roadActive}
              projectSite={this.projectSite}
              drawRoad={this.drawRoad}
              clearRoad={this.clearRoad}
              drawShape={this.drawShape}
              onUpdateShape={this.onUpdateShape}
            />
          }
          {
            (this.state.projectSiteType === "IMPORT" && this.props.openImport) &&
            <ProjectImport {...this.props} map={this.map} setOpenImport={this.props.setOpenImport} setImportType={this.setImportType} importType={this.state.importType} />
          }
          <Button onClick={this.setMapConfig} disableRipple={true} className="site-config-btn">
            {this.props.openRoadConfig && "인접도로 설정" || ""}
            {this.props.openImport && "불러오기" || ""}
            {!this.props.openImport && !this.props.openRoadConfig && "사업영역 설정" || ""}
            {this.props.openMapConfig && <KeyboardArrowLeftIcon className="icon" /> || <KeyboardArrowRightIcon className="icon" />}
          </Button>
          <Tabs className="map-converter tabs bg-navy tabs-small" align="vertical">
            <Button onClick={() => this.props.setCadastralMap(true)} disableRipple={true} className={`tab bg-navy tab-primary tab-small ${this.props.cadastralMap && "active"}`}>지적도</Button>
            <Button onClick={() => this.props.setCadastralMap(false)} disableRipple={true} className={`tab bg-navy tab-primary tab-small ${!this.props.cadastralMap && "active"}`}>지도</Button>
          </Tabs>
          <div className="map-options">
            <Tooltip msg="설정 위치로 이동" place="right">
              <Button disableRipple={true} onClick={() => this.moveToProjectSite()} className="btn bg-navy btn-primary move">
                <GpsIcon className="icon" />
              </Button>
            </Tooltip>
            <Tooltip msg="면적재기" place="right">
              <Button disableRipple={true} onClick={() => this.measureOnMap("AREA")} className="btn bg-navy btn-primary area">
                <img src="/img/measure_area.svg" />
              </Button>
            </Tooltip>
            <Tooltip msg="거리재기" place="right">
              <Button disableRipple={true} onClick={() => this.measureOnMap("DISTANCE")} className="btn bg-navy btn-primary distance">
                <img src="/img/measure_distance.svg" />
              </Button>
            </Tooltip>
          </div>
          <ConstructionLineInfo className="construction-line-info" projectType={this.props.currentProject.project_type} />
        </div>
        <div id="empty"></div>
        <div className="map" ref="map" onClick={() => {
          $('#empty').focus();
        }}>
        </div>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={this.state.openSnack}
          onClose={this.handleSnackClose}
          className="snack-bar"
          ContentProps={{ 'aria-describedby': 'message-id' }}
          message={<div style={{ display: "flex", alignItems: "center" }}><InfoIcon className="icon m-r-sm" />지목이 "도로, 공원, 하천"인 필지는 선택할 수 없습니다. (선택 지목: "{this.state.selectedJimog}")</div>}
        />

      </div>
    );
  }



  moveToProjectSite = () => {
    if (this.projectSite.length > 0 && this.props.currentProject.project_site_center) {
      this.map!.setCenter(DrawingManager2.toGeom(this.props.currentProject.project_site_center, "Point").coordinates);
    }
  }

  measureOnMap = (type: "DISTANCE" | "AREA") => {
    if (this.state.drawable) {
      this.setState({ measureMode: type, drawable: false }, () => {
        if (type === "DISTANCE") {
          this.measureDistance(() => {
            setTimeout(() => this.setState({ measureMode: undefined, drawable: true, shapeVertexLength: 0 }), 100)
          })
        } else {
          this.measureArea(() => {
            setTimeout(() => this.setState({ measureMode: undefined, drawable: true, shapeVertexLength: 0 }), 100)
          })
        }
      })
    }
  }
  measureAreaPolygons: Array<Polygon> = [];
  measureDistancePolylines: Array<Polyline> = [];
  measureDistanceGuideMarkers: Array<Marker> = [];
  measureDistanceTrackMarkers: Array<Array<Marker>> = [];
  measureAreaMarkers: Array<Marker> = [];
  measureDistanceMarkers: Array<Marker> = [];
  measureDistanceVertex: any[] = [];
  measureClickEvent?: (e: any) => void;
  measureMousemoveEvent?: (e: any) => void;


  measureArea = (callback: Function) => {
    const options = _.cloneDeep(DrawingManager2.DrawingOption.CAL_AREA);
    const polygon = this.dm!.drawPolygon(options);
    polygon.on('cancelled', () => {
      polygon.remove();
      callback();
    })
    polygon.on('added', () => {
      if (this.state.shapeVertexLength < 3) {
        polygon.emit('cancelled');
        return;
      }
      if (polygon.getPath()[0].length > this.state.shapeVertexLength) {
        this.dm!.deleteLastVertex(polygon);
      }

      polygon.setOptions({
        clickable: false,
        controllable: false,
      })

      const markerOptions = _.cloneDeep(DrawingManager2.DrawingOption.CAL_MARKER);
      markerOptions.icon.content =
        `<div style="cursor: default !important; pointer-events: none; background-color: black; color: #ccc; font-size: 12px; padding: 10px; box-sizing: border-box; display:flex; flex-flow: row; align-items: center;">
        <span style="height:20px; width: 50px;">면적</span>
        <span style="height:20px; display: flex; align-items: center;">${Number(this.dm!.sumOfSite([DrawingManager2.toWKT(polygon)]).toFixed(2)).toLocaleString()}</span>㎡
        <img src="/img/ic_outline-clear.svg" class="m-l-md" style="border: 0px; pointer-events: auto;" />
      </div>`
      const marker = this.dm!.addMarker(polygon.getLastVertex(), {
        ...markerOptions,
        icon: {
          content: markerOptions.icon.content,
          anchor: { x: -5, y: -5 }
        }
      }, false);
      marker.on('click', () => {
        const pIndex = this.measureAreaPolygons.findIndex(e => e.getId() === polygon.getId());
        this.measureAreaPolygons[pIndex].remove();
        this.measureAreaPolygons.splice(pIndex, 1);

        const mIndex = this.measureAreaMarkers.findIndex(e => e.getId() === marker.getId());
        this.measureAreaMarkers[mIndex].remove();
        this.measureAreaMarkers.splice(mIndex, 1);
      })
      this.measureAreaMarkers.push(marker);
      callback();
    })
    this.measureAreaPolygons.push(polygon);
  }
  measureDistance = (callback: Function) => {
    const options = _.cloneDeep(DrawingManager2.DrawingOption.CAL_DISTANCE);
    const guidePolyline = this.dm!.drawPolyline(options);
    const markerOptions = _.cloneDeep(DrawingManager2.DrawingOption.CAL_MARKER);
    let totalDistance = 0;
    const guideMarkers: any = [];
    const guideMarker = this.dm!.addMarker([], {
      ...markerOptions,
      icon: {
        content: markerOptions.icon.content,
        anchor: { x: -25, y: -25 }
      }
    }, false);

    this.measureDistanceGuideMarkers.push(guideMarker);
    this.measureDistanceVertex = [];

    guidePolyline.on('cancelled', () => {
      guidePolyline.remove();
      const index = this.measureDistanceGuideMarkers.length - 1;
      this.measureDistanceGuideMarkers[index].remove();
      this.measureDistanceGuideMarkers.splice(index, 1);
      this.measureDistanceTrackMarkers.splice(index, 1);

      this.measureClickEvent !== undefined && this.map!.removeListener('m_click', this.measureClickEvent);
      this.measureMousemoveEvent !== undefined && this.map!.removeListener('m_mousemove', this.measureMousemoveEvent);
      callback();
    })
    guidePolyline.on('added', () => {
      if (this.state.shapeVertexLength < 2) {
        guidePolyline.emit('cancelled');
        return;
      }
      if (guidePolyline.getPath().length > this.state.shapeVertexLength) {
        this.dm!.deleteLastVertex(guidePolyline);
      }

      this.measureDistancePolylines.push(guidePolyline);

      guideMarker.remove();
      guidePolyline.setOptions({ clickable: false });

      const index = this.measureDistanceTrackMarkers.length - 1;
      const inIndex = this.measureDistanceTrackMarkers[index].length - 1;
      this.measureDistanceTrackMarkers[index][inIndex].remove();
      this.measureDistanceTrackMarkers[index].splice(inIndex, 1);



      const markerOptions = _.cloneDeep(DrawingManager2.DrawingOption.CAL_MARKER);
      markerOptions.icon.content =
        `<div style="cursor: default !important; pointer-events: none; background-color: black; color: #ccc; font-size: 12px; padding: 10px; box-sizing: border-box; display:flex; flex-flow: row; align-items: center;">
        <span style="height:20px; width: 50px;">총 거리</span>
        <span style="height:20px; display: flex; align-items: center;">${Number(totalDistance.toFixed(2)).toLocaleString()}</span>m
        <img src="/img/ic_outline-clear.svg" class="m-l-md" style="border: 0px; pointer-events: auto;" />
      </div>`
      const marker = this.dm!.addMarker(guidePolyline.getPath()[guidePolyline.getPath().length - 1], {
        ...markerOptions,
        icon: {
          content: markerOptions.icon.content,
          anchor: { x: -5, y: -5 }
        }
      }, false);
      marker.on('click', () => {
        const index = this.measureDistanceMarkers.findIndex(e => e.getId() === marker.getId());
        this.measureDistanceMarkers[index].remove();
        this.measureDistanceMarkers.splice(index, 1);
        this.measureDistanceGuideMarkers[index].remove();
        this.measureDistanceGuideMarkers.splice(index, 1);
        this.measureDistanceTrackMarkers[index].map(m => m.remove());
        this.measureDistanceTrackMarkers.splice(index, 1);
        this.measureDistancePolylines[index].remove();
        this.measureDistancePolylines.splice(index, 1);

      })
      this.measureDistanceMarkers.push(marker);

      this.measureClickEvent !== undefined && this.map!.removeListener('m_click', this.measureClickEvent);
      this.measureMousemoveEvent !== undefined && this.map!.removeListener('m_mousemove', this.measureMousemoveEvent);
      callback();
    })

    this.measureClickEvent = (e: any) => {
      this.measureDistanceVertex.push(e);
      if (this.measureDistanceVertex.length > 1) {
        const p1 = [this.measureDistanceVertex[this.measureDistanceVertex.length - 2].coord.x, this.measureDistanceVertex[this.measureDistanceVertex.length - 2].coord.y];
        const p2 = [e.coord.x, e.coord.y];
        const distance = this.dm!.distanceBetweenPoint(p1, p2);
        totalDistance += distance;
        const markerOptions = _.cloneDeep(DrawingManager2.DrawingOption.CAL_MARKER);
        markerOptions.icon.content =
          `<div style="cursor: default !important; pointer-events: none; background-color: #333; color: #ccc; font-size: 12px; padding: 0px 10px; box-sizing: border-box; display:flex; flex-flow: row; align-items: center;">
          <span style="height:20px; display: flex; align-items: center;">${Number(distance.toFixed(2)).toLocaleString()}</span>m
        </div>`
        const marker = this.dm!.addMarker([e.coord.x, e.coord.y], {
          ...markerOptions,
          icon: {
            content: markerOptions.icon.content,
            anchor: { x: -5, y: -5 }
          }
        }, false);

        const index = this.measureDistanceTrackMarkers.length - 1;
        this.measureDistanceTrackMarkers[index].push(marker);

        guideMarkers.push(marker);
      } else {
        const index = this.measureDistanceTrackMarkers.length;
        this.measureDistanceTrackMarkers[index] = [];
      }
    }
    this.measureMousemoveEvent = (e: any) => {
      if (this.measureDistanceVertex.length > 0) {
        const p1 = [this.measureDistanceVertex[this.measureDistanceVertex.length - 1].coord.x, this.measureDistanceVertex[this.measureDistanceVertex.length - 1].coord.y];
        const p2 = [e.coord.x, e.coord.y];
        const distance = this.dm!.distanceBetweenPoint(p1, p2);
        guideMarker.setOptions({
          icon: {
            content: `<div style="cursor: default !important; pointer-events: none; background-color: #333; color: #ccc; font-size: 12px; padding: 0px 10px; box-sizing: border-box; display:flex; flex-flow: row; align-items: center;">
              <span style="height:20px; display: flex; align-items: center;">${Number(distance.toFixed(2)).toLocaleString()}</span>m
            </div>`,
            anchor: { x: -25, y: -5 }
          }
        });
        guideMarker.setPosition([e.coord.x, e.coord.y])
      }
    }

    this.map!.on('m_click', this.measureClickEvent);
    this.map!.on('m_mousemove', this.measureMousemoveEvent);


  }

  setSiteMultiSelect = (checked: boolean) => {
    if (this.selectedProjectSiteGraph.nodeCount() > 1) {
      this.props.setModal(true, {
        title: "안내",
        positive: () => {

          this.projectSite.map(p => {
            this.clearRoads(p);
            p.remove(true);
          });
          this.roadSite.map(p => p !== null && p.remove(true));
          this.selectedProjectSiteGraph.nodes().map(p => this.selectedProjectSiteGraph.node(p).remove(true));
          this.roadValue = [];
          this.roadActive = [];
          this.projectSite = [];
          this.roadSite = [];
          this.selectedProjectSiteGraph = new Graph();
          this.pnuList = [];
          this.props.updateJiguInfo([]);
          this.props.onUpdateProject({
            multi_select_project_site: false
          }, () => {
            this.onUpdateShape();
            this.props.setModal(false);
          })
        },
        negative: () => {
          this.setSiteMultiSelect(true);
          this.props.setModal(false);
        },
        positiveTitle: "초기화하고 합필모드 끄기",
        negativeTitle: "유지하기",
        content: <div>
          <span>
            합필모드 <b>설정 해제</b>시<br />
            선택하신 모든 필지가<br />
            초기화 됩니다
          </span>
        </div>
      })
    } else {
      this.props.onUpdateProject({
        multi_select_project_site: checked
      })
    }
  }

  handleSnackClose = () => {
    this.setState({ openSnack: false });
  }

  setImportType = (type: ProjectImportType) => {
    if (this.state.importType !== type) {
      this.setState({ importType: type });
    }
  }

  setMapConfig = () => {
    if (this.props.openMapConfig) {
      if (this.props.openRoadConfig) {
        this.props.setOpenRoadConfig(false);
        return;
      }

      if (this.props.openImport) {
        this.props.setOpenImport(false);
        return;
      }

      this.props.setOpenMapConfig(false);
    } else {
      if (this.state.shapeType === 'ROAD') {
        this.props.setOpenRoadConfig(true);
      }
      this.props.setOpenMapConfig(true);
    }
  }

  compareProjectField = (a: any, b: any) => {
    const aa = _.cloneDeep(a);
    const bb = _.cloneDeep(b);
    delete aa.viewed_at;
    delete bb.viewed_at;
    delete aa.modified_at;
    delete bb.modified_at;
    delete aa.project_site_type;
    delete bb.project_site_type;
    delete aa.project_name;
    delete bb.project_name;
    delete aa.project_type;
    delete bb.project_type;
    return JSON.stringify(aa) === JSON.stringify(bb);
  }

  setProjectSiteType = (type: ProjectSiteType, callback?: Function) => {
    if (this.props.currentProject.discountable) {
      return;
    }

    this.props.setOpenJiguInfo(false);
    let preType = this.state.projectSiteType;
    if (preType === type) {
      if (type === "IMPORT" && this.props.openImport === false) {
        this.props.setOpenImport(true);
      }
      return;
    } else {


      // 수정모드 진입시 이전값과 현재값이 같기 떄문에 reset number(props값)와 edit field를 활용함
      if (!_.isEqualWith(this.props.currentProject, this.state.preProject, this.compareProjectField) || (this.props.resetNum === 0 && this.props.edit)) {
        this.props.setModal(true, {
          type: "SIMPLE",
          negative: () => {
            this.props.setModal(false);
          },
          positive: () => {
            this.props.setModal(false);
            this.props.onResetProject({ project_site_type: type, project_type: this.props.currentProject.project_type }, () => {
              this.setState({
                shapeType: this.suitProjectSiteType(),
                preProject: this.props.currentProject,
                importType: "MY_SITE",
              }, () => {
                this.setProjectSiteType(type);
              })
            });
          },
          title: "설정 모드 변경",
          content: <div>사업영역 설정 모드를 변경하시면, 설정이 초기화 됩니다.</div>
        });
      } else {
        this.props.onResetProject({
          project_site_type: type,
          project_name: this.props.currentProject.project_name || "",
          project_type: this.props.currentProject.project_type,
        }, () => {
          this.setState({
            shapeType: this.suitProjectSiteType(),
            preProject: this.props.currentProject,
            importType: "MY_SITE",
          }, () => {
            this.setProjectSiteType(type);
          })
        });
      }
    }
  }

  handleKeyPress(e: any) {
    if (this.state.drawable === false && e.keyCode === 27) { // 그리기 버튼누르고 esc 눌렀을 때 그리던것 취소

      if (this.state.measureMode !== undefined) {
        this.measureClickEvent && this.map!.removeListener('m_click', this.measureClickEvent);
        this.measureMousemoveEvent && this.map!.removeListener('m_mousemove', this.measureMousemoveEvent);

        if (this.state.measureMode === "DISTANCE", this.state.shapeVertexLength === 1) {
          const index = this.measureDistanceTrackMarkers.length - 1;
          this.measureDistanceGuideMarkers[index].remove();
          this.measureDistanceGuideMarkers.splice(index, 1);
          this.measureDistanceTrackMarkers.splice(index, 1);
        }
      }
      this.clearDrawingManager();
      this.setState({ drawable: true });
    }
  }
  // 나중에 options 정리
  drawShape = (type: ShapeType, options?: any) => {
    if (this.state.drawable) {
      this.setState({ drawable: false }, async () => {
        switch (type) {
          case 'PROJECT_SITE':
            {
              // 1개 제한
              if (this.props.currentProject.project_site!.length > 0) {
                this.props.setModal(true, {
                  content: <div style={{ display: "flex", flexFlow: "column" }}>
                    <span>현재, 1개의 대지영역만 설정이 가능합니다.</span>
                    <span>삭제 하시겠습니까?</span>
                  </div>,
                  positive: () => {
                    this.clearRoads(this.projectSite[0]);
                    this.projectSite[0].remove(true);
                    this.projectSite = [];
                    this.roadValue = [];
                    this.roadSite = [];
                    this.boundarySite.map(r => r !== null && r!.remove(true));
                    this.onUpdateShape("PROJECT_SITE");
                    this.props.setModal(false)
                  },
                  positiveTitle: "삭제하기",
                  negative: () => {
                    this.props.setModal(false);
                  },
                  negativeTitle: "취소",
                })
                this.setState({ drawable: true });
                return;
              }
              const poly = this.dm.drawPolygon(DrawingManager2.DrawingOption.PROJECT_SITE) as ProjectSitePolygon;
              poly.on('added', () => {
                if (this.state.shapeVertexLength < 3) {
                  poly.emit('cancelled');
                  return;
                }
                if (poly.getPath()[0].length > this.state.shapeVertexLength) {
                  this.dm!.deleteLastVertex(poly);
                  this.dm!.clearVertex(poly);
                }
                this.dm!.ccwVertex(poly);
                this.projectSite.push(poly);
                this.onUpdateShape("PROJECT_SITE");
                this.setState({ drawable: true, shapeVertexLength: 0 }, () => {
                });
              });
              poly.on('vertex_added', (index: number) => {
                const polygonIndex = this.projectSite.findIndex(e => e.getId() === poly.getId());
                this.roadValue[polygonIndex] !== undefined && this.roadValue[polygonIndex].splice(index - 1, 0, this.roadValue[polygonIndex][index - 1]);
                this.props.onUpdateProject({
                  road_value: this.roadValue
                });
              });
              poly.on('changed', _.debounce(async () => {
                if (poly.getHasRoad()) {
                  await this.drawRoads(poly);
                }
                this.onUpdateShape("PROJECT_SITE");
              }, 500));
              poly.on('removed', () => {
                this.removeElement("projectSite", poly);
                this.onUpdateShape("PROJECT_SITE");
              });
              poly.on('cancelled', () => {
                poly.remove(true);
                this.setState({ drawable: true, shapeVertexLength: 0 });
              });
              break;
            }
          case 'ROAD':
            {
              this.boundarySite.map(r => r !== null && r.remove());
              this.boundaryExtendedSite.map(r => r !== null && r.remove());
              this.boundarySite = [];
              this.boundaryExtendedSite = [];

              this.props.setOpenRoadConfig(true);
              this.props.setOpenImport(false);

              await Promise.all(this.projectSite.map((p) => this.drawRoads(p)))

              this.onUpdateShape("ROAD");
              this.setState({ drawable: true });
              break;
            }
          case 'BOUNDARY_SITE':
            {
              if (!(options && options.disableOpenRoadConfig)) {
                this.props.setOpenRoadConfig(true);
              }
              this.props.setOpenImport(false);

              this.props.onUpdateProject({
                road_value: []
              }, async () => {
                this.vacancyOutside.map(r => r.remove());
                this.vacancyOutside = [];

                this.boundarySite.map(r => r !== null && r.remove());
                this.boundaryExtendedSite.map(r => r !== null && r.remove());

                this.boundarySite = [];
                this.boundaryExtendedSite = [];

                const lambda = await new AWSModule("LAMBDA").connect();
                {
                  const r2 = await lambda.Lambda!.invoke({
                    FunctionName: `arn:aws:lambda:ap-northeast-2:331053433621:function:engine-roadline-generator-${App.stage}`,
                    Payload: JSON.stringify({
                      "isRoad": "false",
                      "polySet": this.props.currentProject.project_site,
                      "genType": "AUTOGEN",
                    }),
                  }).promise();

                  const p2 = JSON.parse(r2.Payload as string);
                  const polygon2 = this.dm.addPolygon(DrawingManager2.toGeom(p2.body, "Polygon").coordinates, {
                    ...DrawingManager2.DrawingOption.BOUNDARY_SITE
                  }, true);
                  polygon2.setEditable(false);
                  polygon2.setOptions({ clickable: false })
                  this.boundarySite.push(polygon2);
                }
                {
                  const r2 = await lambda.Lambda!.invoke({
                    FunctionName: `arn:aws:lambda:ap-northeast-2:331053433621:function:engine-roadline-generator-${App.stage}`,
                    Payload: JSON.stringify({
                      "isRoad": "true",
                      "polySet": this.props.currentProject.project_site,
                      "genType": "AUTOGEN",
                    }),
                  }).promise();

                  const p2 = JSON.parse(r2.Payload as string);

                  const polygon2 = this.dm.addPolygon(DrawingManager2.toGeom(p2.body, "Polygon").coordinates, {
                    ...DrawingManager2.DrawingOption.BOUNDARY_SITE_EXTENDED
                  }, true);
                  polygon2.setEditable(false);
                  polygon2.setOptions({ clickable: false })
                  this.boundaryExtendedSite.push(polygon2);
                }
                this.setState({ drawable: true }, () => {
                  this.onUpdateShape('BOUNDARY_SITE');
                });
              })
              break;
            }
          case 'VACANCY_OUTSIDE':
            {
              const poly = this.dm.drawPolygon(DrawingManager2.DrawingOption.VACANCY_OUTSIDE);

              poly.on('added', () => {
                if (this.state.shapeVertexLength < 3) {
                  poly.emit('cancelled');
                  return;
                }
                if (poly.getPath()[0].length > this.state.shapeVertexLength) {
                  this.dm!.deleteLastVertex(poly);
                  this.dm!.clearVertex(poly);
                }
                this.vacancyOutside.push(poly);
                this.onUpdateShape("VACANCY_OUTSIDE");
                this.setState({ drawable: true, shapeVertexLength: 0 });
              });
              poly.on('changed', () => { this.onUpdateShape("VACANCY_OUTSIDE") });
              poly.on('removed', () => {
                this.removeElement("vacancyOutside", poly);
                this.onUpdateShape("VACANCY_OUTSIDE");
              });
              poly.on('cancelled', () => {
                poly.remove(true);
                this.setState({ drawable: true, shapeVertexLength: 0 });
              });
              break;
            }
          case 'VACANCY_INSIDE':
            {
              const poly = this.dm.drawPolygon(DrawingManager2.DrawingOption.VACANCY_INSIDE);

              poly.on('added', () => {
                if (this.state.shapeVertexLength < 3) {
                  poly.emit('cancelled');
                  return;
                }
                if (poly.getPath()[0].length > this.state.shapeVertexLength) {
                  this.dm!.deleteLastVertex(poly);
                  this.dm!.clearVertex(poly);
                }
                this.vacancyInside.push(poly);
                this.onUpdateShape("VACANCY_INSIDE");
                this.setState({ drawable: true, shapeVertexLength: 0 }, () => {
                });
              });

              poly.on('changed', () => { this.onUpdateShape("VACANCY_INSIDE") });
              poly.on('removed', () => {
                this.removeElement("vacancyInside", poly);
                this.onUpdateShape("VACANCY_INSIDE");
              });
              poly.on('cancelled', () => {
                poly.remove(true);
                this.setState({ drawable: true, shapeVertexLength: 0 });
              });
              break;
            }
          case 'SKYLINE_CIRCLE':
            {
              const circle = this.dm.drawCircle(DrawingManager2.DrawingOption.SKYLINE_CIRCLE);
              circle.on('added', () => {
                this.skylineCircle.push(circle);
                this.onUpdateShape("SKYLINE_CIRCLE");
                this.setState({ drawable: true }, () => {
                });
              });
              circle.on('changed', () => { this.onUpdateShape("SKYLINE_CIRCLE") });
              circle.on('removed', () => {
                this.removeElement("skylineCircle", circle);
                this.onUpdateShape("SKYLINE_CIRCLE");
              });
              circle.on('cancelled', () => {
                circle.remove(true);
                this.setState({ drawable: true });
              });
              break;
            }
          case 'SKYLINE_LINE':
            {
              const start = this.dm.drawMarker(DrawingManager2.DrawingOption.MAX_FLOOR_MARKER);
              let end: Marker;
              let line: Polyline;
              start.on('added', () => {

                this.startMarker.push(start);
                end = this.dm.drawMarker(DrawingManager2.DrawingOption.MIN_FLOOR_MARKER);
                end.on('added', () => {

                  this.endMarker.push(end);
                  const startPosition = start.getPosition();
                  const endPosition = end.getPosition();
                  const path = [[startPosition.x, startPosition.y], [endPosition.x, endPosition.y]];
                  line = this.dm.addPolyline(path, DrawingManager2.DrawingOption.SKYLINE_LINE);
                  this.skylineLine.push(line);
                  this.onUpdateShape("SKYLINE_LINE");
                  this.setState({ drawable: true }, () => {
                  });

                  line.on('changed', () => {
                    this.onUpdateShape("SKYLINE_LINE");
                  });

                  line.on('removed', () => {
                    this.removeElement("skylineLine", line);
                    this.onUpdateShape("SKYLINE_LINE");
                  });

                  start.on('changed', () => {
                    const markerPosition = start.getPosition();
                    const path = line.getPath();
                    path[0] = [markerPosition.x, markerPosition.y];
                    line.setPath(path);
                    line.emit('changed');
                  });
                  end.on('changed', () => {
                    const markerPosition = end.getPosition();
                    const path = line.getPath();
                    path[1] = [markerPosition.x, markerPosition.y];
                    line.setPath(path);
                    line.emit('changed');
                  });

                  end.on('removed', () => {
                    start.remove(true);
                    line.remove();
                  });
                })
              });
              start.on('removed', () => {
                if (this.startMarker.length === this.endMarker.length) {
                  end.remove(true);
                  line.remove();
                }
              });
            }
        }
      })
    } else {
      this.clearDrawingManager();
      this.setState({ drawable: true })
    }
  }

  getMergedRoad = async (polygonIndex: number, polygon: ProjectSitePolygon) => {
    const lambda = await new AWSModule("LAMBDA").connect();
    const r = await lambda.Lambda!.invoke({
      FunctionName: "arn:aws:lambda:ap-northeast-2:331053433621:function:platform-road-site-latest",
      Payload: JSON.stringify({
        project_site: this.roadValue[polygonIndex] !== undefined && [DrawingManager2.toWKT(polygon)],
        road_value: [this.roadValue[polygonIndex]]
      })
    }).promise();
    let road_site = [];
    if (JSON.parse(r.Payload as string).success) {
      road_site = JSON.parse(r.Payload as string).body.road_site;
      if (road_site[0] === null) {
        if (this.roadSite[polygonIndex] !== null && this.roadSite[polygonIndex] !== undefined) {
          this.roadSite[polygonIndex]!.remove(true);
        }
        this.roadSite[polygonIndex] = null;
      } else {
        const reader = new jsts.io.WKTReader();
        const p = reader.read(road_site[0]);
        const type = p.getGeometryType();
        let poly = null;
        if (type === "MultiPolygon") {
          let path: any = [];
          const mPath = DrawingManager2.toGeom(road_site[0], "MultiPolygon").coordinates;
          DrawingManager2.toGeom(road_site[0], "MultiPolygon").coordinates.map((c: any) =>
            path.push(c[0])
          )
          poly = this.dm.addPolygon(path, DrawingManager2.DrawingOption.ROAD_SITE);
          poly.multiPolygonPath = mPath;
        } else {
          poly = this.dm.addPolygon(DrawingManager2.toGeom(road_site[0], "Polygon").coordinates, DrawingManager2.DrawingOption.ROAD_SITE);
        }
        this.roadSite[polygonIndex] = poly;
      }

    } else {
      App.stage !== "prod" && console.log(r);
    }
  }


  drawRoads = async (polygon: ProjectSitePolygon, save: boolean = true) => {
    const polygonIndex = this.projectSite.findIndex(e => e.getId() === polygon.getId());
    this.clearRoads(polygon);
    const outerPath = polygon.getPath()[0];

    for (let i = 0; i < outerPath.length; i++) {
      let width = 0;
      if (this.roadValue[polygonIndex] !== undefined && this.roadValue[polygonIndex][i] !== undefined) {
        width = this.roadValue[polygonIndex][i];
      } else {

        if (this.roadValue[polygonIndex] === undefined) {
          this.roadValue[polygonIndex] = [];
        }
        this.roadValue[polygonIndex].push(width);
      }

      // road active 관련
      if (this.roadActive[polygonIndex] === undefined) {
        this.roadActive[polygonIndex] = [];
      }
      if (this.roadActive[polygonIndex][i] === undefined) {
        this.roadActive[polygonIndex].push(false);
      }

      this.drawRoad(polygon, polygonIndex, i, width);
    }
    await this.getMergedRoad(polygonIndex, polygon);
    save && this.onUpdateShape("ROAD");
  }

  drawRoad = (polygon: ProjectSitePolygon, siteIndex: number, roadIndex: number, distance: number) => {
    const pathSet = this.dm.getRoadSetOfPath(polygon, roadIndex, distance);
    polygon.road.spacePolygon[roadIndex] = this.dm.addPolygon(pathSet.roadSpacePolygonPath, DrawingManager2.DrawingOption.ROAD_SPACE);
    polygon.road.horizontalLine[roadIndex] = this.dm.addPolyline(pathSet.roadHorizontalPolylinePath, this.roadActive[siteIndex][roadIndex] && DrawingManager2.DrawingOption.ROAD_HORIZONTAL_ACTIVE || DrawingManager2.DrawingOption.ROAD_HORIZONTAL);
    polygon.road.verticalLine[roadIndex] = this.dm.addPolyline(pathSet.roadVeritcalPolylinePath, this.roadActive[siteIndex][roadIndex] && DrawingManager2.DrawingOption.ROAD_VERTICAL_ACTIVE || DrawingManager2.DrawingOption.ROAD_VERTICAL);
  }

  clearRoads = (polygon: ProjectSitePolygon) => {
    const polygonIndex = this.projectSite.findIndex(e => e.getId() === polygon.getId());
    const outerPath = polygon.getPath()[0];
    for (let i = 0; i < outerPath.length; i++) {
      this.clearRoad(polygon, i);
    }
    if (this.roadSite[polygonIndex] !== undefined && this.roadSite[polygonIndex] !== null) {
      this.roadSite[polygonIndex]!.remove(true);
      this.roadSite[polygonIndex] = null;
    }
  }

  clearRoad = (polygon: ProjectSitePolygon, roadIndex: number) => {
    if (polygon.road.spacePolygon[roadIndex] !== undefined) {
      polygon.road.spacePolygon[roadIndex]!.remove();
      polygon.road.spacePolygon[roadIndex] = undefined;
    }
    if (polygon.road.verticalLine[roadIndex] !== undefined) {
      polygon.road.verticalLine[roadIndex]!.remove();
      polygon.road.verticalLine[roadIndex] = undefined;
    }
    if (polygon.road.horizontalLine[roadIndex] !== undefined) {
      polygon.road.horizontalLine[roadIndex]!.remove();
      polygon.road.horizontalLine[roadIndex] = undefined;
    }
  }

  removeElement = (field: keyof this, shape: Shape) => {
    const elementList: Array<Shape> = this[field] as any;
    const index = elementList.findIndex(e => e.getId() === shape.getId());
    elementList.splice(index, 1);
    if (field === "projectSite" && this.roadValue[index] !== undefined) {
      this.roadValue.splice(index, 1);
      this.clearRoads(shape as ProjectSitePolygon);

      this.roadSite[index] !== null && this.roadSite[index]!.remove();
      this.roadSite.splice(index, 1);
    }

    if (field === "skylineLine") {
      this.startMarker.splice(index, 1);
      this.endMarker.splice(index, 1);
    }
  }

  clearMeasureShapeOnMap = () => {
    this.measureAreaPolygons.map(p => p.remove());
    this.measureDistancePolylines.map(p => p.remove());
    this.measureDistanceGuideMarkers.map(p => p.remove());
    this.measureDistanceTrackMarkers.map(p => p.map(pp => pp.remove()));
    this.measureAreaMarkers.map(p => p.remove());
    this.measureDistanceMarkers.map(p => p.remove());

    this.measureAreaPolygons = [];
    this.measureDistancePolylines = [];
    this.measureDistanceGuideMarkers = [];
    this.measureDistanceTrackMarkers = [];
    this.measureAreaMarkers = [];
    this.measureDistanceMarkers = [];
    this.measureDistanceVertex = []
  }

  clearAllShapeOnMap = () => {
    this.projectSite.map(p => {
      this.clearRoads(p);
      p.remove(true);
    });
    this.vacancyInside.map(p => p.remove(true));
    this.vacancyOutside.map(p => p.remove(true));
    this.skylineCircle.map(p => p.remove(true));
    this.skylineLine.map(p => p.remove(true));
    this.roadSite.map(p => p !== null && p.remove(true));
    this.selectedProjectSiteGraph.nodes().map(p => this.selectedProjectSiteGraph.node(p).remove(true));
    this.startMarker.map(p => p.remove(true));
    this.endMarker.map(p => p.remove(true));
    this.boundarySite.map(p => p!.remove());
    this.boundaryExtendedSite.map(p => p!.remove());

    this.roadValue = [];
    this.projectSite = [];
    this.vacancyInside = [];
    this.vacancyOutside = [];
    this.skylineCircle = [];
    this.skylineLine = [];
    this.roadSite = [];
    this.selectedProjectSiteGraph = new Graph();
    this.pnuList = [];
    this.startMarker = [];
    this.endMarker = [];
    this.roadActive = [];
    this.boundaryExtendedSite = [];
    this.boundarySite = [];
  }

  onUpdateShape = async (type?: ShapeType) => {
    const update: Project = {};
    switch (type) {
      case "PROJECT_SITE":
        update.project_site = this.projectSite.map(p => DrawingManager2.toWKT(p));
        update.project_site_center = DrawingManager2.getProjectSiteCenter(this.projectSite).centerWKT;


        switch (this.props.currentProject.project_site_type!) {
          case "DRAW":
            update.project_site_area = this.dm.sumOfSite(this.projectSite.map(p => DrawingManager2.toWKT(p)));

            break;
          case "IMPORT":
            // setting 할때 넣어주는값 이외에 update필요가없음
            break;
          case "SELECT":
            const r = await App.search({
              table: 'parcel-info',
              query: `${this.pnuList!.map(r => `pnu: ${r}`).join(" OR ")}`,
              size: 1000,
            });
            const rr = r.data;
            if (rr.hits && rr.hits.hits) {
              update.project_site_area = rr.hits.hits.map((rrr: any) => {

                if (rrr._source['토지면적'] !== undefined) {
                  return rrr._source['토지면적'];
                } else {
                  return rrr._source['토지면적_계산'];
                }
              }).reduce((a: any, b: any) => a + b, 0);
            }

            if (update.project_site_area && this.state.shapeType === "PROJECT_SITE") {
              const polygonArea = this.dm.sumOfSite(this.projectSite.map(p => DrawingManager2.toWKT(p)));
              const offset = Math.abs(Number(update.project_site_area!.toFixed(2)) - Number(polygonArea.toFixed(2))) / Number(update.project_site_area!.toFixed(2));

              //    console.log(polygonArea.toFixed(2), update.project_site_area.toFixed(2), offset, 'offset');
              this.setState({ projectSiteOffset: offset })
            } else {
              this.setState({ projectSiteOffset: 0 })
            }




            update.road_site = this.roadSite.map(p => {
              if (p !== null) {
                return DrawingManager2.toWKT(p);
              } else {
                return null;
              }
              // p !== null ? DrawingManager2.toWKT(p) : null
            });
            update.road_value = this.roadValue;
            update.field_info_pnu = this.pnuList;
            update.boundary_site = this.boundarySite.map(r => {
              if (r === null) {
                return null;
              } else {
                return DrawingManager2.toWKT(r);
              }
            });

            this.props.updateJiguInfo([]);
            this.jiguInfoPolygonActive && this.jiguInfoPolygonActive.remove();
            this.jiguInfoPolygonHover && this.jiguInfoPolygonHover.remove();
            break;
        }
      case "ROAD":
        // 인접대지경계선 초기화 road 넣기

        update.road_site = this.roadSite.map(p => p !== null ? DrawingManager2.toWKT(p) : null);
        update.road_value = this.roadValue;
        update.auto_road = false;

        this.boundarySite.map(r => r !== null && r.remove());
        this.boundaryExtendedSite.map(r => r !== null && r.remove());

        this.boundarySite = [];
        this.boundaryExtendedSite = [];
        update.boundary_site = [];


        break;

      case 'BOUNDARY_SITE':

        update.boundary_site = this.boundarySite.map(p => p !== null ? DrawingManager2.toWKT(p) : null);

        // road 초기화 & 인접대지경계선 넣기  
        if (this.props.currentProject.project_site_type === "SELECT") {
          this.projectSite.map(p => this.clearRoads(p));
          this.roadSite.map(p => p !== null && p.remove())
          update.auto_road = true;
          this.roadSite = [];
          this.roadValue = [];
          this.roadActive = [];
          update.road_site = [];
          update.road_value = [];
        } else {

        }
        // 공지영역 면적
        // update.vacancy_outside_area = this.dm.sumOfSite(this.boundaryExtendedSite.filter(p => p !== null).map(pp => DrawingManager2.toWKT(pp!)));




        break;

      case "VACANCY_INSIDE":
        update.vacancy_inside = this.vacancyInside.map(p => DrawingManager2.toWKT(p));
        update.vacancy_inside_area = this.dm.sumOfSite(this.vacancyInside.map(p => DrawingManager2.toWKT(p)));
        break;

      case "VACANCY_OUTSIDE":
        update.vacancy_outside = this.vacancyOutside.map(p => DrawingManager2.toWKT(p));
        update.vacancy_outside_area = this.dm.sumOfSite(this.vacancyOutside.map(p => DrawingManager2.toWKT(p)));
        break;

      case "SKYLINE_CIRCLE":
        update.skyline_circle = this.skylineCircle.map(p => DrawingManager2.toWKT(p));
        break;

      case "SKYLINE_LINE":
        update.skyline_line = this.skylineLine.map(p => DrawingManager2.toWKT(p));
        break;

      default:
        update.project_site = [];
        update.project_site_center = "";
        update.project_site_area = 0;
        update.road_site = [];
        update.road_value = [];
        update.field_info_pnu = [];
        update.vacancy_inside = [];
        update.vacancy_outside = [];
        update.vacancy_inside_area = 0;
        update.vacancy_outside_area = 0;
    }


    const preRoadSite = _.cloneDeep(this.props.currentProject.road_site)
    const preProjectSite = _.cloneDeep(this.props.currentProject.project_site)
    this.props.onUpdateProject({ ...update }, async () => {
      if (this.props.currentProject.project_site_type !== "SELECT") {
        await this.drawBoundarySite();
        const bs = this.boundarySite.map(r => r === null ? null : DrawingManager2.toWKT(r))
        this.props.onUpdateProject({ boundary_site: bs })
      } else { // 필지 모드
        if (!this.props.currentProject.auto_road) {
          await this.drawBoundarySite();
          const bs = this.boundarySite.map(r => r === null ? null : DrawingManager2.toWKT(r))
          this.props.onUpdateProject({ boundary_site: bs })
        }
      }


      if (type && type === "PROJECT_SITE") {
        if (this.props.currentProject.project_site!.length > 0 && !_.isEqual(preProjectSite, this.props.currentProject.project_site)) {
          this.props.onUpdateAddress(() => {
            // if (this.props.currentProject.auto_setting) {
            //   this.props.onUpdateDefaults();
            // }
            this.props.onUpdateDefaults(undefined, undefined, "All");
          });
        }
      }
    })
  }

  setShapeOnMap = async () => {
    this.props.currentProject.vacancy_inside!.map(p => {
      const path = DrawingManager2.toGeom(p, "Polygon").coordinates;
      const polygon = this.dm.addPolygon(path, DrawingManager2.DrawingOption.VACANCY_INSIDE, this.props.currentProject.project_site_type !== "IMPORT");
      polygon.on('changed', () => {
        this.onUpdateShape("VACANCY_INSIDE")
      });
      polygon.on('removed', () => {
        this.removeElement("vacancyInside", polygon);
        this.onUpdateShape("VACANCY_INSIDE");
      });
      this.vacancyInside.push(polygon);

      if (this.state.projectSiteType !== "IMPORT" && this.state.shapeType !== "VACANCY_INSIDE") {
        polygon.setOptions({ clickable: false, zIndex: DrawingManager2.DrawingOption.VACANCY_INSIDE.zIndex });
      }
    });

    try {
      this.props.currentProject.vacancy_outside!.map(p => {
        const path = DrawingManager2.toGeom(p, "Polygon").coordinates;
        const polygon = this.dm.addPolygon(path, DrawingManager2.DrawingOption.VACANCY_OUTSIDE, this.props.currentProject.project_site_type !== "IMPORT");
        polygon.on('changed', () => { this.onUpdateShape("VACANCY_OUTSIDE") });
        polygon.on('removed', () => {
          this.removeElement("vacancyOutside", polygon);
          this.onUpdateShape("VACANCY_OUTSIDE");
        });
        this.vacancyOutside.push(polygon);
        if (this.state.projectSiteType !== "IMPORT" && this.state.shapeType !== "VACANCY_OUTSIDE") {
          polygon.setOptions({ clickable: false, zIndex: DrawingManager2.DrawingOption.VACANCY_OUTSIDE.zIndex });
        }
      });
    } catch (e) {
      console.log(e)
      this.props.setModal(true, {
        negative: "hidden",
        title: "오류 안내",
        content: <div>그릴 수 없는 나의 파일입니다.</div>,
        type: "SIMPLE",
        color: "DARK",
        positiveTitle: "확인",
      });
    }


    this.props.currentProject.skyline_circle!.map(p => {
      const path = DrawingManager2.toGeom(p, "Polygon").coordinates;
      const circle = this.dm.addCircle(path, DrawingManager2.DrawingOption.SKYLINE_CIRCLE, true);
      circle.on('changed', () => { this.onUpdateShape("SKYLINE_CIRCLE") });
      circle.on('removed', () => {
        this.removeElement("skylineCircle", circle);
        this.onUpdateShape("SKYLINE_CIRCLE");
      });
      this.skylineCircle.push(circle);
      if (this.state.projectSiteType !== "IMPORT" && this.state.shapeType !== "SKYLINE_CIRCLE") {
        circle.setOptions({ clickable: false, zIndex: DrawingManager2.DrawingOption.SKYLINE_CIRCLE.zIndex });
      }
    });

    this.props.currentProject.skyline_line!.map(p => {
      const path = DrawingManager2.toGeom(p, "LineString").coordinates;

      const line = this.dm.addPolyline(path, DrawingManager2.DrawingOption.SKYLINE_LINE);
      const start = this.dm.addMarker(path[0], DrawingManager2.DrawingOption.MAX_FLOOR_MARKER, true);
      const end = this.dm.addMarker(path[1], DrawingManager2.DrawingOption.MIN_FLOOR_MARKER, true);
      this.startMarker.push(start);
      this.endMarker.push(end);
      this.skylineLine.push(line);

      if (this.state.shapeType !== "SKYLINE_CIRCLE") {
        start.setOptions({ clickable: false, visible: false });
        end.setOptions({ clickable: false, visible: false });
      }

      // if (this.state.projectSiteType === "SELECT" ) {
      start.on('changed', () => {
        const markerPosition = start.getPosition();
        const path = line.getPath();
        path[0] = [markerPosition.x, markerPosition.y];
        line.setPath(path);
        line.emit('changed');
      });
      end.on('changed', () => {
        const markerPosition = end.getPosition();
        const path = line.getPath();
        path[1] = [markerPosition.x, markerPosition.y];
        line.setPath(path);
        line.emit('changed');
      });
      start.on('removed', () => {
        end.remove(true);
        line.remove();
      });
      end.on('removed', () => {
        start.remove(true);
        line.remove();
      });

      line.on('changed', () => {
        this.onUpdateShape("SKYLINE_LINE");
      })
      line.on('removed', () => {
        this.removeElement("skylineLine", line);
        this.onUpdateShape("SKYLINE_LINE");
      })
    });

    if (this.props.currentProject.boundary_site !== undefined && this.props.currentProject.boundary_site.length > 0) {
      this.props.currentProject.boundary_site!.map(p => {
        if (p !== null) {
          const path = DrawingManager2.toGeom(p, "Polygon").coordinates;
          const polygon = this.dm.addPolygon(path, {
            ...DrawingManager2.DrawingOption.BOUNDARY_SITE
          }, false);
          polygon.setEditable(false);
          polygon.setOptions({ clickable: false })
          this.boundarySite.push(polygon);
        }
      });
    } else {
      await this.drawBoundarySite();
    }


    // try {
    //   this.props.currentProject.boundary_site!.map(p => {
    //     if (p !== null) {
    //       const path = DrawingManager2.toGeom(p, "Polygon").coordinates;
    //       const polygon = this.dm.addPolygon(path, DrawingManager2.DrawingOption.BOUNDARY_SITE, false);
    //       this.boundarySite.push(polygon);
    //     }
    //   });
    // } catch (e) {
    //   console.log(e)
    //   // this.props.setModal(true, {
    //   //   negative: "hidden",
    //   //   title: "오류 안내",
    //   //   content: <div>그릴 수 없는 나의 파일입니다.</div>,
    //   //   type: "SIMPLE",
    //   //   color: "DARK",
    //   //   positiveTitle: "확인",
    //   // });
    // }

    const lambda = await new AWSModule("LAMBDA").connect();
    this.roadValue = this.props.currentProject.road_value!;
    if (this.props.currentProject.project_site_type === "SELECT") {
      this.pnuList = this.props.currentProject.field_info_pnu!;

      for (let i = 0; i < this.props.currentProject.field_info_pnu!.length; i++) {
        await (async () => {
          const r = await App.API_Axios.post(`/GetFieldInfoAtPNU`, {
            pnu: this.props.currentProject.field_info_pnu![i]
          });

          const r_res = {
            success: true,
            reason: null,
            body: r.data,
          };

          if (r_res.success) {
            const path = DrawingManager2.toGeom(r_res.body.geom, "Field").coordinates;
            const polygon = this.dm.addPolygon(path, { ...DrawingManager2.DrawingOption.PROJECT_SITE, simplify: false }, true) as ProjectSitePolygon;
            polygon.setRightClick(false);
            (polygon as ProjectSitePolygon).setPnu(r_res.body.pnu);
            this.projectSite[i] = polygon;

            this.setGrpahNode(polygon);

            // z-idnex clickable
            if (this.state.shapeType !== "PROJECT_SITE") {
              polygon.setOptions({ clickable: false, zIndex: DrawingManager2.DrawingOption.PROJECT_SITE.zIndex });
            } else if (this.state.shapeType === "PROJECT_SITE") {
              polygon.setOptions({ clickable: true, zIndex: 0 });
            }

            // 삭제시
            polygon.on('click', () => {
              const index = this.pnuList.findIndex(e => e === (polygon as ProjectSitePolygon).getPnu());
              this.pnuList.splice(index, 1);
              this.selectedProjectSiteGraph.removeNode(polygon.getId()!);
              polygon.remove();

              this.roadValue = [];
              this.roadActive = [];
              this.unionProjectSite(() => {
                this.redrawRoads();
              });

              this.boundarySite.map(r => r !== null && r.remove());
              this.boundaryExtendedSite.map(r => r !== null && r.remove());
              this.boundarySite = [];
              this.boundaryExtendedSite = [];

              this.onUpdateShape("PROJECT_SITE");
            });

            // console.log('unionProjectSite()');
          } else {
            console.log(r_res.reason);
          }
        })();
      }
      this.unionProjectSite();
      this.setMapZoom();
      if (this.props.currentProject.auto_road === false && this.props.currentProject.road_value && this.props.currentProject.road_value.length > 0) {
        await Promise.all(this.projectSite.map((r) => this.drawRoads(r)));
      } else if (this.props.currentProject.auto_road && this.props.currentProject.boundary_site!.length > 0) {
        this.drawShape('BOUNDARY_SITE', { disableOpenRoadConfig: true });
      }


      const pList = this.projectSite.map(p => DrawingManager2.toWKT(p));
      const mm = this.dm!.getAABB(pList);
      mm && this.dm!.map!.fitBounds(mm.min, mm.max);

    } else if (this.props.currentProject.project_site_type === "DRAW") {
      this.props.currentProject.project_site!.map(async (p, i) => {
        const path = DrawingManager2.toGeom(p, "Polygon").coordinates;
        const polygon = this.dm.addPolygon(path, DrawingManager2.DrawingOption.PROJECT_SITE, true) as ProjectSitePolygon;
        polygon.on('changed', _.debounce(async () => {
          if (polygon.getHasRoad()) {
            await this.drawRoads(polygon, true);
          }
          this.onUpdateShape("PROJECT_SITE");
        }, 500));
        polygon.on('vertex_added', (index: number) => {
          const polygonIndex = this.projectSite.findIndex(e => e.getId() === polygon.getId());
          this.roadValue[polygonIndex] !== undefined && this.roadValue[polygonIndex].splice(index - 1, 0, this.roadValue[polygonIndex][index - 1]);
          this.onUpdateShape("PROJECT_SITE");
        });
        polygon.on('removed', () => {
          this.removeElement("projectSite", polygon);
          this.onUpdateShape("PROJECT_SITE");
        });
        this.projectSite.push(polygon);

        if (this.state.shapeType !== "PROJECT_SITE") {
          polygon.setOptions({ clickable: false, zIndex: DrawingManager2.DrawingOption.PROJECT_SITE.zIndex });
        } else if (this.state.shapeType === "PROJECT_SITE") {
          polygon.setOptions({ clickable: true, zIndex: 0 });
        }
        this.roadValue[i] !== undefined && this.roadValue[i].length > 0 && this.drawRoads(polygon, !this.props.edit);

      });
      // await this.drawBoundarySite();
      // this.onUpdateShape('BOUNDARY_SITE');
    } else {
      this.props.currentProject.project_site!.map((p, i) => {
        const path = DrawingManager2.toGeom(p, "Polygon").coordinates;
        const polygon = this.dm.addPolygon(path, { ...DrawingManager2.DrawingOption.PROJECT_SITE, simplify: false }) as ProjectSitePolygon;
        this.projectSite.push(polygon);
      });
      this.props.currentProject.road_site!.map(p => {
        if (p !== null) {
          const path = DrawingManager2.toGeom(p, "Polygon").coordinates;
          const polygon = this.dm.addPolygon(path, DrawingManager2.DrawingOption.ROAD_SITE);
          this.roadSite.push(polygon);
        } else {
          this.roadSite.push(null);
        }
      });
      // await this.drawBoundarySite();
      // this.onUpdateShape('BOUNDARY_SITE');
    }
    this.setMapZoom();
    this.props.onUpdateAddress(() => {
      // if (this.props.currentProject.auto_setting) {
      //   this.props.onUpdateDefaults();
      // }
      this.props.onUpdateDefaults(undefined, undefined, "All");
    });
  }

  drawBoundarySite = async () => {
    this.boundarySite.map(r => r!.remove());
    this.boundarySite = [];
    let unionPolygon: any = undefined;
    let unionPolygonWKT = undefined;

    const reader = new jsts.io.WKTReader();
    const writer = new jsts.io.WKTWriter();
    const cp = this.props.currentProject;
    for (let i = 0; i < cp.project_site!.length; i++) {
      // 자기자신을 union
      if (cp.project_site![i] !== undefined) {
        unionPolygon = reader.read(cp.project_site![i]);
      }
      // 도로영역과 union
      if (cp.road_site![i] !== null && cp.road_site![i] !== undefined) {
        const road = reader.read(cp.road_site![i]).buffer(0.01);
        unionPolygon = unionPolygon.union(road);
      }
      // 공지영역과 union
      for (let j = 0; j < cp.vacancy_outside!.length; j++) {
        const vo = reader.read(cp.vacancy_outside![j]).buffer(0.01);;
        if (vo.intersects(unionPolygon)) {
          unionPolygon = unionPolygon.union(vo);
        }
      }
      // for (let j = 0; j < unionPolygon._holes.length; j++) {
      //   console.log(unionPolygon._holes[j].)
      // }
    }

    if (unionPolygon !== undefined) {
      const originSingleProjectSite = reader.read(cp.project_site![0]);
      unionPolygonWKT = writer.write(unionPolygon);
      let holePolygons: any[] = [];
      unionPolygon._holes && unionPolygon._holes.map((r: any, i: number) => {
        let h = writer.write(unionPolygon.getInteriorRingN(i));
        h = h.replace('LINEARRING', 'POLYGON').replace('(', '((').replace(')', '))')
      })
      if (cp.project_site![0] !== unionPolygonWKT) {
        const lambda = await new AWSModule("LAMBDA").connect();
        const polySet = [this.props.currentProject.project_site![0], unionPolygonWKT];
        holePolygons.map(h => polySet.push(h));
        const r2 = await lambda.Lambda!.invoke({
          FunctionName: `arn:aws:lambda:ap-northeast-2:331053433621:function:engine-roadline-generator-${App.stage}`,
          Payload: JSON.stringify({
            "isRoad": "false",
            "polySet": polySet,
            "genType": "USERDEF",
          }),
        }).promise();

        const p2 = JSON.parse(r2.Payload as string);
        const polygon2 = this.dm.addPolygon(DrawingManager2.toGeom(p2.body, "Polygon").coordinates, {
          ...DrawingManager2.DrawingOption.BOUNDARY_SITE
        }, false);


        polygon2.setEditable(false);
        polygon2.setOptions({ clickable: false })
        this.boundarySite.push(polygon2);
      }
    } else {
      this.boundarySite = [];
    }

  }

  setMapZoom = () => {
    if (this.projectSite.length > 0) {
      const pList: string[] = [];
      this.projectSite.map(p => pList.push(DrawingManager2.toWKT(p)));

      const mm = this.dm!.getAABB(pList);
      mm && this.map!.fitBounds(mm.min, mm.max);
    } else if (this.state.mapCenter) { // 초기화했을때 이전 mapCenter 기준으로 오게끔
      this.map!.setCenter(this.state.mapCenter)
    }
  }
  getPNUInfo = async (coord: { x: number, y: number }, callback: Function) => {

  }

  selectPNU = async (coord: { x: number, y: number }) => {
    // multi_select_project_site = 합필모드
    const updatePolygon = (pnu: string, geom: string) => {
      this.pnuList.push(pnu);
      this.roadSite.map(p => p !== null && p.remove()); // polygon
      this.boundarySite.map(p => p !== null && p.remove());
      this.boundaryExtendedSite.map(p => p !== null && p.remove());
      this.projectSite.map(p => {
        this.clearRoads(p);
        p.remove(true);
      });
      this.roadValue = []; // 인접도로 입력값
      this.roadActive = [];  // 인접도로 true false


      this.boundarySite = [];
      this.boundaryExtendedSite = [];
      this.roadSite = [];

      const polygon = this.dm.addPolygon(DrawingManager2.toGeom(geom, "Field").coordinates, { ...DrawingManager2.DrawingOption.PROJECT_SITE, simplify: false }, true);
      polygon.setRightClick(false);
      (polygon as ProjectSitePolygon).setPnu(pnu);

      this.setGrpahNode(polygon);

      // 삭제시
      polygon.on('click', () => {
        const index = this.pnuList.findIndex(e => e === (polygon as ProjectSitePolygon).getPnu());
        this.pnuList.splice(index, 1);
        this.selectedProjectSiteGraph.removeNode(polygon.getId()!);
        this.clearRoads(polygon as ProjectSitePolygon);
        polygon.remove();
        this.roadValue = [];
        this.roadActive = [];
        this.unionProjectSite(() => { });

        this.boundarySite.map(r => r !== null && r.remove())
        this.boundaryExtendedSite.map(r => r !== null && r.remove())

        this.boundarySite = [];
        this.boundaryExtendedSite = [];

        this.onUpdateShape("PROJECT_SITE");
      });

      this.unionProjectSite();
      this.onUpdateShape("PROJECT_SITE");
      return;
    }
    const setPolygon = (payload: { success: boolean, body: any, reason: any }) => {
      const pnu = payload.body.pnu;
      const geom = payload.body.geom;
      if (this.pnuList.findIndex(e => e === pnu) < 0) { // 여러번 눌리는거 방지
        if (!this.props.currentProject.multi_select_project_site && this.pnuList.length > 0) {
          // 처리 delay로 multi select 되는 상황
          return;
        }

        const existNeighborField = this.selectedProjectSiteGraph.nodes().map(r => {
          return this.isDiffer(geom, DrawingManager2.toWKT(this.selectedProjectSiteGraph.node(r)))
        }).filter(item => item === false).length > 0;

        if (!existNeighborField && this.selectedProjectSiteGraph.nodes().length > 1) {
          this.props.setModal(true, {
            positive: () => {
              this.props.setModal(false)
            },
            negative: () => {
              updatePolygon(pnu, geom);
              this.props.setModal(false)
            },
            title: "필지 선택 안내",
            content: <div>
              <div>인접한 필지가 아닙니다.</div><br />
              <div>떨어진 필지를 선택하시면<br />선택된 필지들은 <b>초기화</b> 됩니다.</div>
            </div>,
            type: "SIMPLE",
            color: "DARK",
            positiveTitle: "선택 취소",
            negativeTitle: "초기화 및 떨어진 필지 선택",
          });
          return;
        }
        updatePolygon(pnu, geom);
      }
    }
    const convertJimog = (jimog?: string) => {
      let jimogFullName;
      switch (jimog) {
        case "도": jimogFullName = "도로"; break;
        case "공": jimogFullName = "공원"; break;
        case "천": jimogFullName = "하천"; break;
        default:  jimogFullName = "정의 되지 않은"; break;
      }
      return jimogFullName;
    }

    if (this.props.currentProject.multi_select_project_site === false && this.selectedProjectSiteGraph.nodeCount() > 0) {
      this.projectSite.map(p => {
        this.clearRoads(p);
        p.remove(true);
      });
      this.roadSite.map(p => p !== null && p.remove(true));
      this.selectedProjectSiteGraph.nodes().map(p => this.selectedProjectSiteGraph.node(p).remove(true));
      this.boundaryExtendedSite.map(p => p !== null && p.remove());
      this.boundarySite.map(p => p !== null && p.remove());

      this.boundaryExtendedSite = [];
      this.boundarySite = [];
      this.roadValue = [];
      this.roadActive = [];
      this.projectSite = [];
      this.roadSite = [];
      this.selectedProjectSiteGraph = new Graph();
      this.pnuList = [];
    }

    const r = await App.API_Axios.post(`/GetFieldInfoAtPoint`, {
      ...coord,
    });

    const payload = {
      success: true,
      body: r.data,
      reason: null,
    };
    if (payload.success) {
      if (["도", "공", "천", undefined].includes(payload.body.jimog)) {
        this.props.setModal(true, {
          title: "안내",
          content: <div>선택 필지는 지목이 '{convertJimog(payload.body.jimog)}' 입니다.<br />대지영역에 추가하시겠습니까?</div>,
          type: "SIMPLE",
          color: "DARK",
          positiveTitle: "대지영역에 추가하기",
          positive: () => { 
            setPolygon(payload);
            this.props.setModal(false);
          },
          negative: () => { 
            this.onUpdateShape("PROJECT_SITE");
            this.props.setModal(false) 
          }
        });
        return;
      }
      setPolygon(payload);
    } else {
      console.log('err', payload.reason);
    }
  }

  toWTKFromPolygon = (polygon: Polygon) => {
    const path = polygon.getPath();
    DrawingManager2.toWKT(polygon)
  }

  isDiffer = (p1: string, p2: string) => {
    let differ = false;
    let isOverlap = false;
    let type = "";

    const reader = new jsts.io.WKTReader();
    const poly1 = reader.read(p1).buffer(0.05);
    const poly2 = reader.read(p2).buffer(0.05);

    isOverlap = poly1.intersects(poly2);
    type = poly1.union(poly2).getGeometryType();

    return !isOverlap;
  }

  /*
    1개 제한
    differ: 떨어져있는것 찾기
  */


  setGrpahNode = (polygon?: Polygon) => {
    let differ = false;
    let isOverlap = false;
    let type = "";

    const nodes = this.selectedProjectSiteGraph.nodes();
    if (polygon) {
      for (let i = 0; i < nodes.length; i++) {
        differ = this.isDiffer(DrawingManager2.toWKT(polygon), DrawingManager2.toWKT(this.selectedProjectSiteGraph.node(nodes[i])));
        if (!differ) {
          this.selectedProjectSiteGraph.setNode(polygon.getId()!, polygon);
          this.selectedProjectSiteGraph.setEdge(nodes[i], polygon.getId()!);

          // 폴리곤 하나클릭에 여러개 지워지는거
          const reader = new jsts.io.WKTReader();
          for (let i = 0; i < nodes.length; i++) {
            const p3 = reader.read(DrawingManager2.toWKT(this.selectedProjectSiteGraph.node(nodes[i])));
            isOverlap = reader.read(DrawingManager2.toWKT(polygon)).intersects(p3);
            if (isOverlap) {
              this.selectedProjectSiteGraph.setEdge(nodes[i], polygon.getId()!);
            }
          }
          return;
        }
      }
      // 1개 제한
      if (differ) {
        this.selectedProjectSiteGraph.nodes().map(p => this.selectedProjectSiteGraph.node(p).remove(true));
        this.selectedProjectSiteGraph = new Graph();
        this.projectSite.map(p => p.remove());
        this.projectSite = [];
        this.pnuList = [this.pnuList[this.pnuList.length - 1]];
        isOverlap = false;



      }

      if (isOverlap === false) {
        this.selectedProjectSiteGraph.setNode(polygon.getId()!, polygon);
      }
    } else {

    }

  }

  unionProjectSite = (callback?: Function) => {

    const reader = new jsts.io.WKTReader();
    const writer = new jsts.io.WKTWriter();
    // const p = reader.read(road_site[0]);

    const union: Array<any> = [];
    this.projectSite.map(p => this.clearRoads(p));
    this.projectSite = [];

    graphlib.alg.components(this.selectedProjectSiteGraph).map((g, i) => {

      g.map((p, j) => {
        if (!union[i]) {
          const polygon = this.selectedProjectSiteGraph.node(p);
          union[i] = reader.read(DrawingManager2.toWKT(polygon));
        } else {
          union[i] = union[i].union(reader.read(DrawingManager2.toWKT(this.selectedProjectSiteGraph.node(p))).buffer(0.05))

        }
      })
    })


    // project_site여러개들어오면 한개에대해서만 처리하기
    const bigSiteAreas = union.map(r => r.getArea());
    const bigSiteIndex = bigSiteAreas.indexOf(Math.max(...bigSiteAreas));

    let bigUnion;
    if (bigSiteIndex > -1) {
      bigUnion = writer.write(union[bigSiteIndex]);



      const polygon = this.dm.addPolygon(DrawingManager2.toGeom(bigUnion, "Polygon").coordinates, { ...DrawingManager2.DrawingOption.PROJECT_SITE, simplify: true }, true);
      polygon.setOptions({ visible: false });
      this.projectSite.push(polygon as ProjectSitePolygon);

      graphlib.alg.components(this.selectedProjectSiteGraph).map((r, i) => {

        if (i !== bigSiteIndex) {

          r.map(rr => {
            let p = this.selectedProjectSiteGraph.node(rr);
            p.remove();
            this.selectedProjectSiteGraph.removeNode(rr);
            let index = this.pnuList.findIndex(e => e === p.getPnu())
            if (index > -1) {
              this.pnuList.splice(index, 1);
            }
          })
        }
      })

    }

    if (callback) {
      callback();
    }
  }

  toTurfPolygon = (polygon: Polygon) => {
    const path = polygon.getPath();
    for (let i = 0; i < path.length; i++) {
      // @ts-ignore
      path[i].push(path[i][0]);
    }
    const p = turf.polygon(path);
    return p;
  }

  searchPlace = async (address: string) => {
    this.setState({
      searchPlaceLoading: true
    }, async () => {
      App.naver.maps.Service.geocode({
        query: address.trim(),
      }, async (status: any, response: any) => {

        if (status === App.naver.maps.Service.Status.ERROR) {
          return alert('Something Wrong!');
        }

        let rr0, rr1, rr2;
        if (response.v2.addresses && response.v2.addresses.length > 0) {
          var htmlAddresses = [],
            item = response.v2.addresses[0],
            point = new App.naver.maps.Point(item.x, item.y);
        }
        rr0 = response.v2;
        this.setState({
          geocodeResult: rr0.addresses !== undefined && rr0.addresses || [],
          addressResult: [],
          placeResult: [],
        });
        const lambda = await new AWSModule("LAMBDA").connect();
        if (address.length > 1) {
          const r1 = await App.search({
            "table": 'parcel-info',
            "query": {
              "wildcard": {
                "주소.keyword": {
                  "value": `*${address.trim()}*`,
                }
              }
            }
          });
          rr1 = r1.data; // 주소 검색 결과
          this.setState({
            addressResult: rr1.hits.hits.map((r: any) => {
              return {
                address: r._source['주소'],
                ...r._source
              }
            }),
          })
        }
        const mapCenter = this.map!.getCenter();

        // const r2 = await lambda.Lambda!.invoke({
        //   FunctionName: "arn:aws:lambda:ap-northeast-2:331053433621:function:platform-buildit-SearchPlaces-v20191206",
        //   Payload: JSON.stringify({ 
        //     query: address,
        //     coordinate: `${mapCenter.x},${mapCenter.y}`
        //   })
        // }).promise();
        // rr2 = JSON.parse(r2.Payload as string); // 장소 검색 결과
        this.setState({
          //placeResult: rr2.success && rr2.body.places && rr2.body.places.map((r: any) => r) || [],
          placeResult: [],
          searchPlaceLoading: false,
        })
      })
    })
  }

  clearDrawingManager = () => {
    if (this.startMarker.length !== this.endMarker.length) {
      this.startMarker[this.startMarker.length - 1].remove();
      this.startMarker.splice(this.startMarker.length - 1, 1);
    }
    this.dm.clearDrawingManager();
    this.setState({ shapeVertexLength: 0 });
  }
}
import * as jsts from 'jsts';
import * as THREE from '@teneleven/three';

const DXF_writer = require('dxf-writer');

export enum DXFColor {
  layer = DXF_writer.ACI.LAYER,
  red = DXF_writer.ACI.RED,
  yellow = DXF_writer.ACI.YELLOW,
  green = DXF_writer.ACI.GREEN,
  cyan = DXF_writer.ACI.CYAN,
  blue = DXF_writer.ACI.BLUE,
  magenta = DXF_writer.ACI.MAGENTA,
  white = DXF_writer.ACI.WHITE,
}

export class DXFMaker {
  writer: any;
  layerList: string[] = [];
  boundry: any;
  scale: number;
  constructor(boundryWKT: string) {
    this.writer = new DXF_writer();
    this.writer.setUnits('Millimeters');
    let reader = new jsts.io.WKTReader();
    this.boundry = reader.read(boundryWKT);
    this.scale = 1000;

    this.writer.addLayer('text', DXFColor.green, 'CONTINUOUS');
    this.writer.addLayer('field', DXFColor.yellow, 'CONTINUOUS');
    this.writer.addLayer('building', DXFColor.white, 'CONTINUOUS');
    this.writer.addLayer('road', DXFColor.yellow, 'CONTINUOUS');
  }


  ActiveLayer = (name: string, color: any = DXF_writer.ACI.WHITE) => {
    if (this.layerList.findIndex(layer => layer === name) > 0)
      this.writer.setActiveLayer(name);
    else {
      this.writer.addLayer(name, color, 'CONTINUOUS');
      this.writer.setActiveLayer(name);
      this.layerList.push(name);
    }
  }

  AddPolyline = (geoString: string, number: string = '') => {
    let reader = new jsts.io.WKTReader();
    try {
      let geo = reader.read(geoString).intersection(this.boundry);

      this.writer.setActiveLayer('text');
      let center = geo.getCentroid();
      this.writer.drawText(center.getX() * this.scale, center.getY() * this.scale, this.scale, 0, number, 'center', 'middle');

      this.writer.setActiveLayer('field');
      this.writeGeomertry(geo);
    } catch (e) {

    }
  }

  writeGeomertry = (geo: jsts.geom.Geometry) => {
    for (let i = 0; i < geo.getNumGeometries(); i++) {
      let writerPoints: number[][] = [];
      //@ts-ignore
      let subGeo = geo.getGeometryN(i).buffer(0.001);
      //@ts-ignore
      subGeo._shell.getCoordinates().forEach(c => {
        writerPoints.push([c.x * this.scale, c.y * this.scale, 0]);
      })
      this.writer.drawPolyline(writerPoints, true);

      //@ts-ignore
      let holes = subGeo._holes;
      holes.forEach((h: any) => {
        writerPoints = [];
        h.getCoordinates().forEach((c: any) => {
          writerPoints.push([c.x * this.scale, c.y * this.scale, 0]);
        })
        this.writer.drawPolyline(writerPoints, true);
      });
    }
  }

  AddRoadPolyline = (geoString: string) => {
    let reader = new jsts.io.WKTReader();
    this.writer.setActiveLayer('road');
    try {
      let geo = reader.read(geoString).intersection(this.boundry);
      this.writeGeomertry(geo);
    } catch (e) {

    }
  }

  AddBuildingPolyline = (geoString: string) => {
    this.writer.setActiveLayer('building');
    // this.writer.setTrueColor(0xDCDCDC);

    let reader = new jsts.io.WKTReader();
    try {
      let geo = reader.read(geoString).intersection(this.boundry);
      this.writeGeomertry(geo);
    } catch (e) {

    }
  }

  //pos: center pos
  AddText = (pos: THREE.Vector2, height: number, text: string) => {
    this.writer.drawText(pos.x, pos.y, height, 0, text, 'center', 'middle');
  }

  DonwloadDXF = () => {
    this.writer.addLayer('Boundry', DXFColor.blue, 'CONTINUOUS');
    this.writer.setActiveLayer('Boundry');

    let writerPoints: number[][] = [];
    this.boundry.getCoordinates().forEach((c: any) => {
      writerPoints.push([c.x * this.scale, c.y * this.scale, 0]);
    })
    this.writer.drawPolyline(writerPoints, true);

    let dxfString = this.writer.toDxfString();
    let blob = new Blob([dxfString as Buffer]);
    let url = URL.createObjectURL(blob);
    var link = document.createElement('a');
    link.setAttribute('download', 'test.dxf');
    link.href = url;
    link.click();
  }
}
import React, { Component } from 'react';
import { BuildingComponent } from './BuildingPart';
import { ConverterInputBox } from './ConverterInputBox';
import { BuildingHouseUnit } from './BuildingHouseUnit';
import { BuildingCoreUnit } from './BuildingCoreUnit';

interface componentState {
  extraMenu: boolean;
  update: number;
}

interface componentProp {
  compoent: BuildingComponent;
  updateArea: () => void;
}


export class ComponentUI extends Component<componentProp, componentState> {
  state: componentState = {
    extraMenu: false,
    update: 0,
  }

  pageUpdate = () => {
    this.props.updateArea();
    this.setState({ update: this.state.update + 1 })
  }

  render = () => {
    return (
      <div key={this.props.compoent.uuid}>
        {(this.props.compoent.componentType === 'core' &&
          <div className='coreUI'>
            <div>
              <ConverterInputBox name='코어 면적' value={(this.props.compoent as BuildingCoreUnit).GetArea()} valueUpdate={this.pageUpdate} valueOnChange={(this.props.compoent as BuildingCoreUnit).SetArea} unit='㎡' step={0.01} />
            </div>
            <div className='Line' />
          </div>) ||
          <div className='houseUI'>
            <div className='inputValues'>
              <ConverterInputBox name='전용 면적' value={(this.props.compoent as BuildingHouseUnit).exclusiveArea} valueUpdate={this.pageUpdate} valueOnChange={(this.props.compoent as BuildingHouseUnit).SetExclusiveArea} unit='㎡' step={0.01} />
              <ConverterInputBox name='벽체 공용 면적' value={(this.props.compoent as BuildingHouseUnit).commonWallArea} valueUpdate={this.pageUpdate} valueOnChange={(this.props.compoent as BuildingHouseUnit).SetCommonWallArea} unit='㎡' step={0.01} />
            </div>
            <div style={{ position: 'relative', width: 'fit-content' }}>
              <div className='extraMenu' onClick={() => this.setState({ extraMenu: !this.state.extraMenu })}>자세히+</div>
              <ConverterInputBox name='발코니 면적' value={(this.props.compoent as BuildingHouseUnit).serviceArea} valueUpdate={this.pageUpdate} valueOnChange={(this.props.compoent as BuildingHouseUnit).SetServiceArea} unit='㎡' step={0.01} />
            </div>
            <div className={`inputValues ${!this.state.extraMenu && 'hidden'}`} style={{ margin: '5px 0 0 0' }}>
              <ConverterInputBox name='서비스 면적' value={(this.props.compoent as BuildingHouseUnit).balconyLess150cm} valueUpdate={this.pageUpdate} valueOnChange={(this.props.compoent as BuildingHouseUnit).SetBalconyOver150cm} disable={true} unit='㎡' step={0.1} />
              <ConverterInputBox name='용적율 포함 면적' value={(this.props.compoent as BuildingHouseUnit).balconyOver150cm} valueUpdate={this.pageUpdate} valueOnChange={(this.props.compoent as BuildingHouseUnit).SetBalconyOver150cm} unit='㎡' step={0.01} />
            </div>
          </div>
        }
      </div>
    )
  }
}

import * as THREE from '@teneleven/three';
import { BuildingTypeData, ConverterLayer, mapProjectionData, screenInfo } from "./DataTypes";
import { switchLineDashedState } from './FileParser';
import { areaProportion } from './resultLocationDataStruct';
import { loc_building_stories_avg } from './resultDataStruct';
import * as jsts from 'jsts';
import App from '../App';
import { Field } from './Field';
import { House } from './House';
import { Core } from './Core';
import { NaverPoint } from './NaverMapManager';
import { tm2latlng } from './SceneManager';
import { BuildingComponent, ConverterBuilding } from './BuildingPart';
const uuid4 = require('uuid/v4');
const earcut = require('earcut');

export function MakeANewBuilding(name: string, hideList = false) {
  let building: BuildingTypeData = {
    id: uuid4(),
    cores: [],
    houses: [],
    name: name,
    showList: false,
    totalExclusiveAreas: 0,
    totalServiceAreas: 0,
    totalCoreAreas: 0,
    buildingArea: 0,
    groundArea: 0,
    hideList: hideList,
  }
  return building;
}

export function deleteHouseFromHouseList(houses: House[], house: House) {
  let i = houses.indexOf(house)
  if (i > -1) {
    house.deleteHouse();
    houses.splice(i, 1);
  }
}

export function deleteCoreFromCoreList(cores: Core[], core: Core) {
  let i = cores.indexOf(core);
  if (i > -1) {
    core.deleteCore();
    cores.splice(i, 1);
  }
}

export function deleteFieldFromFieldList(fields: Field[], field: Field) {
  let i = fields.indexOf(field);
  if (i > -1) {
    if (field.getLayer()) { switchLayerState(field.getLayer()) }
    fields.splice(i, 1);
  }
}

export function deleteBuildingFromBuildingList(buildings: BuildingTypeData[], building: BuildingTypeData) {
  let i = buildings.indexOf(building);
  if (i > -1) {
    while (building.houses.length > 0) {
      deleteHouseFromHouseList(building.houses, building.houses[0]);
    }

    while (building.cores.length > 0) {
      deleteCoreFromCoreList(building.cores, building.cores[0]);
    }
    buildings.splice(i, 1);
  }
}

export function switchLayerState(l: ConverterLayer | null, isSinglePolygon: boolean = false) {
  if (!l)
    return;

  if (isSinglePolygon) {
    let p = l.polygons[0];
    for (let i = 1; i < l.polygons.length; i++) {
      p = p.area < l.polygons[i].area ? l.polygons[i] : p;
    }
    p.selected = !l.selected;
    App.stage !== "prod" && console.log(p, l);
    switchLineDashedState(p.lineMesh.material, l.selected);
  }
  else {
    l.polygons.forEach(p => {
      p.selected = !l.selected;
      switchLineDashedState(p.lineMesh.material, l.selected);
    });
  }

  l.selected = !l.selected;

  if (!l.selected) {
    l.polygons.forEach(p => {
      p.lineMesh.renderOrder = 0;
      p.innerMesh.visible = false;
    })
  }

  l.polygons.forEach(p => {
    p.lineMesh.material.color = new THREE.Color().set(l.color);
  })
}

export function getFieldsArea(fields: Field[]) {
  let totalArea = 0;

  fields.forEach(f => {
    if (f.getLayer())
      totalArea += f.getArea();
  })

  return Number(totalArea.toFixed(2));
}

export function getHouseArea(house: House) {
  if (house.wall) {
    return getConverterLayerArea(house.wall);
  }
  else
    return 0;
}

export function getFieldArea(field: Field) {
  let layer = field.getLayer();
  if (layer) {
    return getConverterLayerArea(layer);
  }
  else {
    return 0;
  }
}

export function getConverterLayerArea(layer: ConverterLayer) {
  return Number(GetJSTSUnionPolygonFormLayer(layer).getArea().toFixed(2));
}

export function GetJSTSUnionPolygonFormLayer(layer: ConverterLayer) {
  let polygons = GetJSTSPolygonFormLayer(layer);
  let unionPolygon = GetEmptyJSTSGeometry();
  polygons.forEach((p: jsts.geom.Geometry) => {
    unionPolygon = unionPolygon.union(p);
  });
  return unionPolygon;
}

export function getBlockTotalHouseHold(buildings: ConverterBuilding[]) {
  let totalHousehold = 0;
  buildings.forEach(b => {
    b.parts.forEach(p => {
      totalHousehold += p.houseNumber;
    })
  })

  return totalHousehold;
}

export function getTotalHousehold(buildings: BuildingTypeData[]) {
  let totalHousehold = 0;
  buildings.forEach(b => {
    b.cores.forEach(c => {
      c.houses.forEach(h => {
        totalHousehold += (h.level.length - h.piloti);
      })
    })
  });
  return totalHousehold;
}

export function calculateBlockAreaProPortion(buildings: ConverterBuilding[]) {
  let areaProportions: areaProportion[] = [];

  buildings.forEach(b => {
    b.parts.forEach(p => {
      // let ap = areaProportions.find(e => e.housingPlanTypeArea === h.exclusiveArea)
    })
  });

  let totalHousehold = getBlockTotalHouseHold(buildings);
  areaProportions.push({
    housingPlanTypeArea: 84,
    housingPlanTypeNumberMin: totalHousehold,
    housingPlanTypeProportion: 0,
    numberOfBay: 0,
    templateName: '',
  })
  areaProportions.forEach(a => {
    a.housingPlanTypeProportion = Number((a.housingPlanTypeNumberMin / totalHousehold).toFixed(12));
  });

  return areaProportions;
}

export function calculateAreaProPortion(buildings: BuildingTypeData[]) {
  let areaProportions: areaProportion[] = [];

  buildings.forEach(b => {
    b.cores.forEach(c => {
      c.houses.forEach(h => {
        let ap = areaProportions.find(e => e.housingPlanTypeArea === h.exclusiveArea)
        if (!ap) {
          areaProportions.push({
            housingPlanTypeArea: h.exclusiveArea,
            housingPlanTypeNumberMin: (h.level.length - h.piloti),
            housingPlanTypeProportion: 0,
            numberOfBay: 0,
            templateName: '',
          })
        }
        else {
          ap.housingPlanTypeNumberMin += (h.level.length - h.piloti);
        }
      })
    })
  });

  let totalHousehold = getTotalHousehold(buildings);

  areaProportions.forEach(a => {
    a.housingPlanTypeProportion = Number((a.housingPlanTypeNumberMin / totalHousehold).toFixed(12));
  });

  return areaProportions;
}

function fixedNumber(n: number, length: number) {
  return Number(n.toFixed(length));
}


export function buildingStoriesAvg(buildings: BuildingTypeData[]) {
  let totalHouseLine = 0;
  let totalHouseHold = 0;
  let totalLevel = 0;
  let totalBaseArea = 0;
  let totalBuildingArea = 0;

  buildings.forEach(b => {
    let maxLevel = 0;
    let baseArea = 0;
    b.cores.forEach(c => {
      totalHouseLine += c.houses.length;
      c.houses.forEach(h => {
        totalHouseHold += (h.level.length - h.piloti);
        maxLevel = Math.max(maxLevel, h.level.length);
        baseArea += h.exclusiveArea;
        totalBuildingArea += h.exclusiveArea + h.serviceArea;
      })
      baseArea += c.area;
      totalBuildingArea += c.area;
    })
    totalLevel += maxLevel;
    totalBaseArea += baseArea / maxLevel;
  });

  let floorAvg_totalHouse = fixedNumber(totalHouseHold / totalHouseLine, 12);
  let floorAvg_Math = fixedNumber(totalLevel / buildings.length, 12);
  let floorAvg_area = fixedNumber(totalBuildingArea / totalBaseArea, 12);

  let buildingAvg: loc_building_stories_avg = {
    AREA: floorAvg_area,
    HOUSE: floorAvg_totalHouse,
    NUMERICAL: floorAvg_Math,
  }

  return buildingAvg;
}

export function blockBuildingStoriesAvg(buildings: ConverterBuilding[]) {
  let totalHouseLine = 0;
  let totalHouseHold = 0;
  let totalLevel = 0;
  let totalBaseArea = 0;
  let totalBuildingArea = 0;

  buildings.forEach(b => {
    let maxLevel = 0;
    let baseArea = 0;
    b.parts.forEach(p => {
      totalBuildingArea += p.totalExclusiveAreas + p.totalCoreAreas + p.totalServiceAreas;
      baseArea += p.totalCoreAreas;
      maxLevel = Math.max(maxLevel, p.levelCount);
      totalHouseHold += p.houseNumber;
      if (p.buildingType === 'component') {
        if ((p as BuildingComponent).componentType === 'house') {
          totalHouseLine++;
        }
      }
    })

    totalLevel += maxLevel;
    totalBaseArea += baseArea / 10;
  });

  let floorAvg_totalHouse = fixedNumber(totalHouseHold / totalHouseLine, 12);
  let floorAvg_Math = fixedNumber(totalLevel / buildings.length, 12);
  let floorAvg_area = fixedNumber(totalBuildingArea / totalBaseArea, 12);

  let buildingAvg: loc_building_stories_avg = {
    AREA: floorAvg_area,
    HOUSE: floorAvg_totalHouse,
    NUMERICAL: floorAvg_Math,
  }

  return buildingAvg;
}

export function mouseOverLayerTag(layers: ConverterLayer[], layer: ConverterLayer) {
  layers.forEach(l => {
    l.polygons.forEach(p => {
      if (l.name !== layer.name) {
        p.lineMesh.material.uniforms.opacity = { value: 0.2 };
        p.lineMesh.renderOrder = 0;
      }
      else {
        p.lineMesh.material.uniforms.opacity = { value: 0.8 };
        p.lineMesh.renderOrder = 1;
      }
    })
  })
}

export function mouseOutLayerTag(layers: ConverterLayer[]) {
  layers.forEach(l => {
    l.polygons.forEach(p => {
      p.lineMesh.material.uniforms.opacity = { value: 1 };
      //@ts-ignore
      p.innerMesh.material.opacity = 0.5;
      p.lineMesh.renderOrder = l.z_index;
    })
  })
}

export function darkenAllLayer(layers: ConverterLayer[]) {
  layers.forEach(l => {
    l.polygons.forEach(p => {
      p.lineMesh.material.uniforms.opacity = { value: 0.2 };
      //@ts-ignore
      p.innerMesh.material.opacity = 0.1;
    })
  })
}

export function brightenAllLayer(layers: ConverterLayer[]) {
  layers.forEach(l => {
    l.polygons.forEach(p => {
      p.lineMesh.material.uniforms.opacity = { value: 1 };
      //@ts-ignore
      p.innerMesh.material.opacity = 0.5;
    })
  })
}

export function mouseOverHouseTag(layers: ConverterLayer[], house: House) {
  layers.forEach(l => {
    l.polygons.forEach(p => {
      if ((house.wall && l.name === house.wall.name) ||
        (house.lightWindow && l.name === house.lightWindow.name) ||
        (house.normalWindow && l.name === house.normalWindow.name)) {
        p.lineMesh.material.uniforms.opacity = { value: 0.8 };
        //@ts-ignore
        p.innerMesh.material.opacity = 0.5;
      }
      else {
        p.lineMesh.material.uniforms.opacity = { value: 0.2 };
        //@ts-ignore
        p.innerMesh.material.opacity = 0.1;
      }
    })
  })
}

export function allLayerSetToBaseColor(layers: ConverterLayer[]) {
  layers.forEach(l => {
    l.polygons.forEach(p => {
      p.lineMesh.material.color = new THREE.Color().set(l.color);
    })
  })
}

export function changeAllLayerOpacity(layers: ConverterLayer[], opacity: number) {
  layers.forEach(l => {
    l.polygons.forEach(p => {
      p.lineMesh.material.uniforms.opacity = { value: opacity };
    })
  })
}

export function findBuilding(buildings: BuildingTypeData[], name: string): BuildingTypeData | null {
  let building: BuildingTypeData | null = null;
  buildings.forEach(b => {
    building = b.name === name ? b : null;
  })

  return building;
}

export function GetPolygonCentroid(layer: ConverterLayer) {
  let center = new THREE.Vector3(0, 0, 0);

  if (layer.polygons.length <= 0) {
    return center;
  }

  let polygons = GetJSTSPolygonFormLayer(layer);
  polygons.forEach(p => {
    // buffer(-1)에 try catch 필요
    try {
      //@ts-ignore
      let c = p.buffer(-1).getCentroid();
      center.add(new THREE.Vector3(c.getX(), c.getY(), 0));
    } catch (error) {

    }
  })
  return center.divideScalar(polygons.length);
}

export function GetLayerOverlapState(layer: ConverterLayer) {
  let polygons: jsts.geom.Geometry[] = [];
  polygons = polygons.concat(GetJSTSPolygonFormLayer(layer));
  return polygons.length > 1 ? CheckPolygonOverlap(polygons) : false;
}

export function CheckPolygonOverlap(polygons: jsts.geom.Geometry[]) {
  let overlapInter = null;
  for (let i = 0; i < polygons.length - 1; i++) {
    for (let j = i + 1; j < polygons.length; j++) {
      let interPoly = polygons[i].intersection(polygons[j]);
      if (interPoly.getArea() > 0.0001) {
        overlapInter = interPoly;
      }
    }
  }
  return overlapInter
}

export function JSTSGeoToTHREEGeo(jstsGeo: jsts.geom.Geometry) {
  let vertsTri: number[] = [];
  let vertsGeo: THREE.Vector3[] = [];
  jstsGeo.getCoordinates().forEach(coord => {
    vertsTri.push(coord.x, coord.y);
    vertsGeo.push(new THREE.Vector3(coord.x, coord.y, 0));
  });

  let triangles = earcut(vertsTri);
  const geo = new THREE.Geometry();
  if (jstsGeo.getCoordinates().length > 2) {
    geo.vertices = vertsGeo;
    for (let i = 0; i < triangles.length - 2; i += 3) {
      geo.faces.push(new THREE.Face3(triangles[i], triangles[i + 1], triangles[i + 2]));
    }
  }

  return new THREE.Mesh(geo, new THREE.MeshBasicMaterial({ color: new THREE.Color(1, 0, 0) }))
}

export function ChekPolygonsApart(core: jsts.geom.Geometry[], houses: jsts.geom.Geometry[]) {
  let apart = false;
  //@ts-ignore
  let geoNumber = core[0].union(houses[0].buffer(0.02)).getNumGeometries();
  if (geoNumber > 1) apart = true;
  return apart;
}

export function GetJSTSPolygonFormLayer(layer: ConverterLayer) {
  let polygons: jsts.geom.Geometry[] = [];
  layer.polygons.forEach(p => {
    if (p.shape) {
      let coords: jsts.geom.Coordinate[] = [];
      p.vertices.forEach(v => {
        coords.push(new jsts.geom.Coordinate(v.x, v.y));
      })
      let geoFac = new jsts.geom.GeometryFactory();
      let linearRing = geoFac.createLinearRing(coords);
      //@ts-ignore
      polygons.push(geoFac.createPolygon(linearRing, []).buffer(0));
    }
  })
  return polygons;
}

export function GetUnionJSTSPolygonFormLayer(layer: ConverterLayer) {
  let polygons: jsts.geom.Geometry[] = GetJSTSPolygonFormLayer(layer);
  //@ts-ignore
  let polygon = polygons[0].buffer(0.2);
  polygons.forEach(p => {
    //@ts-ignore
    polygon = polygon.union(p.buffer(0.2));
  })

  return polygon;
}

export function CheckVerticesArrayisCCW(verts: THREE.Vector3[]) {
  let coords: jsts.geom.Coordinate[] = [];
  verts.forEach(v => {
    coords.push(new jsts.geom.Coordinate(v.x, v.y));
  })

  return jsts.algorithm.Orientation.isCCW(coords);
}

function GetEmptyJSTSGeometry() {
  let geoFac = new jsts.geom.GeometryFactory();
  let linearRing = geoFac.createLinearRing([]);
  //@ts-ignore
  return geoFac.createPolygon(linearRing, []).buffer(0);
}

export function CheckColsedPolygon(layer: ConverterLayer) {
  let shape = true;
  layer.polygons.forEach(p => {
    if (!p.shape)
      shape = false;
  })
  return shape;
}

export function setErrorColorForLayer(layer: ConverterLayer | null) {
  if (layer) {
    layer.polygons.forEach(p => {
      p.lineMesh.material.color = new THREE.Color(0xff0000);
    })
  }
}

export function getLatLonPosition(point: THREE.Vector3, mapProjData: mapProjectionData, screenInfo: screenInfo) {
  let np = point.clone().applyMatrix4(screenInfo.mvp);
  let nx = (np.x + 1) * 0.5 * screenInfo.rendererSize.x;
  let ny = (1 - (np.y + 1) * 0.5) * screenInfo.rendererSize.y;
  return mapProjData.projection.fromPageXYToCoord(NaverPoint(nx + screenInfo.offset.left, ny + screenInfo.offset.top));
}

export function wkt2LatLngs(wkt: string, benchmark: THREE.Vector3, benchmarkInMap: THREE.Vector2) {
  let reader = new jsts.io.WKTReader();
  let polygon = reader.read(wkt);

  let latlngs: any[] = [];
  let bodyPolygon: any[] = [];
  polygon.getCoordinates().forEach(c => {
    let dir = new THREE.Vector3(c.x, c.y, 0).sub(benchmark);
    let latlng = tm2latlng(benchmarkInMap.clone().add(new THREE.Vector2(dir.x, dir.y)));
    bodyPolygon.push([latlng.x, latlng.y]);
  })
  latlngs.push(bodyPolygon);

  return latlngs;
}

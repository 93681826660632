import React, { Component } from 'react';
import * as THREE from '@teneleven/three';
import '../css/CADConverter/CadConverterTemplate.scss';
import '@teneleven/protocols-ts-web';

import { ConverterLayer, siteStruct, Unit, saveState, ConverterType, ListType, PartOfSelect } from './DataTypes';
import { dataParsing, asyncFileRead, parsingUpdateFile, blockParsing } from './FileParser';
import { saveDataToS3, saveDataToDynamoDB, getAddressByProjectSite, checkFileName, checkSpecialSymbolInName, getRoadLine } from './DBManager';
import { NaverMapManager, NaverPoint, NaverLatLng } from './NaverMapManager';
import { GeotoWKT, jstsPolygontoWKT, latlng2tm, SceneManager, tm2latlng, } from './SceneManager';
import { Field, FieldType } from './Field';
import { Button } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search'
import { deleteFieldFromFieldList, getFieldsArea, brightenAllLayer, darkenAllLayer, wkt2LatLngs, GetUnionJSTSPolygonFormLayer } from './CoreAndHouseController';
import { shapeInfoData } from './ProjectDataStruct';
import { ModalProps, Modal, buttonNum } from './Modal';
import { incrementCounter } from '../Utils';
import App from '../App';
import { getCurveErrorCircle } from './MeshMaker';
import { FieldUI } from './FieldUI';
import { ConverterHeader } from './ConverterHeader';
import { DropFileBox } from './DropFileToLoadBox';
import { Setting, userSettingData } from './SettingModal';
import { ErrorLog, ErrorLogCell, ErrorType, makeErrorInformation, makeWarningInformation } from './ErrorLog';
import { LayerPanel } from './LayerPanel';
import { House } from './House';
import { DXFColor, DXFMaker } from './DXFMaker';
import Axios from 'axios';
import { BlockParsingData } from './BuildingPart';
import { ConverterBlock } from './ConverterBlock';
import { ConverterInputBox } from './ConverterInputBox';
import wkx from 'wkx'

export interface MySiteProps {

}

export interface MySiteState {
  layers: ConverterLayer[];
  handle: number;
  isCad: boolean;
  isCadastral: boolean;
  screenWidth: number;
  screenHeight: number;
  address: string;
  canvasAlpha: number;
  fileName: string;
  saveName: string;
  showLayer: boolean;
  selectLayer: ConverterLayer | null;
  siteArea: number,
  roadArea: number,
  vacancyOusideArea: number,
  vacancyInsideArea: number,
  settingID: string,
  userID: string,
  showModal: boolean,
  loadFile: boolean,
  dragCover: boolean,
  fileUnit: Unit,
  showSettingModal: boolean,
  showAutoSetting: boolean,
  showSample: boolean,
  errorLog: boolean,
  errorSize: number,
  warningSize: number,
}

export class Scene extends Component<MySiteProps, MySiteState> {
  state: MySiteState = {
    layers: [],
    handle: 0,
    isCad: true,
    isCadastral: false,
    screenWidth: window.innerWidth,
    screenHeight: window.innerHeight - 122,
    address: '',
    canvasAlpha: 50,
    fileName: '선택된 파일 없음',
    saveName: '',
    showLayer: true,
    selectLayer: null,
    siteArea: 0,
    roadArea: 0,
    vacancyOusideArea: 0,
    vacancyInsideArea: 0,
    settingID: '',
    userID: 'test@1011.co.kr',
    showModal: false,
    showAutoSetting: false,
    loadFile: true,
    dragCover: true,
    fileUnit: Unit.Millimeters,
    showSettingModal: false,
    errorLog: false,
    errorSize: 0,
    warningSize: 0,
    showSample: true,
  };

  mapManager = new NaverMapManager();
  sceneManager = new SceneManager();

  mount: HTMLDivElement | null = null;
  polygon2DGroup = new THREE.Group();

  bbox = new THREE.Box3();

  mouseOverLayerColor = '#aaaaaa';
  baseLayerColor = '#909090';

  // siteFields: Field[] = [];
  // roadFields: Field[] = [];
  // vacancyOutsideFields: Field[] = [];
  // vacancyInsideFields: Field[] = [];
  // centerLineOfRoadFields: Field[] = []; // 도로중심선 
  // topographyFields: Field[] = []; //성절토 라인

  DBTableName = 'platform-buildit-my-site-v2';
  S3BucketName = 'teneleven-platform-my-site-v2';
  fileData: string = '';
  areaBias = 1;
  makedAreaBias = false;

  parsingOutput: BlockParsingData = { buildings: [], fields: [] }
  block: ConverterBlock[] = [];
  centerOfRoadWKT = '';

  settingData = new userSettingData(App.stage, App.session.email);

  errorLogs: ErrorLogCell[][] = [];
  loadFileErrorLogs: ErrorLogCell[] = [];
  globalErrorLogs: ErrorLogCell[] = [];

  ModalProps: ModalProps = {
    content: ['내용'],
    buttonNum: buttonNum.twoButton,
    open: true,
    positive: () => console.log('test'),
    title: '제목'
  }

  animate = () => {
    requestAnimationFrame(this.animate);
    this.sceneManager.render();
  }

  componentDidMount = async () => {

    this.mount!.appendChild(this.sceneManager.canvasElement);

    this.sceneManager.addObjectToScene(this.polygon2DGroup);

    this.sceneManager.SceneInit();

    this.mapManager.createMap(NaverLatLng(37.3595704, 127.105399), this.refs.map as HTMLElement);
    (this.refs.map as HTMLDivElement).style.visibility = 'hidden';

    this.mapManager.addListener('zoom_changed', this.naverMapChanged);
    this.mapManager.addListener('center_changed', this.naverMapChanged);

    this.animate();
    this.settingData.getDataFromDB();
    this.errorLogs.push(this.globalErrorLogs);
    this.errorLogs.push(this.loadFileErrorLogs);

    const r = await App.search({
      "table": this.DBTableName,
      "query": `stage.keyword:${App.stage} AND email.keyword:${App.session.email} AND deleted:false`
    });

    this.setState({
      userID: App.session.email,
      showSample: r.data.hits.total > 0 ? true : false,
    })
    this.onWindowResize();

    window.addEventListener('resize', this.onWindowResize, false);
    window.addEventListener("keyup", this.onKeyUp, false);
  }

  componentDidUpdate = (previousProps: Readonly<MySiteProps>, previousState: Readonly<MySiteState>) => {
    if (previousState.screenWidth !== this.state.screenWidth || previousState.screenHeight !== this.state.screenHeight) {
      let width = this.state.screenWidth;
      let height = this.state.screenHeight;
      let aspect = width / height;

      let frustumSize = this.sceneManager.orthoCamera.right;
      if (this.state.canvasAlpha !== 100 && !this.state.isCad) {
        frustumSize = this.getFrustumSizeWithMapSize();
      }

      this.sceneManager.CameraFrustumResize(frustumSize, aspect);
      this.sceneManager.renderer.setSize(width, height);
    }

    if (previousState.isCad !== this.state.isCad || previousState.canvasAlpha !== this.state.canvasAlpha) {
      if (this.state.isCad) {
        this.sceneManager.setRendererAlpha(1);
      }
      else {
        this.sceneManager.setRendererAlpha(this.state.canvasAlpha / 100);
      }
    }

    if (this.state.showLayer !== previousState.showLayer) {
      this.onWindowResize();
    }

    if (previousState.errorLog !== this.state.errorLog) {
      this.onWindowResize();
    }
  }

  componentWillUnmount = () => {
    this.mount!.removeChild(this.sceneManager.canvasElement);
  }

  onWindowResize = () => {
    this.setState({
      screenWidth: window.innerWidth - 235 * (!this.state.showLayer ? 1 : 0) - (this.state.loadFile ? 420 : 0),
      screenHeight: window.innerHeight - 122 - (this.state.errorLog ? 222 : 0),
    });
  }

  addNewField = (fields: Field[], type: FieldType) => {
    let newField = new Field(fields.length.toString(), type);
    let uuid = newField.id;

    if (type === FieldType.site) {
      if (fields.length > 0) {
        this.showModal('알림', ['사업영역은 하나만 허용합니다!!!!'], buttonNum.oneButton, () => console.log('enable only one site layer'));
        return;
      }
    }

    fields.push(newField);
    this.errorLogs.push(newField.ErrorLog);
    this.polygon2DGroup.add(newField.ErrorPolygonGroup);

    this.setState({
      settingID: uuid,
      showLayer: false,
    })

    this.recalculateArea();
  }

  dataInitialize = () => {
    this.polygon2DGroup.children = [];

    this.bbox = new THREE.Box3();
    this.mapManager.clearAllPolygon();

    this.setState({
      layers: [],
    })
  }

  loadFilebyFile = async (file: any) => {
    if (!file[0].name.endsWith('.dxf')) {
      return;
    }

    let data = await asyncFileRead(file);
    if (!data)
      return;
    this.fileData = data;
    this.dataInitialize();
    while (this.loadFileErrorLogs.length > 0) {
      this.loadFileErrorLogs.splice(0, 1);
    }

    // let layer = dataParsing(data, this.state.fileUnit);
    // let layer = parsingUpdateFile(data);
    this.block = blockParsing(data, this.parsingOutput, this.settingData.dataUnit);
    console.log(this.parsingOutput);
    // if (layer.length === 0) {
    //   this.showModal('알림', ['no data in file'], buttonNum.oneButton, () => { })
    //   return;
    // }

    this.parsingOutput.fields.forEach(f => {
      this.polygon2DGroup.add(f.renderGroup);
    })


    this.bbox.makeEmpty();
    this.bbox.setFromObject(this.polygon2DGroup);
    let center = new THREE.Vector3(0);
    let size = new THREE.Vector3(0);
    this.bbox.getCenter(center);
    this.bbox.getSize(size);
    let frustumSize = (this.bbox.max.x - this.bbox.min.x) / 2 * 1.1;
    if (this.mount!.scrollHeight < this.mount!.scrollWidth) {
      let height = (this.bbox.max.y - this.bbox.min.y) / 2 * 1.1;
      frustumSize = height / this.mount!.scrollHeight * this.mount!.scrollWidth;
    }
    let aspect = this.state.screenWidth / this.state.screenHeight;
    this.sceneManager.CameraFrustumResize(frustumSize, aspect);

    this.sceneManager.orthoCamera.position.set(center.x, center.y, 1);
    this.sceneManager.orthoControl.target.set(center.x, center.y, 0);
    this.sceneManager.orthoCamera.zoom = 1;

    this.loadFileErrorLogs.forEach(lfel => {
      this.polygon2DGroup.add(lfel.hilightPolygon);
    })


    this.setState({
      // layers: layer,
      fileName: file[0].name,
      saveName: file[0].name.substring(0, file[0].name.length - 4),
      loadFile: true,
      showAutoSetting: true,
      isCad: true,
    }, () => {
      this.onWindowResize();
      this.AutoAdd2Map();
    })
  }

  AutoAdd2Map = async () => {
    if (this.parsingOutput.fields.length > 0) {
      let sitePolygon = this.parsingOutput.fields[0].getUnionJstsPolygon();
      let unionPolygon = this.parsingOutput.fields[0].getUnionJstsPolygon();
      
      let center = new THREE.Vector2(0);
      let RoadLatlngs: any[] = [];
      let SiteLatlngs: any[] = [];
      let centerRoadlatlngs: any[] = [];
      let outsideLatLngs: any[] = [];
      let insideLatLngs: any[] = [];
      this.parsingOutput.fields.forEach(f => {
        let matrix = f.renderGroup.matrixWorld;
        if (f.typeName === FieldType.site) {
          //@ts-ignore
          sitePolygon = f.getUnionJstsPolygon()!.buffer(0);
          let cPoint = sitePolygon.getCentroid();
          center.set(cPoint.getX(), cPoint.getY());
          //@ts-ignore
          unionPolygon = f.getUnionJstsPolygon()!.buffer(0.1);

          SiteLatlngs = SiteLatlngs.concat(f.getLatLngList());
        }
        else if (f.typeName === FieldType.road) {
          RoadLatlngs = RoadLatlngs.concat(f.getLatLngList());
        }
        else if (f.typeName === FieldType.vacancyInside) {
          insideLatLngs = insideLatLngs.concat(f.getLatLngList());
        }
        else if (f.typeName === FieldType.vacancyOutside) {
          outsideLatLngs = outsideLatLngs.concat(f.getLatLngList());
        }
        else if (f.typeName === FieldType.centerLineOfRoad) {
          centerRoadlatlngs = centerRoadlatlngs.concat(f.getLatLngList());
          f.parts.forEach(p => {
            this.centerOfRoadWKT = jstsPolygontoWKT(p.getJSTSPolygon(matrix));
          })
        }
      })

      this.mapManager.setNaverSitePolygon(SiteLatlngs);
      this.mapManager.setNaverRoadPolygon(RoadLatlngs);
      this.mapManager.setNaverVacancyInsidePolygon(insideLatLngs);
      this.mapManager.setNaverVacancyOutsidePolygon(outsideLatLngs);

      let centerInLL = tm2latlng(center);
      this.mapManager.setMapCenter(centerInLL.x, centerInLL.y);

      this.parsingOutput.fields.forEach(f => {
        //@ts-ignore
        unionPolygon = unionPolygon!.union(f.getUnionJstsPolygon()!.buffer(0.1));
      })

      if (centerRoadlatlngs.length === 0) {
        this.centerOfRoadWKT = await getRoadLine([jstsPolygontoWKT(sitePolygon!), jstsPolygontoWKT(unionPolygon!)]);
        centerRoadlatlngs = wkt2LatLngs(this.centerOfRoadWKT, new THREE.Vector3(0), new THREE.Vector2(0));
      }
      this.mapManager.setCenterofRoadPolygon(centerRoadlatlngs);

      this.recalculateArea();
    }
  }

  saveInputBlocks = async () => {
    let fn = this.state.saveName === '' ? 'my_site' : this.state.saveName;

    this.showModal('알림', ['나의 사업영역을 저장 중입니다.'], buttonNum.noButton, () => console.log('saving'));

    let { globalId: globalID, userId: privateID } = await incrementCounter("my_site_id", this.state.userID);

    let S3SavePath = `${this.S3BucketName}/${App.stage}/${globalID}`;
    let imageName = `img_large.png`;
    let shapeInfoName = 'shapeInfo.json';
    let date = new Date().toISOString();

    await new Promise((resolve, reject) => {
      this.state.layers.forEach(l => {
        let visible = false;
        if (l.selected) {
          visible = true;
        }
        l.polygons.forEach(p => {
          p.lineMesh.visible = visible && p.selected;
        })
      })
      resolve(0);
    })
    saveDataToS3(this.fileData, S3SavePath, 'file.dxf', '');

    let projectSiteWKT: string[] = [];
    let roadSiteWKT: string[] = [];
    let vacancyInsideWKT: string[] = [];
    let vacancyOutsideWKT: string[] = [];
    // let centerOfRoadWKT: string[] = [];
    let topographyWKT: { polygon: string[], height: number }[] = [];

    this.parsingOutput.fields.forEach(f => {
      let matrix = f.renderGroup.matrixWorld;
      if (f.typeName === FieldType.site) {
        f.parts.forEach(p => {
          projectSiteWKT.push(jstsPolygontoWKT(p.getJSTSPolygon(matrix)));
        })
      }
      else if (f.typeName === FieldType.road) {
        f.parts.forEach(p => {
          roadSiteWKT.push(jstsPolygontoWKT(p.getJSTSPolygon(matrix)));
        })
      }
      else if (f.typeName === FieldType.vacancyInside) {
        f.parts.forEach(p => {
          vacancyInsideWKT.push(jstsPolygontoWKT(p.getJSTSPolygon(matrix)));
        })
      }
      else if (f.typeName === FieldType.vacancyOutside) {
        f.parts.forEach(p => {
          vacancyOutsideWKT.push(jstsPolygontoWKT(p.getJSTSPolygon(matrix)));
        })
      }
    })

    let captureBbox = new THREE.Box3();
    captureBbox.expandByObject(this.polygon2DGroup);

    let lImgBuf = this.sceneManager.getScreenCapture(256, 256, captureBbox);
    let mImgBuf = this.sceneManager.getScreenCapture(128, 128, captureBbox);
    let sImgBuf = this.sceneManager.getScreenCapture(64, 64, captureBbox);

    this.state.layers.forEach(l => {
      l.polygons.forEach(p => {
        p.lineMesh.visible = true;
      })
    })

    //image 
    saveDataToS3(lImgBuf, S3SavePath, imageName, 'image/png');
    saveDataToS3(mImgBuf, S3SavePath, 'img_middle.png', 'image/png');
    saveDataToS3(sImgBuf, S3SavePath, 'img_small.png', 'image/png');

    let shapeInfo: shapeInfoData = {
      boundaryLine: [],
      projectSite: projectSiteWKT,
      projectSiteArea: this.state.siteArea,
      projectSiteRoad: [],
      road: roadSiteWKT,
      centerOfRoad: [this.centerOfRoadWKT],
      setbackLineApartment: [],
      setbackLineMultiHouse: [],
      setbackLineOfficetel: [],
      setbackLineRowHouse: [],
      skylineCircle: [],
      skylineLine: [],
      vacancyInsie: vacancyInsideWKT,
      vacancyOutsie: vacancyOutsideWKT,
      topographyLines: topographyWKT,
    }
    App.stage !== "prod" && console.log(shapeInfo);
    saveDataToS3(JSON.stringify(shapeInfo), S3SavePath, shapeInfoName, 'application/json');

    let dbItem: siteStruct = {
      stage: App.stage,
      global_id: globalID,
      user_id: privateID,
      name: fn,
      email: this.state.userID,
      address: await getAddressByProjectSite(projectSiteWKT),
      project_site_area: this.state.siteArea,
      project_site: projectSiteWKT,
      road_site_area: this.state.roadArea,
      road_site: roadSiteWKT,
      vacancy_outside_area: this.state.vacancyOusideArea,
      vacancy_outside: vacancyOutsideWKT,
      vacancy_inside_area: this.state.vacancyInsideArea,
      vacancy_inside: vacancyInsideWKT,
      topographyLines: topographyWKT,
      centerOfRoad: [this.centerOfRoadWKT],
      img_path: `s3://${S3SavePath}/${imageName}`,
      shapeInfo: `s3://${S3SavePath}/${shapeInfoName}`,
      created_at: date,
      modified_at: date,
      deleted: false,
    };
    App.stage !== "prod" && console.log(dbItem);
    saveDataToDynamoDB(dbItem, this.DBTableName);

    this.showModal('알림', ['나의 사업영역을 저장했습니다.'], buttonNum.oneButton, () => console.log('save done'));

  }

  loadDXFFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files![0]) {
      this.loadFilebyFile(e.target.files);
    }
  }

  recalculateArea = () => {
    let error = 0, waring = 0;
    this.errorLogs.forEach(els => {
      els.forEach(el => {
        if (el.Type === ErrorType.Error) error++;
        if (el.Type === ErrorType.Warning) waring++;
      })
    })

    let siteArea = 0;
    let roadArea = 0;
    let vacancyInsideArea = 0;
    let vacancyOusideArea = 0;

    this.parsingOutput.fields.forEach(f => {
      switch (f.typeName) {
        case FieldType.site:
          siteArea += f.getArea();
          break;
        case FieldType.road:
          roadArea += f.getArea();
          break;
        case FieldType.vacancyInside:
          vacancyInsideArea += f.getArea();
          break;
        case FieldType.vacancyOutside:
          vacancyOusideArea += f.getArea();
          break;
        default:
          break;
      }
    })

    this.setState({
      siteArea: siteArea,
      roadArea: roadArea,
      vacancyInsideArea: vacancyInsideArea,
      vacancyOusideArea: vacancyOusideArea,
      errorSize: error,
      warningSize: waring,
      errorLog: error + waring > 0 ? true : false,
    })
  }

  showLayerList = (id: string) => {
    this.setState({
      showLayer: false,
      settingID: id,
    })
  }

  textInputKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    switch (e.key) {
      case 'Enter':
        this.mapManager.searchAddressToCoordinate(this.state.address);
        e.currentTarget.blur();
        break;

      default:
        break;
    }
  }

  onKeyUp = (event: KeyboardEvent) => {
    switch (event.key) {
      case 'a':
        // this.mapManager.changeMapType();
        // this.makeDXFFile();
        break;
      case 'b':
        // this.saveInputBlocks(this.state.fileName);
        break;
      default:
        break;
    }
  }

  makeDXFFile = async () => {
    let polygons = this.mapManager.getPolygonInDrawingManager();

    for (let polygon of Object.values(polygons)) {
      let bounds = (polygon as any).bounds;
      let leftTopTM = latlng2tm(new THREE.Vector2(bounds._min.x, bounds._min.y));
      let boxWidth = leftTopTM.distanceTo(latlng2tm(new THREE.Vector2(bounds._max.x, bounds._min.y)));
      let boxHeight = leftTopTM.distanceTo(latlng2tm(new THREE.Vector2(bounds._min.x, bounds._max.y)));
      // let latlng2tm
      let coords: any[] = [];
      coords.push([leftTopTM.x, leftTopTM.y]);
      coords.push([leftTopTM.x, leftTopTM.y + boxHeight]);
      coords.push([leftTopTM.x + boxWidth, leftTopTM.y + boxHeight]);
      coords.push([leftTopTM.x + boxWidth, leftTopTM.y]);
      coords.push([leftTopTM.x, leftTopTM.y]);
      let geoJson = {
        type: 'Polygon',
        coordinates: [coords],
      }
      let wkt = wkx.Geometry.parseGeoJSON(geoJson).toWkt();
      console.log(wkt);
      const r = await App.API_Axios.post('/GetFieldAndBuildingsAtWKT', {
        'rectWKT': wkt,
      }, {
        headers: {
          ...App.headers,
        },
      });
      let dxfMaker = new DXFMaker(wkt);
      let data = await Axios.get(r.data.url);

      console.log(data);


      data.data.fieldItems.forEach((field: any) => {
        // field.지목
        if (field.토지이용상황 !== '도로등')
          dxfMaker.AddPolyline(field.geom, field.지번);
        else
          dxfMaker.AddRoadPolyline(field.geom);
      })

      // data.data.cadPoly.forEach((field: any) => {
      //   dxfMaker.AddRoadPolyline(field.poly);
      // })

      data.data.buildingItems.forEach((building: any) => {
        dxfMaker.AddBuildingPolyline(building.geom);
      });

      dxfMaker.DonwloadDXF();
    }
  }

  objectRotate(degree: number) {
    this.polygon2DGroup.rotateZ(THREE.MathUtils.degToRad(degree));
  }

  getFrustumSizeWithMapSize = () => {
    let mapProj = this.mapManager.getMapProjectionData();
    let rect = this.mount!.getBoundingClientRect();
    let p1 = mapProj.projection.fromPageXYToCoord(NaverPoint(0, rect.top));
    let p2 = mapProj.projection.fromPageXYToCoord(NaverPoint(this.state.screenWidth, rect.top));
    return mapProj.projection.getDistance(p1, p2) / 2;
  }

  resizeCanvasResolution = () => {
    let aspect = this.state.screenWidth / this.state.screenHeight;
    this.sceneManager.orthoCamera.zoom = 1;
    let width = this.getFrustumSizeWithMapSize();
    width = this.areaBias !== 1 ? width / this.areaBias : width;
    this.sceneManager.CameraFrustumResize(width / this.areaBias, aspect);
  }

  naverMapChanged = () => {
    this.areaBias = 1;
    this.makedAreaBias = false;
    this.resizeCanvasResolution();
  }

  canvasModeChanged = (_isCAD: boolean) => {
    if (_isCAD) {
      this.sceneManager.getControl().enableKeys = true;
      this.sceneManager.canvasElement.style.pointerEvents = '';
      (this.refs.map as HTMLDivElement).style.visibility = 'hidden';
    }
    else {
      this.sceneManager.getControl().enableKeys = false;
      this.sceneManager.canvasElement.style.pointerEvents = 'none';
      (this.refs.map as HTMLDivElement).style.visibility = 'visible';
      this.mapManager.createDrawingManager();
      this.resizeCanvasResolution();
    }

    this.setState({
      isCad: _isCAD,
    });
  }

  mapTypeChanged = (isCadastral: boolean) => {
    this.mapManager.changeMapType(isCadastral);

    this.setState({
      isCadastral: isCadastral
    })
  }

  setSelectLayer = (click: ConverterLayer) => {
    if (click.selected) {
      this.showModal('알림', ['이미 선택되어 있는 레이어입니다.'], buttonNum.oneButton, () => console.log('selected layer'));
      return;
    }

    if (click.errorLayer) {
      this.showModal('알림', ['문제 있는 레이어를 선택했습니다.'], buttonNum.oneButton, () => console.log('error layer'));
      return;
    }

    if (!this.state.selectLayer || click !== this.state.selectLayer) {
      this.setState({ selectLayer: click }, this.recalculateArea);
    }
    else {
      this.setState({ selectLayer: null }, () => this.setState({ selectLayer: click }, this.recalculateArea));
    }
  }

  showModal = (title: string, content: string[], buttonNum: buttonNum, func: () => void) => {
    this.ModalProps.title = title;
    this.ModalProps.content = content;
    this.ModalProps.buttonNum = buttonNum;
    this.ModalProps.positive = func;

    this.setState({
      showModal: !this.state.showModal,
    })
  }

  getCurrentSelected = () => {
    return null;
  }

  render = () => {
    return (
      <React.Fragment>
        <Modal content={this.ModalProps.content} title={this.ModalProps.title} open={this.state.showModal} buttonNum={this.ModalProps.buttonNum} positive={this.ModalProps.positive}></Modal>
        <Setting
          closeModal={() => this.setState({ showSettingModal: false })}
          open={this.state.showSettingModal}
          settingData={this.settingData}
        />
        <ConverterHeader
          type={ConverterType.mySite}
          email={App.session.email}
          loadFile={this.loadDXFFile}
          reset={() => { }}
          errorSize={this.state.errorSize}
          warningSize={this.state.warningSize}
          openSettingModal={() => this.setState({ showSettingModal: true })}
          saveFile={() => this.showModal('나의 사업영역 저장', ['나의 사업영역을 저장하시겠습니까?'], buttonNum.twoButton, this.saveInputBlocks)}
          showErrorLog={() => this.setState({ errorLog: !this.state.errorLog })}
          showModal={this.showModal}
        />
        <div className='MainBody'>
          <div className='information'>
            <div className='info'><div className='infoLabel'>사업영역 면적</div><div className='inforValue'>{this.state.siteArea.toFixed(2)}㎡</div></div>
            <div className='info'><div className='infoLabel'>인접도로 면적</div><div className='inforValue'>{this.state.roadArea.toFixed(2)}㎡</div></div>
            <div className='info'><div className='infoLabel'>공지영역 면적</div><div className='inforValue'>{this.state.vacancyOusideArea.toFixed(2)}㎡</div></div>
            <div className='info'><div className='infoLabel'>특수영역 면적</div><div className='inforValue'>{this.state.vacancyInsideArea.toFixed(2)}㎡</div></div>
            <div>
            </div>
            <div className='fileInfo'>
              <span className={`dxfIcon ${this.state.loadFile && 'loaded'}`}><span className={`text ${this.state.loadFile && 'loaded'}`}>dxf</span></span>
              <span className={`fileName ${this.state.loadFile && 'loaded'}`}>{this.state.loadFile && this.state.fileName || '선택된 파일이 없습니다.'}</span>
              <span className={`layerToggle ${this.state.loadFile && 'loaded'}`} onClick={() => this.state.loadFile && this.setState({ showLayer: !this.state.showLayer })}>레이어 보기</span>
            </div>
          </div>

          <div className='RenderView'>
            <div className='Scene' >
              <div className='Canvas' ref={(mount) => { this.mount = mount }}>
                <DropFileBox
                  functionForButton={this.loadDXFFile}
                  functionForDrop={this.loadFilebyFile}
                  loadFile={this.state.loadFile}
                  type={ConverterType.mySite}
                  showSample={this.state.showSample}
                />
                <div ref="map" style={{ width: `100%`, height: `100%`, position: "absolute" }} />
                <div className={`toolBar ${!this.state.loadFile && 'hidden'}`}>
                  <div className='searchDiv' hidden={this.state.isCad}>
                    <input
                      type='text'
                      className='addressSearch'
                      placeholder='주소 검색'
                      value={this.state.address}
                      onChange={e => this.setState({ address: e.target.value })}
                      onKeyUp={e => this.textInputKeyUp(e)}
                    />
                    <SearchIcon className='icon' onClick={() => this.mapManager.searchAddressToCoordinate(this.state.address)} />
                  </div>
                  <div className='rightButtons'>
                    <div className='switchTabs'>
                      <Button className={`switchButton ${this.state.isCad && 'enable'}`} onClick={e => this.canvasModeChanged(true)}>CAD</Button>
                      <Button className={`switchButton ${!this.state.isCad && 'enable'}`} onClick={e => this.canvasModeChanged(false)}>MAP</Button>
                    </div>
                    <div className={`switchTabs ${this.state.isCad && 'hidden'}`}>
                      <Button className={`switchButton ${!this.state.isCadastral && 'enable'}`} onClick={e => this.mapTypeChanged(false)}>지도</Button>
                      <Button className={`switchButton ${this.state.isCadastral && 'enable'}`} onClick={e => this.mapTypeChanged(true)}>지적도</Button>
                    </div>
                    <input
                      className={`slider ${this.state.isCad && 'hidden'}`}
                      type='range'
                      min='0'
                      max='100'
                      value={this.state.canvasAlpha}
                      onChange={(e) => this.setState({ canvasAlpha: Number(e.target.value) })}
                    />
                  </div>
                </div>
              </div>
              <ErrorLog
                show={this.state.errorLog}
                closeLog={() => this.setState({ errorLog: !this.state.errorLog })}
                logs={this.errorLogs}
                warningSize={this.state.warningSize}
                errorSize={this.state.errorSize}
                brightenAllLayer={() => brightenAllLayer(this.state.layers)}
                darkenAllLayer={() => darkenAllLayer(this.state.layers)}
              />
            </div>
            <div className={`mainLayout ${!this.state.loadFile && 'hidden'}`}>
              <div style={{ width: '235px', }}>
                {this.parsingOutput.fields.map(f => {
                  return <div key={f.uuid} style={{ cursor: 'pointer', color: 'white' }}>
                    {f.name}
                  </div>
                })}
              </div>
              <div className='functionalLayout' >
                <div className='fileNameLayout lbrBorder'>
                  <div className='titleText'>나의 사업영역 제목</div>
                  <input type='text' value={this.state.saveName} onChange={e => this.setState({ saveName: e.target.value })} placeholder='나의 사업영역 제목 입력'></input>
                </div>
                <div className='bodyLayout lbrBorder'>
                  {this.parsingOutput.fields.map(f => {
                    return <div className='field' key={f.uuid}>
                      <div className='nameDiv'>{f.name}</div>
                      <div className={`inputValues ${f.typeName !== FieldType.site && 'hidden'}`} style={{ margin: '5px 0 0 0' }}>
                        <ConverterInputBox name='면적' value={f.getArea()} valueOnChange={f.setArea} valueUpdate={this.recalculateArea} unit='㎡' step={0.01} />
                      </div>
                      <div className='Line' />
                    </div>
                  })}
                </div>
                <div className='saveLayout lbrBorder TwoButtonHeight'>
                  <Button className='navyButton' onClick={() => this.makeDXFFile()}>DXF파일 다운로드</Button>
                  <Button className='sideMargin' onClick={() => this.showModal('나의 사업영역 저장', ['나의 사업영역을 저장하시겠습니까?'], buttonNum.twoButton, this.saveInputBlocks)}>나의 사업영역 저장</Button>
                  <div className='extraText'><span>서비스 이용약관</span><span className='end'>Copyright © 2019 BUILDIT</span></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

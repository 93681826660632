import React, { Component } from 'react';
import { ComponentUI } from './BuildingComponentUI';
import { BuildingComponent, BuildingPart } from './BuildingPart';

interface partState {

}

interface partProp {
  compoent: BuildingPart;
  updateArea: () => void;
}

export class BuildingPartUI extends Component<partProp, partState> {
  render = () => {
    return (
      this.props.compoent && <div key={this.props.compoent.uuid} className='core' style={{ paddingLeft: '10px' }}>
        {this.props.compoent.name}
        {this.props.compoent.buildingType === 'component' && <ComponentUI updateArea={this.props.updateArea} compoent={this.props.compoent as BuildingComponent} /> ||
          this.props.compoent.parts.map(p => {
            return <BuildingPartUI key={p.uuid} updateArea={this.props.updateArea} compoent={p} />
          })}
      </div>
    )
  }
}


import {
  ConverterLayer,
  makeHouseState,
  CompletenessType,
  PolylineInfo,
  LineType,
} from "./DataTypes";
import {
  CheckColsedPolygon,
  CheckPolygonOverlap,
  getConverterLayerArea,
  GetJSTSPolygonFormLayer,
  GetPolygonCentroid,
  JSTSGeoToTHREEGeo,
  switchLayerState,
} from "./CoreAndHouseController";
import * as THREE from "@teneleven/three";
import { getWindowErrorCircle, remakeHousePolygons } from "./MeshMaker";
import {
  ErrorLogCell,
  ErrorType,
  makeErrorInformation,
  makeWarningInformation,
} from "./ErrorLog";
import userSettingData from "./SettingModal";

const uuid4 = require("uuid/v4");

export class House {
  name: string;
  id: string;
  level: boolean[];
  levelHeights: number[];
  outputPolygon: PolylineInfo[];
  finalLines: any[];
  centerOfAllLine: THREE.Vector3;
  complete: CompletenessType;
  wall: ConverterLayer | null;
  lightWindow: ConverterLayer | null;
  normalWindow: ConverterLayer | null;
  exclusiveArea: number; // 전용 면적
  serviceArea: number; // 발코니 면적
  balconyOver150cm: number;
  balconyLess150cm: number;
  commonWallArea: number;
  piloti: number;
  selected: boolean;
  makeState: makeHouseState;
  ErrorLog: ErrorLogCell[];
  ErrorPolygonGroup: THREE.Group;

  constructor(name: string) {
    this.id = uuid4();
    this.name = name;
    this.wall = null;
    this.normalWindow = null;
    this.lightWindow = null;
    this.exclusiveArea = 0;
    this.serviceArea = 0;
    this.balconyLess150cm = 0;
    this.balconyOver150cm = 0;
    this.selected = false;
    this.makeState = makeHouseState.Finish;
    this.centerOfAllLine = new THREE.Vector3(0);
    this.complete = CompletenessType.warning;
    this.outputPolygon = [];
    this.level = [true];
    this.piloti = 0;
    this.levelHeights = [2.8];
    this.finalLines = [];
    this.commonWallArea = 0;
    this.ErrorLog = [];
    this.ErrorPolygonGroup = new THREE.Group();
  }

  getHouseArea = () => {
    if (this.wall) {
      return getConverterLayerArea(this.wall);
    } else return 0;
  };

  getSettedLayer = () => {
    let layerArray = [];
    if (this.wall) layerArray.push(this.wall);
    if (this.lightWindow) layerArray.push(this.lightWindow);
    if (this.normalWindow) layerArray.push(this.normalWindow);
    return layerArray;
  };

  checkCompleteness = () => {
    while (this.ErrorLog.length > 0) {
      this.ErrorLog.splice(0, 1);
    }
    while (this.ErrorPolygonGroup.children.length > 0) {
      this.ErrorPolygonGroup.children.splice(0, 1);
    }

    if (!this.wall) {
      this.ErrorLog.push(
        makeErrorInformation(
          `${this.name}세대의 벽 레이어를 추가해 주세요.`,
          "",
          new THREE.Group(),
          this.getSettedLayer()
        )
      );
    } else {
      let overlap = CheckPolygonOverlap(GetJSTSPolygonFormLayer(this.wall));
      if (overlap) {
        let areaOffset = userSettingData.myTypeSettingData.layerOverlap.enable
          ? 0
          : userSettingData.myTypeSettingData.layerOverlap.value / 100;
        if (overlap.getArea() / this.getHouseArea() > areaOffset) {
          let group = new THREE.Group();
          let mesh = JSTSGeoToTHREEGeo(overlap);
          mesh.visible = false;
          group.add(mesh);
          this.ErrorPolygonGroup.add(group);
          this.ErrorLog.push(
            makeWarningInformation(
              `${this.name}세대의 ${this.wall.name}에 폴리곤이 중복되어 있습니다.`,
              "",
              group
            )
          );
        }
      }

      // 면적 오차 오류 체크
      let area = this.getHouseArea();
      if (!userSettingData.myTypeSettingData.areaOffset.enable) {
        let settingValue =
          userSettingData.myTypeSettingData.areaOffset.value / 100;
        let inputArea = this.serviceArea + this.exclusiveArea;
        // let maxAlpha = settingValue * 30; // legacy 코드
        // let minAlpha = 1 / maxAlpha; // legacy 코드
        /*
          Error영역 설정 변경 21.07.07.
          area보다 inputArea가 2배 이상 크거나, 10% 이하 크기인 경우 Error로 판단.
        */
        let maxAlpha = 2; // area의 2배
        let minAlpha = 0.1; // area의 10%크기

        if (inputArea >= area * maxAlpha || inputArea <= area * minAlpha) {
          this.ErrorLog.push(
            makeErrorInformation(
              `${this.name}의 입력면적과 실제 폴리곤 면적이 서로 상이합니다. 단위를 확인 하신 후 다시 진행해 주세요.`,
              `입력 면적: ${(this.serviceArea + this.exclusiveArea).toFixed(
                2
              )}㎡, 계산 면젹: ${area.toFixed(2)}㎡, 차이: ${Math.abs(
                area - this.serviceArea - this.exclusiveArea
              ).toFixed(2)}㎡`,
              new THREE.Group(),
              this.getSettedLayer()
            )
          );
        }
        if (
          inputArea <= area * (1 - settingValue) ||
          inputArea >= area * (1 + settingValue)
        ) {
          this.ErrorLog.push(
            makeWarningInformation(
              `${this.name}의 입력면적과 실제 폴리곤 면적이 서로 상이합니다.`,
              `입력 면적: ${(this.serviceArea + this.exclusiveArea).toFixed(
                2
              )}㎡, 계산 면젹: ${area.toFixed(2)}㎡, 차이: ${Math.abs(
                area - this.serviceArea - this.exclusiveArea
              ).toFixed(2)}㎡`,
              new THREE.Group(),
              this.getSettedLayer()
            )
          );
        }
      }
    }

    if (!this.lightWindow && !this.normalWindow) {
      this.ErrorLog.push(
        makeErrorInformation(
          `${this.name}세대의 창문 레이어를 추가해 주세요.`,
          "",
          new THREE.Group(),
          this.getSettedLayer()
        )
      );
    }

    if (
      this.makeState === makeHouseState.lightWindowError ||
      this.makeState === makeHouseState.allWindowError
    ) {
      let group = getWindowErrorCircle(this.lightWindow!);
      this.ErrorPolygonGroup.add(group);
      this.ErrorLog.push(
        makeWarningInformation(
          `${this.name}세대에 생성 안 된 채광 창문 라인이 있습니다.`,
          "",
          group,
          [this.lightWindow!]
        )
      );
    }
    if (
      this.makeState === makeHouseState.outerWindowError ||
      this.makeState === makeHouseState.allWindowError
    ) {
      let group = getWindowErrorCircle(this.normalWindow!);
      this.ErrorPolygonGroup.add(group);
      this.ErrorLog.push(
        makeWarningInformation(
          `${this.name}세대에 생성 안 된 일반 창문 라인이 있습니다.`,
          "",
          group,
          [this.normalWindow!]
        )
      );
    }

    let wallLength = 0;
    this.outputPolygon.forEach((op) => {
      if (op.type === LineType.LT_OUTERWALL) {
        wallLength++;
      }
    });
    if (wallLength === 0) {
      this.ErrorLog.push(
        makeErrorInformation(`${this.name}의 생성된 벽 폴리곤이 없습니다.`)
      );
    }

    let error = 0,
      waring = 0;
    this.ErrorLog.forEach((el) => {
      if (el.Type === ErrorType.Error) error++;
      if (el.Type === ErrorType.Warning) waring++;
    });

    if (error > 0) this.complete = CompletenessType.error;
    else if (error === 0 && waring > 0)
      this.complete = CompletenessType.warning;
    else this.complete = CompletenessType.complete;

    if (this.complete !== CompletenessType.error) {
      this.wall!.polygons.forEach((p) => {
        p.innerMesh.visible = true;
        p.lineMesh.material.color = new THREE.Color(0xffffff);
      });
    } else {
      if (this.wall) {
        this.wall.polygons.forEach((p) => {
          p.innerMesh.visible = false;
          //@ts-ignore
          p.lineMesh.material.color = new THREE.Color().set(this.wall.color);
        });
      }
    }
    return this.complete;
  };

  deleteHouse = () => {
    // this.selected = false;
    if (this.wall) {
      // this.wall.polygons.forEach(p => p.lineMesh.visible = false);
      switchLayerState(this.wall);
    }
    if (this.lightWindow) {
      switchLayerState(this.lightWindow);
    }
    if (this.normalWindow) {
      switchLayerState(this.normalWindow);
    }

    while (this.ErrorLog.length > 0) {
      this.ErrorLog.splice(0, 1);
    }
  };

  setLevel = (level: number) => {
    let height = this.levelHeights[0];
    this.level = [];
    this.levelHeights = [];
    for (let i = 0; i < level; i++) {
      this.level.push(true);
      this.levelHeights.push(height);
    }
  };

  setLevelHeight = (value: number) => {
    for (let i = 0; i < this.levelHeights.length; i++) {
      this.levelHeights[i] = value;
    }
  };

  setPiloti = (level: number) => {
    level = level > this.level.length ? this.level.length : level;

    for (let i = 0; i < this.level.length; i++) {
      this.level[i] = i < level ? false : true;
    }

    this.piloti = level;
  };

  setWallLayer = (layer: ConverterLayer | null) => {
    if (!layer) return;
    if (!CheckColsedPolygon(layer)) return;

    let exLayer = this.wall;
    if (layer === this.wall) {
      this.wall = null;
    } else {
      this.wall = layer;
      switchLayerState(layer);
      this.centerOfAllLine = GetPolygonCentroid(layer);
    }

    if (exLayer) switchLayerState(exLayer);
    this.makeHousePolygon();
    this.checkCompleteness();
  };

  setLightWindow = (layer: ConverterLayer | null) => {
    if (!layer) return;

    let exLayer = this.lightWindow;
    if (layer === this.lightWindow) {
      this.lightWindow = null;
    } else {
      this.lightWindow = layer;
      switchLayerState(layer);

      layer!.polygons.forEach((p) => {
        p.lineMesh.renderOrder = 1;
      });
    }

    if (exLayer) switchLayerState(exLayer);
    this.makeHousePolygon();
    this.checkCompleteness();
  };

  setNormalWindow = (layer: ConverterLayer | null) => {
    if (!layer) return;

    let exLayer = this.normalWindow;
    if (layer === this.normalWindow) {
      this.normalWindow = null;
    } else {
      this.normalWindow = layer;
      switchLayerState(layer);

      layer!.polygons.forEach((p) => {
        p.lineMesh.renderOrder = 1;
      });
    }

    if (exLayer) switchLayerState(exLayer);
    this.makeHousePolygon();
    this.checkCompleteness();
  };

  makeHousePolygon = () => {
    let windowOffset = userSettingData.myTypeSettingData.windowOffset;
    let offset = windowOffset.enable ? 0 : windowOffset.value;

    remakeHousePolygons(this, offset / 1000 + 0.00001);
    this.checkCompleteness();
  };

  setExclusiveArea = (value: number) => {
    this.exclusiveArea = value;
    this.checkCompleteness();
  };

  setServiceArea = (value: number) => {
    this.serviceArea = value;
    this.balconyLess150cm = value;
    this.balconyOver150cm = 0;
    this.checkCompleteness();
  };

  setBalconyOver150cm = (value: number) => {
    if (value > this.serviceArea) {
      value = this.serviceArea;
    }

    this.balconyOver150cm = value;
    this.balconyLess150cm = this.serviceArea - this.balconyOver150cm;
    this.checkCompleteness();
  };

  setCommonWallArea = (value: number) => {
    this.commonWallArea = value;
    this.checkCompleteness();
  };
}

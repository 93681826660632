import AWS from 'aws-sdk';
import * as THREE from '@teneleven/three';
import * as turf from '@turf/turf';
import App from '../App';
import _ from 'lodash';
import AWSModule from '../AWSModule';
import * as URI from "uri-js";

import { PolylineInfo, SiteType, DEMData } from './DataTypes';
import { WKTtoGeom, GeotoWKT } from './SceneManager';
import { building, buildingData, areaInfo, areaInfoSet, polyline, lineInfo, floorInfo, floorStatus } from './resultLocationDataStruct';
import { Core } from './Core';
import { BuildingGroup } from './BuildingGroup';
import { BuildingCoreUnit } from './BuildingCoreUnit';
import { BuildingHouseUnit } from './BuildingHouseUnit';
import { BuildingComponent, ConverterBuilding } from './BuildingPart';

export async function getDEM(TMx: number, TMy: number) {
  let s3 = await new AWSModule("S3").connect();

  const cx = (Math.floor(TMx / 2560) - 1) * 2560;
  const cy = (Math.floor(TMy / 2560) - 1) * 2560 + 1120 + 2560;
  // const key = cx + '_' + cy;

  const dems = [[cx, cy]];
  let offset = 1;
  for (let i = -offset; i <= offset; i += 1) {
    for (let j = -offset; j <= offset; j += 1) {
      if (i === 0 && j === 0) {
        continue;
      }
      dems.push([dems[0][0] + 2560 * i, dems[0][1] + 2560 * j])
    }
  }

  let demValues: DEMData[] = [];
  await new Promise(resolve => {
    dems.forEach(async key => {
      const sr = await s3.S3!.getObject({
        Bucket: 'teneleven-pubdata-dem-v2-apn2',
        Key: `${key[0] + '_' + key[1]}`,
      }).promise();
      const vals = _.range(257).map(i => {
        return _.range(257).map(j => (sr.Body as Buffer).readInt16LE((i * 257 + j) * 2))
      });
      demValues.push({ leftBottom: new THREE.Vector2(key[0], key[1]), values: vals });
    });
    resolve(0);
  })
  return demValues;
}

export async function getSaveData() {
  let s3 = await new AWSModule("S3").connect();

  const sr = await s3.S3!.getObject({
    Bucket: 'teneleven-platform-my-building-type-v2',
    Key: `dev/159/27a0edf6-90e4-4845-90f4-4fa0f2145e2a.json`,
  }).promise();

  App.stage !== "prod" && console.log(sr.Body!.toString());
}

export async function getAddressByProjectSite(projectSite: string[] | any) {
  const r = await App.API_Axios.post('/ProjectDefault', {
    project_site: projectSite,
  }, {
    headers: {
      ...App.headers,
    },
  });

  return r.data['주소'];
}

export async function getRoadLine(site: string[]) { //[대지영역, union(대지영역+공지영역+도로영역)]
  const lambda = await new AWSModule("LAMBDA").connect();
  const r = await lambda.Lambda!.invoke({
    FunctionName: `arn:aws:lambda:ap-northeast-2:331053433621:function:engine-roadline-generator-${App.stage}`,
    Payload: JSON.stringify(
      {
        "isRoad": "false",
        "polySet": site,
        "genType": "USERDEF",
      }
    )
  }).promise();
  return JSON.parse(r.Payload as string)['body'];
}

export async function saveDataToS3(data: string | Buffer | Uint8Array | Blob | undefined, bucket: string, fileName: string, contentType: string) {
  let s3 = await new AWSModule("S3").connect();

  const r = await s3.S3!.putObject({
    Bucket: bucket,
    Key: fileName,
    Body: data,
    ContentType: contentType,
  }).promise();

  // console.log('saveToS3', r);
  return r.$response.httpResponse.statusCode === 200;
}

export async function saveDataToDynamoDB(data: any, tableName: string) {
  let ddb = await new AWSModule("DDB").connect();

  const r = await ddb.Ddb!.put({
    TableName: tableName,
    Item: data,
  }).promise();

  // console.log('saveDataToDDB', r.$response.httpResponse.statusCode === 200);
  return r.$response.httpResponse.statusCode === 200;
  
}

export async function updateDynamoDBData(dbTable: string, item: AWS.DynamoDB.DocumentClient.AttributeMap) {
  const lambda = await new AWSModule("LAMBDA").connect();

  await lambda.Lambda!.invoke({
    FunctionName: `arn:aws:lambda:ap-northeast-2:331053433621:function:buildit-public-platform-UpdateDynamoDB-${App.stage}`,
    Payload: JSON.stringify({
      TableName: dbTable,
      Item: item,
    })
  }).promise();
}

export async function getSettingData() {
  let ddb = await new AWSModule("DDB").connect();

  let dbData = await ddb.Ddb!.get({
    TableName: 'platform-buildit-cad-converter-user-setting',
    Key: {
      stage: App.tempStage, //App.stage,
      email: App.session.email,
    },
  }).promise();

  return dbData.Item
}

export async function getMetaData(tableName: string, localId: string) {
  const r = await App.search({
    "table": tableName,
    "query": `stage.keyword:${App.tempStage} AND email.keyword:${App.session.email} AND user_id:${localId} AND deleted:false`
  });

  return r.data.hits.hits[0]._source
}

export function checkDuplicatePointAndAdd(nodes: THREE.Vector2[], node: THREE.Vector2) {
  let exist = false;
  nodes.forEach(n => {
    if (new THREE.Vector2(n.x, n.y).distanceTo(new THREE.Vector2(node.x, node.y)) < 0.0001) {
      exist = true;
    }
  });

  if (!exist) {
    nodes.push(new THREE.Vector2(Number(node.x.toFixed(4)), Number(node.y.toFixed(4))));
  }
}

export function pushAllPoints(nodes: THREE.Vector2[], polygon: PolylineInfo[], basePosition: THREE.Vector3,
  matrix: THREE.Matrix4 = new THREE.Matrix4().identity()) {
  for (let i = 0; i < polygon.length; i++) {
    let startPoint = polygon[i].line.start.clone().applyMatrix4(matrix).sub(basePosition);
    let endPoint = polygon[i].line.end.clone().applyMatrix4(matrix).sub(basePosition);

    checkDuplicatePointAndAdd(nodes, new THREE.Vector2(startPoint.x, startPoint.y))
    checkDuplicatePointAndAdd(nodes, new THREE.Vector2(endPoint.x, endPoint.y))
  }
}

export function findVerticeIndex(nodes: THREE.Vector2[], node: THREE.Vector2) {
  let id = -1;
  for (let i = 0; i < nodes.length; i++) {
    if (nodes[i].distanceTo(node) < 0.0001) { // 0.01
      id = i;
    }
  }
  if (id === -1) {
    App.stage !== "prod" && console.log(nodes, node);
  }
  return id;
}

export function getLineInfo(nodes: THREE.Vector2[], lines: PolylineInfo[], minPos: THREE.Vector3,
  matrix: THREE.Matrix4 = new THREE.Matrix4().identity()) {
  let outputLines: lineInfo[] = [];

  for (let i = 0; i < lines.length; i++) {
    let startPoint = lines[i].line.start.clone().applyMatrix4(matrix).sub(minPos);
    let endPoint = lines[i].line.end.clone().applyMatrix4(matrix).sub(minPos);
    if (startPoint.distanceTo(endPoint) > 0.0001) {
      let startIndex = findVerticeIndex(nodes, new THREE.Vector2(startPoint.x, startPoint.y));
      let endIndex = findVerticeIndex(nodes, new THREE.Vector2(endPoint.x, endPoint.y));

      if (matrix.elements[0] * matrix.elements[5] < 0) {
        let temp = endIndex;
        endIndex = startIndex;
        startIndex = temp;
      }

      outputLines.push({
        start: startIndex,
        end: endIndex,
        lineType: lines[i].type,
        thickness: lines[i].thickness,
      })
    }
  }
  return outputLines;
}

export function getBuildingData(cores: Core[], buildingName: string = 'building', bayNum: number = 0, copy: number = 1,) {
  let coreTotal: number[] = [];
  let houseTotal: number[] = [];
  let areaInfoSet: areaInfoSet[] = [];
  let outline: polyline[] = [];
  let maxLevel: number = 0;
  let floorStatus: floorStatus[] = [];

  let bbox = new THREE.Box3();
  for (let i = 0; i < copy; i++) {
    cores.forEach(c => {
      coreTotal.push(1);
      houseTotal.push(c.houses.length);

      c.outputPolygon.forEach(p => {
        bbox.expandByPoint(p.line.start);
        bbox.expandByPoint(p.line.end);
      });

      c.houses.forEach(h => {
        h.outputPolygon.forEach(p => {
          bbox.expandByPoint(p.line.start);
          bbox.expandByPoint(p.line.end);
        })
      });
    })
  }

  let minPos = bbox.min.clone();
  let width = Math.abs(bbox.max.x - bbox.min.x);
  // let nodes: THREE.Vector2[] = [];
  let group = 0;
  let coreIndex = 0;
  for (let i = 0; i < copy; i++) {
    cores.forEach(c => {
      let coreNodes: THREE.Vector2[] = [];
      let coreLines: lineInfo[] = [];
      let coreLevel = 0;
      let houseIndex = 0;
      let areaInfoArray: areaInfo[] = []
      let coreFloorinfo: floorInfo[] = [];
      let houseFloorinfo: floorInfo[] = [];

      let areaInfo: areaInfo = {
        areaOfReference: -1,
        areaOfArchitecture: -1,
        areaFloor: -1,
        areaOfExclusive: -1,
        areaOfInner: -1,
        areaOfCorepart: c.area,
        areaOfService: -1,
        areaOfSupply: -1,
        areaOfCostEstimation: -1,
        areaOfCommonETC: -1,
        areaOfContract: -1,
        areaOfPayingout: -1,
        areaOfCommonWall: -1,
        bayNum: bayNum
      }

      pushAllPoints(coreNodes, c.outputPolygon, minPos);
      coreLines = getLineInfo(coreNodes, c.outputPolygon, minPos);
      areaInfoArray.push(areaInfo);

      let polyline: polyline = {
        category: "PCT_CORE",
        type: 'PT_OUTLINE',
        group: group,
        categoryIndex: coreIndex,
        componentIndex: coreIndex,
        node: { data: coreNodes },
        areaInfo: areaInfo,
        lineInfo: coreLines,
      }
      outline.push(polyline);

      c.houses.forEach(h => {
        let houseNodes: THREE.Vector2[] = [];
        let houseLines: lineInfo[] = [];

        let areaInfo: areaInfo = {
          areaOfReference: h.exclusiveArea,
          areaOfArchitecture: h.exclusiveArea + h.serviceArea + c.area / c.houses.length + h.commonWallArea,
          areaFloor: h.exclusiveArea + c.area / c.houses.length + h.commonWallArea,
          areaOfExclusive: h.exclusiveArea,
          areaOfInner: h.commonWallArea,
          areaOfCorepart: c.area / c.houses.length,
          areaOfService: h.serviceArea,
          areaOfSupply: h.exclusiveArea + c.area / c.houses.length + h.commonWallArea,
          areaOfCostEstimation: h.exclusiveArea + h.serviceArea + c.area / c.houses.length + h.commonWallArea,
          areaOfCommonETC: 0,
          areaOfCommonWall: h.commonWallArea,
          areaOfContract: 0,
          areaOfPayingout: h.exclusiveArea + c.area / c.houses.length + h.commonWallArea,
          bayNum: bayNum
        }

        pushAllPoints(houseNodes, h.outputPolygon, minPos);
        houseLines = getLineInfo(houseNodes, h.outputPolygon, minPos);

        let floorInfo: floorInfo = { floorCategory: [] };
        for (let i = 0; i < h.level.length; i++) {
          if (!h.level[i]) {
            floorInfo.floorCategory.push('FC_PILOTI');
          }
          // else if (!h.level[i]) {
          //   floorInfo.floorCategory.push('FC_VOID');
          // }
          else {
            floorInfo.floorCategory.push('FC_HOUSE');
          }
        }
        houseFloorinfo.push(floorInfo);
        coreLevel = coreLevel < h.level.length ? h.level.length : coreLevel;
        areaInfoArray.push(areaInfo);
        let polyline: polyline = {
          category: "PCT_HOUSE",
          type: 'PT_OUTLINE',
          group: group,
          categoryIndex: houseIndex,
          componentIndex: houseIndex,
          node: { data: houseNodes },
          areaInfo: areaInfo,
          lineInfo: houseLines,
        }

        outline.push(polyline);
        houseIndex++;
      })

      let floorInfo: floorInfo = { floorCategory: [] };
      for (let i = 0; i < coreLevel; i++) {
        floorInfo.floorCategory.push('FC_CORE');
      }
      coreFloorinfo.push(floorInfo);
      maxLevel = coreLevel < maxLevel ? maxLevel : coreLevel;
      areaInfoSet.push({ areaInfo: areaInfoArray });

      floorStatus.push({
        coreFloorInfo: coreFloorinfo,
        houseFloorInfo: houseFloorinfo,
      })
      group++;
      //복사 타입 생성시 필수 옵션
      // minPos.x -= width;
    })
  }

  // template 
  let building: building = {
    angleRange: { min: -90, max: 90 },
    areaInfoSet: areaInfoSet,
    groupTotal: cores.length * copy,
    coreTotal: coreTotal,
    houseTotal: houseTotal,
    floorThickness: 0,
    isUserData: true,
    name: buildingName,
    outline: outline,
    polylineFull: outline,
  }

  let floorHeights: number[] = [];
  for (let i = 0; i < maxLevel; i++) {
    floorHeights.push(2.8);
  }

  // building
  let buildingData: buildingData = {
    angle: 0,
    building: building,
    position: minPos,
    rotStatus: 'DEFAULT',
    initialCondition: false,
    rotationOrient: new THREE.Vector2(0, 0),
    floorStatus: floorStatus,// [{ coreFloorInfo: coreFloorinfo, houseFloorInfo: houseFloorinfo }],
    floorHeight: floorHeights,
    name: building.name,
    setbackRegulationFromNorthLess9m: 1.5,
    setbackRegulationFromNorthLess9mType: 'METER',
    setbackRegulationFromNorthMore9m: 0.5,
    setbackRegulationFromNorthMore9mType: 'HEIGHT',
    setbackRegulationFromSiteBoundary: 0.5,
    distanceBetweenWindowOpaqueWalls: 0.8,
    distanceBetweenSideOpaqueWalls: 8,
    distanceBetweenSideWalls: 8,
  }
  return buildingData;
}

export function makeBlockBuildingData(building: ConverterBuilding) {
  let coreTotal: number[] = [];
  let houseTotal: number[] = [];
  let areaInfoSet: areaInfoSet[] = [];
  let outline: polyline[] = [];
  let maxLevel: number = 0;
  let floorStatus: floorStatus[] = [];
  let group = 0;

  let bbox = new THREE.Box3();
  console.log(building);
  bbox.expandByObject(building.renderGroup);
  console.log(bbox.min);

  let coreAreaAve = 0;
  let areaInfoArray: areaInfo[] = [];
  let coreFloorinfo: floorInfo[] = [];
  let houseFloorinfo: floorInfo[] = [];
  let coreIndex = 0;
  let houseIndex = 0;
  let groupTotal = 0;

  building.parts.forEach(p => {
    if (p.buildingType === 'group') {
      let coreAreaAve = 0;
      let areaInfoArray: areaInfo[] = [];
      let coreFloorinfo: floorInfo[] = [];
      let houseFloorinfo: floorInfo[] = [];
      let coreIndex = 0;
      let houseIndex = 0;

      (p as BuildingGroup).parts.forEach(components => {
        if ((components as BuildingComponent).componentType === 'core') {
          let c = components as BuildingCoreUnit;
          let coreNodes: THREE.Vector2[] = [];
          let coreLines: lineInfo[] = [];
          console.log(c.GetArea(), 'c.g et area')
          let areaInfo: areaInfo = {
            areaOfReference: -1,
            areaOfArchitecture: -1,
            areaFloor: -1,
            areaOfExclusive: -1,
            areaOfInner: -1,
            areaOfCorepart: c.GetArea(),
            areaOfService: -1,
            areaOfSupply: -1,
            areaOfCostEstimation: -1,
            areaOfCommonETC: -1,
            areaOfContract: -1,
            areaOfPayingout: -1,
            areaOfCommonWall: -1,
            bayNum: 0
          }
          let matrix = c.renderGroup.matrixWorld;
          pushAllPoints(coreNodes, c.outputPolygon, bbox.min, matrix);
          coreLines = getLineInfo(coreNodes, c.outputPolygon, bbox.min, matrix);
          areaInfoArray.push(areaInfo);

          let floorInfo: floorInfo = { floorCategory: [] };
          for (let i = 0; i < c.level.length; i++) {
            floorInfo.floorCategory.push('FC_CORE');
          }
          coreFloorinfo.push(floorInfo);

          let polyline: polyline = {
            category: "PCT_CORE",
            type: 'PT_OUTLINE',
            group: group,
            categoryIndex: coreIndex,
            componentIndex: coreIndex,
            node: { data: coreNodes },
            areaInfo: areaInfo,
            lineInfo: coreLines,
          }
          maxLevel = c.level.length < maxLevel ? maxLevel : c.level.length;

          outline.push(polyline);
          coreIndex++;
          groupTotal++;
        }
        else if ((components as BuildingComponent).componentType === 'house') {
          let h = components as BuildingHouseUnit;
          let houseNodes: THREE.Vector2[] = [];
          let houseLines: lineInfo[] = [];

          let areaInfo: areaInfo = {
            areaOfReference: h.exclusiveArea,
            areaOfArchitecture: h.exclusiveArea + h.serviceArea + coreAreaAve + h.commonWallArea,
            areaFloor: h.exclusiveArea + coreAreaAve + h.commonWallArea,
            areaOfExclusive: h.exclusiveArea,
            areaOfInner: h.commonWallArea,
            areaOfCorepart: coreAreaAve,
            areaOfService: h.serviceArea,
            areaOfSupply: h.exclusiveArea + coreAreaAve + h.commonWallArea,
            areaOfCostEstimation: h.exclusiveArea + h.serviceArea + coreAreaAve + h.commonWallArea,
            areaOfCommonETC: 0,
            areaOfContract: 0,
            areaOfPayingout: h.exclusiveArea + coreAreaAve + h.commonWallArea,
            areaOfCommonWall: h.commonWallArea,
            bayNum: 0
          }

          let matrix = h.renderGroup.matrixWorld;
          pushAllPoints(houseNodes, h.outputPolygon, bbox.min, matrix);
          houseLines = getLineInfo(houseNodes, h.outputPolygon, bbox.min, matrix);

          areaInfoArray.push(areaInfo);

          let floorInfo: floorInfo = { floorCategory: [] };
          for (let i = 0; i < h.level.length; i++) {
            if (!h.level[i]) {
              floorInfo.floorCategory.push('FC_PILOTI');
            }
            else {
              floorInfo.floorCategory.push('FC_HOUSE');
            }
          }
          houseFloorinfo.push(floorInfo);
          let polyline: polyline = {
            category: "PCT_HOUSE",
            type: 'PT_OUTLINE',
            group: group,
            categoryIndex: houseIndex,
            componentIndex: houseIndex,
            node: { data: houseNodes },
            areaInfo: areaInfo,
            lineInfo: houseLines,
          }

          outline.push(polyline);
          houseIndex++;
        }
      })
      areaInfoSet.push({ areaInfo: areaInfoArray });

      floorStatus.push({
        coreFloorInfo: coreFloorinfo,
        houseFloorInfo: houseFloorinfo,
      })

      coreTotal.push(1);
      houseTotal.push((p as BuildingGroup).GetHouseNumber());
      group++;
    }
    else if ((p as BuildingComponent).componentType === 'core') {
      let c = p as BuildingCoreUnit;
      let coreNodes: THREE.Vector2[] = [];
      let coreLines: lineInfo[] = [];

      let areaInfo: areaInfo = {
        areaOfReference: -1,
        areaOfArchitecture: -1,
        areaFloor: -1,
        areaOfExclusive: -1,
        areaOfInner: -1,
        areaOfCorepart: c.GetArea(),
        areaOfService: -1,
        areaOfSupply: -1,
        areaOfCostEstimation: -1,
        areaOfCommonETC: -1,
        areaOfContract: -1,
        areaOfPayingout: -1,
        areaOfCommonWall: -1,
        bayNum: 0
      }

      let matrix = c.renderGroup.matrixWorld;
      pushAllPoints(coreNodes, c.outputPolygon, bbox.min, matrix);
      coreLines = getLineInfo(coreNodes, c.outputPolygon, bbox.min, matrix);
      areaInfoArray.push(areaInfo);

      let floorInfo: floorInfo = { floorCategory: [] };
      for (let i = 0; i < c.level.length; i++) {
        floorInfo.floorCategory.push('FC_CORE');
      }
      coreFloorinfo.push(floorInfo);

      let polyline: polyline = {
        category: "PCT_CORE",
        type: 'PT_OUTLINE',
        group: group,
        categoryIndex: coreIndex,
        componentIndex: coreIndex,
        node: { data: coreNodes },
        areaInfo: areaInfo,
        lineInfo: coreLines,
      }
      maxLevel = c.level.length < maxLevel ? maxLevel : c.level.length;
      coreTotal.push(1);

      outline.push(polyline);
      coreIndex++;
      groupTotal++;
    }
    else if ((p as BuildingComponent).componentType === 'house') {
      let h = p as BuildingHouseUnit;
      let houseNodes: THREE.Vector2[] = [];
      let houseLines: lineInfo[] = [];

      let areaInfo: areaInfo = {
        areaOfReference: h.exclusiveArea,
        areaOfArchitecture: h.exclusiveArea + h.serviceArea + coreAreaAve + h.commonWallArea,
        areaFloor: h.exclusiveArea + coreAreaAve + h.commonWallArea,
        areaOfExclusive: h.exclusiveArea,
        areaOfInner: h.commonWallArea,
        areaOfCorepart: coreAreaAve,
        areaOfService: h.serviceArea,
        areaOfSupply: h.exclusiveArea + coreAreaAve + h.commonWallArea,
        areaOfCostEstimation: h.exclusiveArea + h.serviceArea + coreAreaAve + h.commonWallArea,
        areaOfCommonETC: 0,
        areaOfContract: 0,
        areaOfPayingout: h.exclusiveArea + coreAreaAve + h.commonWallArea,
        areaOfCommonWall: h.commonWallArea,
        bayNum: 0
      }

      let matrix = h.renderGroup.matrixWorld;
      pushAllPoints(houseNodes, h.outputPolygon, bbox.min, matrix);
      houseLines = getLineInfo(houseNodes, h.outputPolygon, bbox.min, matrix);

      areaInfoArray.push(areaInfo);

      let floorInfo: floorInfo = { floorCategory: [] };
      for (let i = 0; i < h.level.length; i++) {
        if (!h.level[i]) {
          floorInfo.floorCategory.push('FC_PILOTI');
        }
        else {
          floorInfo.floorCategory.push('FC_HOUSE');
        }
      }

      houseFloorinfo.push(floorInfo);
      let polyline: polyline = {
        category: "PCT_HOUSE",
        type: 'PT_OUTLINE',
        group: group,
        categoryIndex: houseIndex,
        componentIndex: houseIndex,
        node: { data: houseNodes },
        areaInfo: areaInfo,
        lineInfo: houseLines,
      }

      outline.push(polyline);
      houseIndex++;
    }
  })
  if (coreFloorinfo.length > 0 && houseFloorinfo.length > 0)
    floorStatus.push({
      coreFloorInfo: coreFloorinfo,
      houseFloorInfo: houseFloorinfo,
    })

  if (areaInfoArray.length > 0) {
    areaInfoSet.push({ areaInfo: areaInfoArray });
  }
  if (houseIndex > 0) {
    houseTotal.push(houseIndex);
  }

  let outputBuilding: building = {
    angleRange: { min: -90, max: 90 },
    areaInfoSet: areaInfoSet,
    groupTotal: groupTotal,
    coreTotal: coreTotal,
    houseTotal: houseTotal,
    floorThickness: 0,
    isUserData: true,
    name: 'building',
    outline: outline,
    polylineFull: outline,
  }

  let floorHeights: number[] = [];
  for (let i = 0; i < maxLevel; i++) {
    floorHeights.push(2.8);
  }

  let buildingData: buildingData = {
    angle: 0,
    building: outputBuilding,
    position: bbox.min.clone(),
    rotStatus: 'DEFAULT',
    initialCondition: false,
    rotationOrient: new THREE.Vector2(0, 0),
    floorStatus: floorStatus,// [{ coreFloorInfo: coreFloorinfo, houseFloorInfo: houseFloorinfo }],
    floorHeight: floorHeights,
    name: building.name,
    setbackRegulationFromNorthLess9m: 1.5,
    setbackRegulationFromNorthLess9mType: 'METER',
    setbackRegulationFromNorthMore9m: 0.5,
    setbackRegulationFromNorthMore9mType: 'HEIGHT',
    setbackRegulationFromSiteBoundary: 0.5,
    distanceBetweenWindowOpaqueWalls: 0.8,
    distanceBetweenSideOpaqueWalls: 8,
    distanceBetweenSideWalls: 8,
  }

  return buildingData;
}

export function polygonBufferWithDistance(polygons: any[], siteType: SiteType) {
  let boundaryLines: any[] = [];
  let distance = -4;

  switch (siteType) {
    case SiteType.Apartment:
      distance = -4;
      break;
    case SiteType.Officetel:
      distance = -4;
      break;
    default:
      break;
  }

  polygons.forEach(wkt => {
    const gj = WKTtoGeom(wkt);
    const p = {
      "type": "Feature",
      "geometry": gj,
    } as any;

    const b = turf.buffer(p, distance, { units: "meters" });

    boundaryLines.push(GeotoWKT(b.geometry));
  });
  return boundaryLines;
}

export async function checkFileName(fileName: string, userID: string, tableName: string) {
  let r = await App.search({
    "table": tableName,
    "query": {
      "query_string": {
        "query": `stage.keyword:${App.tempStage} AND user_id.keyword:${userID} AND deleted:false AND name:"${fileName}"`
      }
    }
  });

  return r.data.hits.total > 0 ? false : true;
}

export function checkSpecialSymbolInName(name: string) {
  let hasSpecialSymbol = false;
  let sSymbol = [];

  sSymbol.push('"');
  sSymbol.push('/');
  sSymbol.push('\\');

  sSymbol.forEach(s => {
    if (!hasSpecialSymbol)
      hasSpecialSymbol = name.includes(s);
  });

  return hasSpecialSymbol;
}

import * as THREE from '@teneleven/three';
import { ConverterBlock } from './ConverterBlock';
import { BlockType, ConverterEntity, ConverterUnit, Polygon, PolylineInfo } from './DataTypes';
import { ErrorLogCell } from './ErrorLog';
import { FieldType } from './Field';
import * as jsts from 'jsts';
import * as turf from '@turf/turf';
import { tm2latlng } from './SceneManager';

const uuid4 = require('uuid/v4');

export interface BlockParsingData {
  buildings: ConverterBuilding[];
  fields: ConverterField[];
}

export abstract class BuildingPart {
  readonly uuid: string;
  readonly buildingType: 'group' | 'component';
  name: string;
  readonly block: ConverterBlock;
  position: THREE.Vector3;
  scale: THREE.Vector3;
  rotate: number;
  renderGroup: THREE.Group;
  parts: BuildingPart[];

  totalExclusiveAreas: number;
  totalServiceAreas: number;
  totalCommonWallAreas: number;
  totalCoreAreas: number;
  houseNumber: number;
  coreNumber: number;
  levelCount: number;

  abstract RebuildOutputPolygon(): void;
  abstract ResetPartElement(): void;
  abstract UpdateArea(): void;
  abstract toJson(): {};

  constructor(block: ConverterBlock) {
    this.uuid = uuid4();
    this.block = block;
    this.buildingType = block.type === BlockType.group ? 'group' : 'component';
    this.name = block.name;
    this.position = new THREE.Vector3();
    this.scale = new THREE.Vector3();
    this.rotate = 0;
    this.renderGroup = new THREE.Group();
    this.parts = [];

    this.totalExclusiveAreas = 0;
    this.totalServiceAreas = 0;
    this.totalCommonWallAreas = 0;
    this.totalCoreAreas = 0;

    this.houseNumber = 0;
    this.coreNumber = 0;
    this.levelCount = 0;
  }
}

export abstract class BuildingComponent extends BuildingPart {
  componentType: 'core' | 'house';
  outputPolygon: PolylineInfo[];
  polygon: Polygon[];
  centerOfAllLine: THREE.Vector3;
  ErrorLog: ErrorLogCell[];
  level: boolean[];

  constructor(block: ConverterBlock) {
    super(block);

    this.componentType = 'core';
    this.outputPolygon = [];
    this.polygon = [];
    this.centerOfAllLine = new THREE.Vector3();
    this.ErrorLog = [];
    this.level = [true, true, true, true, true, true, true, true, true, true];
  }

  RebuildOutputPolygon = () => {
    this.parts.forEach(p => {
      p.RebuildOutputPolygon();
    })
  }

  ResetPartElement = () => {

  }
}

export class ConverterBuilding {
  parts: BuildingPart[];
  position: THREE.Vector3;
  scale: THREE.Vector3;
  rotate: number;
  name: string;
  readonly uuid: string;
  renderGroup: THREE.Group;

  constructor() {
    this.parts = [];
    this.position = new THREE.Vector3(0);
    this.scale = new THREE.Vector3(1, 1, 1);
    this.rotate = 0;
    this.name = '';
    this.renderGroup = new THREE.Group();
    this.uuid = uuid4();
  }

  setPosition = (pos: THREE.Vector3) => {
    this.position = pos;
    this.renderGroup.position.set(pos.x, pos.y, pos.z);
  }

  setScale = (scale: THREE.Vector3) => {
    this.scale = scale;
    this.renderGroup.scale.set(scale.x, scale.y, scale.z);
  }

  setUnitScale = (unitScale: number) => {
    unitScale = unitScale;
    let newScale = this.renderGroup.scale.clone().multiplyScalar(unitScale);
    this.setScale(newScale);
    let pos = this.renderGroup.position.clone().multiplyScalar(unitScale);
    this.setPosition(pos);
  }

  setRotation = (rotate: number) => {
    this.rotate = rotate;
    this.renderGroup.rotateZ(turf.degrees2radians(rotate));
  }

  resetPartsElements = () => {
    if (this.parts.length > 0) {
      if (this.parts[0].buildingType === 'component') {
        let coreIndex = this.parts.findIndex(p => (p as BuildingComponent).componentType === 'core');
        let corePart = this.parts[coreIndex];

        this.parts.splice(coreIndex, 1);
        this.parts.unshift(corePart);
        console.log('reset');
      }
      else {
        for (let i = 0; i < this.parts.length; i++) {
          this.parts[i].ResetPartElement();
        }
        console.log('not component');
      }
    }
  }

  toJson() {
    let json = {
      parts: this.parts.map(p => p.toJson()),
      position: this.position,
      name: this.name,
    }

    return json;
  }
}

export class FieldPart {
  // entity: ConverterEntity;
  area: number;
  renderGroup: THREE.Group;
  verts: THREE.Vector3[];
  position: THREE.Vector3;
  constructor(entity: ConverterEntity) {
    this.verts = (entity as ConverterUnit).verts;
    this.position = new THREE.Vector3(0);
    this.renderGroup = new THREE.Group();
    this.renderGroup.add((entity as ConverterUnit).polygon.lineMesh);
    let pol = this.getJSTSPolygon(this.renderGroup.matrixWorld);
    this.area = pol.getArea();
  }

  getJSTSPolygon = (matrix: THREE.Matrix4) => {
    let coords: jsts.geom.Coordinate[] = [];
    this.verts.forEach(v => {
      let newV = v.clone().applyMatrix4(matrix);
      coords.push(new jsts.geom.Coordinate(newV.x, newV.y));
    })
    let geoFac = new jsts.geom.GeometryFactory();
    let linearRing = geoFac.createLineString(coords);
    //@ts-ignore
    return geoFac.createPolygon(linearRing, []).buffer(0);
  }
}

export class ConverterField {
  readonly uuid: string;
  parts: FieldPart[];
  renderGroup: THREE.Group;
  ErrorLog: ErrorLogCell[];
  name: string;
  typeName: FieldType;
  private area: number;
  private height: number;
  ErrorPolygonGroup: THREE.Group;
  private position: THREE.Vector3;
  private scale: THREE.Vector3;
  unitScale: number;

  constructor(block: ConverterBlock, type: FieldType) {
    this.uuid = uuid4();
    this.ErrorLog = [];
    this.ErrorPolygonGroup = new THREE.Group();
    this.renderGroup = new THREE.Group();
    this.name = block.name;
    this.parts = [];
    this.area = 0;
    this.height = 0;
    this.typeName = type;
    this.position = new THREE.Vector3(0);
    this.scale = new THREE.Vector3(1, 1, 1);
    this.unitScale = 1;

    block.entities.forEach(e => {
      let newPart = new FieldPart(e);
      this.area += newPart.area;
      this.renderGroup.add(newPart.renderGroup);
      this.parts.push(newPart)
    })
  }

  setPosition = (position: THREE.Vector3) => {
    this.position = position;
    this.renderGroup.position.set(position.x, position.y, position.z);
  }

  getPosition = () => { return this.position; }

  setScale = (scale: THREE.Vector3) => {
    this.scale = scale;
    this.renderGroup.scale.set(scale.x, scale.y, scale.z);
  }

  getArea = () => { return this.area; }
  setArea = (area: number) => { this.area = Number(area); }

  setUnitScale = (unitScale: number) => {
    this.unitScale = unitScale;
    let newScale = this.renderGroup.scale.multiplyScalar(unitScale);
    this.setScale(newScale);
    let pos = this.renderGroup.position.multiplyScalar(unitScale);
    this.setPosition(pos);
    let area = 0;
    this.parts.forEach(p => area += p.area)
    area *= unitScale;
    area *= unitScale;
    this.area = area;
  }

  getUnionJstsPolygon = () => {
    let matrix = this.renderGroup.matrixWorld;
    if (this.parts.length > 0) {
      let polygon = this.parts[0].getJSTSPolygon(matrix);
      this.parts.forEach(p => {
        polygon = polygon.union(p.getJSTSPolygon(matrix));
      })
      return polygon;
    }
  }

  getLatLngList = () => {
    let matrix = this.renderGroup.matrixWorld;
    let outputPolygons: any[] = [];
    this.parts.forEach(p => {
      let polygon: any[] = [];
      p.verts.forEach(v => {
        let newV = v.clone().applyMatrix4(matrix);
        let latlng = tm2latlng(new THREE.Vector2(newV.x, newV.y));
        polygon.push([latlng.x, latlng.y]);
      })
      outputPolygons.push(polygon);
    })
    return outputPolygons;
  }
}

import React, { Component, Fragment } from 'react';

import SearchIcon from '@material-ui/icons/Search';
import ViewHeadlineIcon from '@material-ui/icons/ViewHeadline';
import ViewStreamIcon from '@material-ui/icons/ViewStream';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import DeleteProjectIcon from '@material-ui/icons/DeleteForever';
import RestoreProjectIcon from '@material-ui/icons/RestoreFromTrash';
import CopyProjectIcon from '@material-ui/icons/FileCopy';
import DiscardProjectIcon from '@material-ui/icons/Delete';
import { Button, Table, TableHead, TableCell, TableBody, TableRow, InputAdornment, IconButton } from '@material-ui/core';
import ProjectIcon from './ProjectIcon';

import { default as _ } from 'lodash';

import AWSModule from './AWSModule';
import { ProjectListState, TableType, SortOptions, TagType, ProjectListType, TableBuildingType, ModalType } from "./ProjectList";
import App from './App';
import { Project } from './model/Project';
import { ModalOptions } from './Modal';
import BuilditInput from './BuilditInput';
import Tabs from './Tabs';
import Pagination from './Pagination';
import Switch from './Switch';
import ProjectTypeTag from './ProjectTypeTag';
import ProjectListTable from './ProjectListTable'
import CloseIcon from '@material-ui/icons/Close';

import './css/ProjectListBody.scss';
import Tooltip from './Tooltip';
import BuilditSelect from './BuilditSelect';
import DiscountTag from './DiscountTag';
import { project } from '@teneleven/protocols-ts-web';
import queryString from 'query-string';


const pageViewList = {
  devList: [
    { label: "10개 보기", value: 10 },
    { label: "20개 보기", value: 20 },
    { label: "30개 보기", value: 30 },
    { label: "40개 보기", value: 40 },
    { label: "50개 보기", value: 50 },
    { label: "100개 보기", value: 100 },
  ],
  list: [
    { label: "10개 보기", value: 10 },
    { label: "20개 보기", value: 20 },
    { label: "30개 보기", value: 30 },
    { label: "40개 보기", value: 40 },
    { label: "50개 보기", value: 50 },
  ],
  card: [
    { label: "8개 보기", value: 8 },
    { label: "12개 보기", value: 12 },
    { label: "16개 보기", value: 16 },
    { label: "20개 보기", value: 20 },
    { label: "24개 보기", value: 24 },
  ]
}

export interface ProjectListBodyProps extends ProjectListState {
  projects?: Array<Project>;
  setPaginationValue: (viewNum: number, curPageNum: number, callback?: Function) => void;
  setProjectFavorite: (project: Project, favorite: boolean) => void;
  setSearchField: (field: "project_name" | "user_project_id") => void;
  setSearchText: (text: string, callback?: Function) => void;
  tableType: TableType,
  setTableType: (type: TableType, callback?: Function) => void,
  setSelectedProject: (user_project_id: number[], allCheck?: boolean) => void,
  sort: SortOptions,
  setSort: (field: keyof SortOptions, value: "asc" | "desc") => void,
  resetSort: () => void
  selectedProject: Array<{ user_project_id: number, checked: boolean, discountable: boolean, modal_checked: boolean }>;
  setModal: (open: boolean, options?: ModalOptions) => void;
  setLoading: (on: boolean, callback?: Function) => void;
  getQueryString: () => void;
  tagType: TagType
  setProjectTypeSelect: (selectType: TagType) => void;
  setProjectListType: (type: ProjectListType, callback?: Function) => void;
  setBuildingType: (type: TableBuildingType) => void;
  setQuery: (ListType: ProjectListType, viewNum: number, curPageNum: number, tagType: TagType,
    sortField: keyof SortOptions, sortValue: "asc" | "desc",
    searchField: "project_name" | "user_project_id", searchText: string) => void;
  setDiscountProject: (user_project_id: number) => void;
  onCreateProject: (callback: Function) => void;
  getModalOption: (type: ModalType) => ModalOptions | undefined;
}
export interface ProjectListBodyState {
}

export default class ProjectListBody extends Component<ProjectListBodyProps, ProjectListBodyState> {
  state: ProjectListBodyState = {
    sort: {
      user_project_id: "desc",
      project_site_area: "desc",
      floor_area_ratio: "desc",
      created_at: "desc",
    }
  }

  componentWillMount = () => {
    this.search = _.debounce(this.search, 300);

    const tableType = localStorage.getItem('tableType');
    if (tableType) this.props.setTableType(tableType as TableType);
    else localStorage.setItem('tableType', 'TABLE');
}

  get pageSelectPart(): JSX.Element {
    let selectPart: JSX.Element = <div></div>

    switch (this.props.tableType) {
      case "TABLE":
        selectPart =
          <BuilditSelect className="view-num" type="Normal"
            list={(App.stage !== "prod") && pageViewList.devList || pageViewList.list}
            value={this.props.viewNum}
            onChange={(r: React.ReactText) => this.props.setPaginationValue(Number(r), 1)}
          />
        break;
      case "TABLE_DETAIL":
        selectPart =
          <BuilditSelect className="view-num" type="Normal"
            list={(App.stage !== "prod") && pageViewList.devList || pageViewList.list}
            value={this.props.viewNum}
            onChange={(r: React.ReactText) => this.props.setPaginationValue(Number(r), 1)}
          />
        break;
      case "CARD":
        selectPart =
          <BuilditSelect className="view-num" type="Normal"
            list={pageViewList.card}
            value={this.props.viewNum}
            onChange={(r: React.ReactText) => this.props.setPaginationValue(Number(r), 1)}
          />
        break;
      default:
        break;
    }

    return selectPart
  }

  getTextWidth = (s: string): number => {
    const font = "14px Roboto";
    const context = document.createElement("canvas").getContext("2d");
    context!.font = font;
    return context!.measureText(s).width;
  }

  getShortText = (s: string, append: string): string => {
    if (this.getTextWidth(s) < 330) {
      return s + append;
    } else {
      return this.getShortText(s.slice(0, s.length - 1), "...");
    }
  }


  render() {
    let detailText: string = "";
    if (this.props.totalNum > 0) {
      detailText = `'${this.getShortText(this.props.searchText, "")}'에 대한 ${this.props.totalNum.toLocaleString()}개의 검색결과`
    } else {
      detailText = `'${this.getShortText(this.props.searchText, "")}'에 대한 검색결과가 없습니다.`
    }
    return (
      <div className="ProjectListBody">
        <div className="wrapper">
          <div className="total-count font font-primary font-noto font-18px">{`전체 프로젝트 ${this.props.totalNum.toLocaleString()}개`}</div>
          <div className="header">
            <div className="search-wrap">
              <BuilditSelect
                className={`search-field`}
                type="Normal"
                list={[
                  { label: "프로젝트명", value: "project_name" },
                  { label: "번호", value: "user_project_id" },
                ]} value={this.props.searchField}
                onChange={(v: React.ReactText) => this.props.setSearchField((v as "user_project_id" | "project_name"))} />
              <BuilditInput className={`search-text`}
                endAdornment={
                  <InputAdornment position="end">
                    {
                      this.props.searchText.length > 0 &&
                      <IconButton className="icon-btn" disableRipple={true} onClick={e => { this.props.setSearchText("", this.search) }}>
                        <CloseIcon className="icon remove-icon" />
                      </IconButton>
                    }
                    <IconButton className="icon-btn" disableRipple={true} onClick={e => { }}>
                      <SearchIcon className="icon" />
                    </IconButton>
                  </InputAdornment>
                }
                placeholder="검색"
                value={this.props.searchText}
                onChange={(v: React.ReactText) => this.props.setSearchText(v as string, this.search)}
              />
              {
                this.props.searchText &&
                <div className={"search-info font bg-navy font-primary font-noto font-14px"}>
                  {
                    detailText
                  }
                </div>
              }
            </div>
            <div className="table-wrap">
              <div className="buttons">
                {
                  this.props.projectListType === "DISCARDED" &&
                  <Fragment>
                    <Tooltip msg="영구삭제" >
                      <Button onClick={this.deleteProject}
                        className="btn bg-navy btn-cancel"
                      >
                        <DeleteProjectIcon className="icon" />
                      </Button>
                    </Tooltip>
                    <Tooltip msg="되돌리기">
                      <Button className="btn bg-navy btn-primary" onClick={this.restoreProject}
                        style={{ marginLeft: "5px" }}
                      >
                        <RestoreProjectIcon className="icon" />
                      </Button>
                    </Tooltip>
                    {
                      App.stage !== "prod" &&
                      <Button onClick={() => this.allDeleteProjects()}
                        className="btn bg-navy btn-cancel all-delete-btn"
                      >
                        전체 영구 삭제
                    </Button>
                    }
                  </Fragment>
                  ||
                  <Fragment>
                    <Tooltip msg="삭제" >
                      <Button onClick={this.discardProject}
                        className="btn bg-navy btn-cancel">
                        <DiscardProjectIcon className="icon" />
                      </Button>
                    </Tooltip>
                    <Tooltip msg="복사" >
                      <Button className="btn bg-navy btn-primary"
                        style={{ marginLeft: "5px" }} onClick={this.copyProject}>
                        <CopyProjectIcon className="icon" />
                      </Button>
                    </Tooltip>
                  </Fragment>
                }
              </div>

              <div className="project-tabs">
                <Tabs className="bg-navy tabs">
                  <Tooltip msg={"목록"}>
                    <Button
                      className={`bg-navy tab tab-primary ${this.props.tableType === "TABLE" && "active" || ""}`}
                      disableRipple={true}
                      onClick={e => {
                        this.props.setTableType("TABLE")

                      }}>
                      <ViewHeadlineIcon />
                    </Button>
                  </Tooltip>
                  <Tooltip msg={"상세 목록"}>
                    <Button
                      className={`bg-navy tab tab-primary ${this.props.tableType === "TABLE_DETAIL" && "active" || ""}`}
                      disableRipple={true}
                      onClick={e => {
                        this.props.setTableType("TABLE_DETAIL")
                      }}>
                      <ViewStreamIcon />
                    </Button>
                  </Tooltip>
                  <Tooltip msg={"카드 목록"}>
                    <Button
                      className={`bg-navy tab tab-primary ${this.props.tableType === "CARD" && "active" || ""}`}
                      disableRipple={true}
                      onClick={e => {
                        this.props.setTableType("CARD")
                      }}>
                      <ViewModuleIcon />
                    </Button>
                  </Tooltip>
                </Tabs>
              </div>
              <div className="view-wrap">
                {this.pageSelectPart}
              </div>
            </div>

          </div>
          <div className="body">
            <ProjectListTable {...this.props}
              sort={this.props.sort}
              setSortOptions={this.props.setSort}
            />
            <div className="buttons">
              {
                this.props.projectListType === "DISCARDED" &&
                <Fragment>
                  <Tooltip msg="영구삭제" >
                    <Button onClick={this.deleteProject}
                      className="btn bg-navy btn-cancel"
                    >
                      <DeleteProjectIcon className="icon" />
                    </Button>
                  </Tooltip>
                  <Tooltip msg="되돌리기">
                    <Button className="btn bg-navy btn-primary" onClick={this.restoreProject}
                      style={{ marginLeft: "5px" }}
                    >
                      <RestoreProjectIcon className="icon" />
                    </Button>
                  </Tooltip>
                  {
                    App.stage !== "prod" &&
                    <Button onClick={() => this.allDeleteProjects()}
                      className="btn bg-navy btn-cancel all-delete-btn"
                    >
                      전체 영구 삭제
                    </Button>
                  }
                </Fragment>
                ||
                <Fragment>
                  <Tooltip msg="삭제" >
                    <Button onClick={this.discardProject}
                      className="btn bg-navy btn-cancel">
                      <DiscardProjectIcon className="icon" />
                    </Button>
                  </Tooltip>
                  <Tooltip msg="복사" >
                    <Button className="btn bg-navy btn-primary"
                      style={{ marginLeft: "5px" }} onClick={this.copyProject}>
                      <CopyProjectIcon className="icon" />
                    </Button>
                  </Tooltip>
                </Fragment>
              }
            </div>
            
            <div className="project-pagination">
              <Pagination
                totalNum={this.props.totalNum}
                viewNum={this.props.viewNum}
                curPage={this.props.curPageNum}
                onChange={(n: number) => this.props.setPaginationValue(this.props.viewNum, n)} />
            </div>
          </div>
        </div>
      </div>
    )
  }

  search = () => {
    // const parsed = queryString.parse(location.search);
    // const content = this.props.searchField === "project_name" ? `${this.props.searchText}` : Number(this.props.searchText);
    // let q: ProjectListQuery = parsed.q && JSON.parse(parsed.q as string) || undefined;
    // if (q === undefined) {
    //   q = {
    //     search: {
    //       field: this.props.searchField,
    //       content: content
    //     }
    //   }
    // } else {
    //   if (this.props.searchText.length === 0) {
    //     delete q.search;
    //   } else {
    //     q.search = {
    //       field: this.props.searchField,
    //       content: content
    //     }
    //   }
    // }
    // parsed.q = encodeURIComponent(JSON.stringify(q));
    // const options = [];
    // parsed.type && options.push(`type=${parsed.type}`) || options.push(`type=ALL`);
    // parsed.page && options.push(`page=${Number(parsed.page)}`);
    // parsed.view && options.push(`view=${Number(parsed.view)}`);
    // parsed.sort && options.push(`sort=${parsed.sort}`)
    // parsed.q && options.push(`q=${parsed.q}`);
    // // @ts-ignore
    // this.props.history.push(`/project/listtest?${options.join("&")}`);
    this.props.setQuery(this.props.projectListType, this.props.viewNum, 1, this.props.tagType,
      "user_project_id", "desc", this.props.searchField, this.props.searchText)
  }

  discardProject = () => {
    if (this.props.selectedProject.map(r => r.checked && 1 || 0).reduce((a: any, b: any) => a + b) === 0) {
      this.props.setModal(true, {
        title: "삭제 안내",
        color: "DARK",
        type: "SIMPLE",
        positive: () => {
          this.props.setModal(false);
        },
        negative: "hidden",
        content: <div>선택된 프로젝트가 없습니다.</div>
      })
      return;
    }

    if (this.props.selectedProject.map(r => {
      for (let i of this.props.projects!) {
        if (i.user_project_id === r.user_project_id) {
          if (App.stage !== "dev") {
            if (i.status === "RUNNING" || i.status === "WAITING") {
              return 0;
            }
          }
        }
      }
      return 1;
    }).reduce((a: any, b: any) => a + b) === 0) {
      this.props.setModal(true, {
        title: "삭제 안내",
        color: "DARK",
        type: "SIMPLE",
        positive: () => {
          this.props.setModal(false);
        },
        negative: "hidden",
        content:
          <Fragment>
            <div>분석 중인 프로젝트는 삭제 하실 수 없습니다.</div>
            <br />
            <div>모든 프로젝트가 분석 중인 프로젝트입니다.</div>
          </Fragment>
      })
      return;
    }

    let list: number[] = [];
    let message: string[] = [];
    type MessageType = {
      "분석중 프로젝트": boolean,
      "즐겨찾기 프로젝트": boolean,
      "완료된 프로젝트": boolean,
      "확인요 프로젝트": boolean,
    }
    let messageType = {
      "분석중 프로젝트": false,
      "즐겨찾기 프로젝트": false,
      "완료된 프로젝트": false,
      "확인요 프로젝트": false,
    }

    if (!this.props.projects) {
      return;
    }

    for (let i = 0; i < this.props.selectedProject.length; i++) {
      if (this.props.selectedProject[i].checked) {
        if (this.props.projects[i].status === "WAITING" || this.props.projects[i].status === "RUNNING") {
          messageType["분석중 프로젝트"] = true;
        } else {
          list.push(this.props.selectedProject[i].user_project_id);
        }
      }
    }
    Object.keys(messageType).forEach(e => { messageType[e as keyof MessageType] && message.push(e) });

    this.props.setModal(true, this.props.getModalOption("DISCARD"))
  }
  allDeleteProjects = () => {
    this.props.setModal(true, this.props.getModalOption("ALL_DELETE"));
  }

  deleteProject = () => {
    if (this.props.selectedProject.map(r => r.checked && 1 || 0).reduce((a: any, b: any) => a + b) === 0) {
      this.props.setModal(true, {
        color: "DARK",
        type: "SIMPLE",
        positive: () => {
          this.props.setModal(false);
        },
        negative: "hidden",
        content: <div>선택된 프로젝트가 없습니다.</div>
      })
      return;
    }

    this.props.setModal(true, this.props.getModalOption("DELETE"));
  }

  restoreProject = async () => {
    if (this.props.selectedProject.map(r => r.checked && 1 || 0).reduce((a: any, b: any) => a + b) === 0) {
      this.props.setModal(true, {
        color: "DARK",
        type: "SIMPLE",
        positive: () => {
          this.props.setModal(false);
        },
        negative: "hidden",
        content: <div>선택된 프로젝트가 없습니다.</div>
      })
      return;
    }

    this.props.setModal(true, this.props.getModalOption("RESTORE"));


  }

  copyProject = () => {
    let list: Array<{ project: Project, discountable: boolean }> = [];
    // let discountChecked: boolean[] = [];

    if (this.props.selectedProject.map(r => r.checked && 1 || 0).reduce((a: any, b: any) => a + b) === 0) {
      this.props.setModal(true, {
        color: "DARK",
        type: "SIMPLE",
        positive: () => {
          this.props.setModal(false);
        },
        negative: "hidden",
        content: <div>선택된 프로젝트가 없습니다.</div>
      })
      return;
    }

    if (!this.props.projects) {
      App.stage !== "prod" && console.log("projects 가 없습니다.")
      return;
    }

    for (let i = 0; i < this.props.selectedProject.length; i++) {
      if (this.props.selectedProject[i].checked && this.props.projects[i].project_type !== "CAL") {
        list.push({
          project: this.props.projects[i],
          discountable: this.props.selectedProject[i].discountable,
        });
      }
    }

    if (list.length === 0 && this.props.selectedProject.map(r => Number(r.checked)).reduce((a, b) => a + b, 0)) {
      this.props.setModal(true, this.props.getModalOption("COPY"))
      return;
    }
    this.props.setModal(true, this.props.getModalOption("COPY"));
  }
}
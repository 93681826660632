import React, { Component } from "react";
import * as THREE from "@teneleven/three";
import "./css/CADConverter/CadConverterTemplate.scss";
import "@teneleven/protocols-ts-web";
import { Button } from "@material-ui/core";

import { ConverterLayer, buildingTypeStruct, ListType, saveState, CompletenessType, ConverterType, PartOfSelect } from "./CADConverter/DataTypes";
import { asyncFileRead, blockParsing, loadMetaFile } from "./CADConverter/FileParser";
import { saveDataToS3, saveDataToDynamoDB, checkFileName, checkSpecialSymbolInName, makeBlockBuildingData, getMetaData } from "./CADConverter/DBManager";
import { SceneManager } from "./CADConverter/SceneManager";
import { brightenAllLayer, darkenAllLayer } from "./CADConverter/CoreAndHouseController";
import { Modal, ModalProps, buttonNum } from "./CADConverter/Modal";
import { incrementCounter } from "./Utils";
import { MakeBuildingMesh } from "./Viewer/MeshManager";
import App from "./App";
import queryString from "query-string";
import { ConverterHeader } from "./CADConverter/ConverterHeader";
import { DropFileBox } from "./CADConverter/DropFileToLoadBox";
import { SaveViewerModal } from "./CADConverter/SaveViewerModal";
import { LoadingPage } from "./CADConverter/LoadingPage";
import { userSettingData, Setting } from "./CADConverter/SettingModal";
import { House } from "./CADConverter/House";
import { ErrorLog, ErrorLogCell, ErrorType, makeWarningInformation } from "./CADConverter/ErrorLog";
import _ from "lodash";
import { ConverterBlock } from "./CADConverter/ConverterBlock";
import { BlockParsingData, BuildingComponent, BuildingPart } from "./CADConverter/BuildingPart";
import { BuildingPartUI } from "./CADConverter/BuildingPartUI";
import { BlockPanelUI } from "./CADConverter/BlockPanel";

const uuid4 = require("uuid/v4");

enum houseCoreTabs {
  house = "세대",
  core = "코어",
}

interface SceneProps {}

interface SceneState {
  layers: ConverterLayer[];
  is2D: boolean;
  screenWidth: number;
  screenHeight: number;
  fileName: string;
  topDirName: string;
  secondDirName: string;
  saveName: string;
  showLayer: boolean;
  listType: ListType;
  houseCoreTabs: houseCoreTabs;
  selectLayer: ConverterLayer | null;
  selectHouse: House | null;
  settingID: string;
  partOfHouse: PartOfSelect;
  totalExclusiveAreas: string;
  totalServiceAreas: string;
  totalCoreAreas: string;
  totalCommonWallAreas: string;
  buildingArea: string;
  groundArea: string;
  userID: string;
  showModal: boolean;
  showSaveModal: boolean;
  hideAuto: boolean;
  loadFile: boolean;
  showAutoSetting: boolean;
  loadingPage: boolean;
  showSample: boolean;
  showSettingModal: boolean;
  errorLog: boolean;
  errorSize: number;
  warningSize: number;
  houseNumber: number;
  loadDB: any;
  clickedBuildingPart: any;
}

export class Scene extends Component<SceneProps, SceneState> {
  state: SceneState = {
    layers: [],
    is2D: true,
    screenWidth: window.innerWidth,
    screenHeight: window.innerHeight - 122,
    fileName: "선택된 파일 없음",
    showLayer: true,
    selectLayer: null,
    settingID: "",
    totalCoreAreas: "0",
    totalExclusiveAreas: "0",
    totalServiceAreas: "0",
    totalCommonWallAreas: "0",
    buildingArea: "0",
    groundArea: "0",
    listType: ListType.layer,
    houseCoreTabs: houseCoreTabs.house,
    selectHouse: null,
    saveName: "",
    topDirName: "",
    secondDirName: "",
    userID: "test@1011.co.kr",
    showModal: false,
    showSaveModal: false,
    hideAuto: false,
    loadFile: false,
    showAutoSetting: false,
    partOfHouse: PartOfSelect.wall,
    loadingPage: false,
    showSample: true,
    showSettingModal: false,
    errorLog: false,
    errorSize: 0,
    warningSize: 0,
    houseNumber: 0,
    loadDB: null,
    clickedBuildingPart: null,
  };

  mount: HTMLDivElement | null = null;

  sceneManager = new SceneManager();

  polygon2DGroup = new THREE.Group();
  polygon3DGroup = new THREE.Group();

  bbox = new THREE.Box3();

  S3BucketName = "platform-buildit-building-type-agent-test";
  DBTableName = "platform-buildit-my-building-type-v2";
  ModalProps: ModalProps = {
    content: ["내용"],
    buttonNum: buttonNum.twoButton,
    open: true,
    positive: () => App.stage !== "prod" && console.log("test"),
    title: "제목",
  };
  fileData: string = "";

  houseCount = 0;
  coreCount = 0;
  errorLogs: ErrorLogCell[][] = [];
  loadFileErrorLogs: ErrorLogCell[] = [];
  globalErrorLogs: ErrorLogCell[] = [];
  parsingOutput: BlockParsingData = { buildings: [], fields: [] };
  block: ConverterBlock[] = [];
  settingData = new userSettingData(App.stage, App.session.email);

  animate = () => {
    requestAnimationFrame(this.animate);
    this.sceneManager.render();
  };

  updateInfoPosition = (id: string, center: THREE.Vector3, completeness: CompletenessType) => {
    let s = document.querySelector(`.TT${id}`) as HTMLInputElement;
    if (s) {
      let c = this.sceneManager.getScreenPosition(center);
      let rect = s.getBoundingClientRect();
      let min = new THREE.Vector2(c.x - rect.width / 2, c.y - rect.height / 2);
      let max = new THREE.Vector2(c.x + rect.width / 2, c.y + rect.height / 2);

      if (!(completeness !== CompletenessType.error) || !this.sceneManager.canvasBox2.containsPoint(min) || !this.sceneManager.canvasBox2.containsPoint(max)) s.style.visibility = "hidden";
      else s.style.visibility = "visible";

      s.style.top = c.y.toString() + "px";
      s.style.left = c.x.toString() + "px";
    }
  };

  componentDidMount = async () => {
    this.mount!.appendChild(this.sceneManager.canvasElement);

    this.sceneManager.SceneInit();

    this.sceneManager.addObjectToScene(this.polygon2DGroup);
    this.sceneManager.addObjectToScene(this.polygon3DGroup);

    this.sceneManager.addObjectToScene(new THREE.AmbientLight(0xffffff, 0.5));
    let light = new THREE.DirectionalLight();
    light.position.set(10, 10, 10);
    this.sceneManager.addObjectToScene(light);
    this.sceneManager.addObjectToScene(light.target);

    this.animate();

    window.addEventListener("resize", this.onWindowResize, false);
    window.addEventListener("keyup", this.onKeyUp, false);
    App.stage !== "prod" && console.log(this.sceneManager.canvasElement.getBoundingClientRect());

    this.settingData.getDataFromDB();
    this.errorLogs.push(this.globalErrorLogs);
    this.errorLogs.push(this.loadFileErrorLogs);

    const qs = queryString.parse(window.location.search);
    if (qs.id) {
      let item = await getMetaData(this.DBTableName, qs.id as string);
      this.block = await loadMetaFile(item.meta_path, this.parsingOutput.buildings, this.settingData.dataUnit);
      console.log(item);
      this.parsingOutput.buildings.forEach((b) => {
        this.polygon2DGroup.add(b.renderGroup);
      });

      this.recalculateResult();

      this.bbox.makeEmpty();
      this.bbox.setFromObject(this.polygon2DGroup);
      let center = new THREE.Vector3();
      this.bbox.getCenter(center);

      this.sceneManager.orthoCamera.position.set(center.x, center.y, 1);
      this.sceneManager.orthoControl.target.set(center.x, center.y, 0);
      this.sceneManager.orthoCamera.zoom = 1;

      let aspect = this.state.screenWidth / this.state.screenHeight;
      this.sceneManager.CameraFrustumResize(this.getFrustumSize(aspect), aspect);

      this.setState(
        {
          fileName: item.name,
          saveName: item.name,
          loadingPage: false,
          loadFile: true,
          loadDB: item,
        },
        () => this.onWindowResize()
      );
    }

    // var axesHelper = new THREE.AxesHelper(5);
    // this.sceneManager.addObjectToScene(axesHelper);

    const r = await App.search({
      table: this.DBTableName,
      query: `stage.keyword:${App.stage} AND email.keyword:${App.session.email} AND deleted:false`,
    });

    this.setState({
      userID: App.session.email,
      showSample: r.data.hits.total > 0 ? true : false,
    });
  };

  componentDidUpdate = (previousProps: Readonly<SceneProps>, previousState: Readonly<SceneState>) => {
    if (previousState.screenWidth !== this.state.screenWidth || previousState.screenHeight !== this.state.screenHeight || previousState.showLayer !== this.state.showLayer) {
      let width = this.state.screenWidth;
      let height = this.state.screenHeight;

      if (!this.state.showLayer) {
        width -= 235;
      }

      let aspect = width / height;

      this.sceneManager.renderer.setSize(width, height);
      this.sceneManager.CameraFrustumResize(this.getFrustumSize(aspect), aspect);
    }

    if (previousState.errorLog !== this.state.errorLog) {
      this.onWindowResize();
    }
  };

  componentWillUnmount = () => {
    this.mount!.removeChild(this.sceneManager.canvasElement);
  };

  onKeyUp = (event: KeyboardEvent) => {
    switch (event.key) {
      case "a":
        // this.autoSetHouse();
        break;
      default:
        break;
    }
  };

  loadFilebyFile = async (file: any) => {
    this.setState({ loadingPage: true });

    if (!file[0].name.endsWith(".dxf")) {
      return;
    }

    let data = await asyncFileRead(file);
    if (!data) return;

    this.polygon2DGroup.children = [];
    this.polygon3DGroup.children = [];
    this.parsingOutput.buildings = [];

    while (this.loadFileErrorLogs.length > 0) {
      this.loadFileErrorLogs.splice(0, 1);
    }

    this.fileData = data;
    this.block = blockParsing(data, this.parsingOutput, this.settingData.dataUnit);

    this.parsingOutput.buildings.forEach((b) => {
      this.polygon2DGroup.add(b.renderGroup);
    });
    console.log(this.parsingOutput);
    this.recalculateResult();

    this.bbox.makeEmpty();
    this.bbox.setFromObject(this.polygon2DGroup);
    let center = new THREE.Vector3();
    this.bbox.getCenter(center);

    this.sceneManager.orthoCamera.position.set(center.x, center.y, 1);
    this.sceneManager.orthoControl.target.set(center.x, center.y, 0);
    this.sceneManager.orthoCamera.zoom = 1;

    this.setState({
      fileName: file[0].name,
      saveName: file[0].name.substring(0, file[0].name.length - 4),
      loadingPage: false,
      loadFile: true,
    });
    this.onWindowResize();
    let aspect = this.state.screenWidth / this.state.screenHeight;
    this.sceneManager.CameraFrustumResize(this.getFrustumSize(aspect), aspect);
  };

  getFrustumSize = (aspect: number) => {
    let bboxSize = new THREE.Vector3(0);
    this.bbox.getSize(bboxSize);
    let frustumSize = (bboxSize.x / 2) * 1.1;
    if (aspect > bboxSize.x / bboxSize.y) {
      let height = (bboxSize.y / 2) * 1.1;
      frustumSize = height * aspect;
    }
    return frustumSize;
  };

  loadDXFFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files![0]) {
      this.loadFilebyFile(e.target.files);
    }
  };

  onWindowResize = () => {
    let width = window.innerWidth < 1200 ? 1200 : window.innerWidth;

    this.setState({
      screenWidth: width - (this.state.loadFile ? 420 : 0),
      screenHeight: window.innerHeight - 122 - (this.state.errorLog ? 222 : 0),
    });
  };

  recalculateResult = () => {
    let totalExclusiveAreas = 0;
    let totalServiceAreas = 0;
    let totalCommonWallAreas = 0;
    let totalCoreAreas = 0;
    let houseNumber = 0;

    this.parsingOutput.buildings.forEach((b) => {
      b.parts.forEach((p) => {
        p.UpdateArea();
        totalExclusiveAreas += p.totalExclusiveAreas;
        totalServiceAreas += p.totalServiceAreas;
        totalCommonWallAreas += p.totalCommonWallAreas;
        totalCoreAreas += p.totalCoreAreas;
        houseNumber += p.houseNumber;
      });
    });

    while (this.globalErrorLogs.length > 0) {
      this.globalErrorLogs.splice(0, 1);
    }

    let error = 0,
      waring = 0;
    this.errorLogs.forEach((els) => {
      els.forEach((el) => {
        if (el.Type === ErrorType.Error) error++;
        if (el.Type === ErrorType.Warning) waring++;
      });
    });

    this.setState({
      totalExclusiveAreas: totalExclusiveAreas.toFixed(2),
      totalServiceAreas: totalServiceAreas.toFixed(2),
      totalCoreAreas: totalCoreAreas.toFixed(2),
      totalCommonWallAreas: totalCommonWallAreas.toFixed(2),
      buildingArea: (totalExclusiveAreas + totalServiceAreas + totalCoreAreas + totalCommonWallAreas).toFixed(2),
      groundArea: (totalExclusiveAreas + totalCoreAreas + totalCommonWallAreas).toFixed(2),
      houseNumber: houseNumber,
      errorSize: error,
      warningSize: waring,
      errorLog: error + waring > 0 ? true : false,
    });
  };

  Switch2D3D = (is2D: boolean) => {
    if (this.state.errorSize > 0) {
      this.showModal("알림", ["코어와 세대의 세팅을 완성해 주세요."], buttonNum.oneButton, () => {});
      return;
    }

    this.sceneManager.switchRenderCamera(!is2D);

    if (is2D) {
      this.polygon3DGroup.children = [];
      this.setState({
        showLayer: true,
      });

      this.polygon3DGroup.add(this.makeReviewBuilding());
      let bbox = new THREE.Box3();
      bbox.expandByObject(this.polygon3DGroup);
      let bboxCenter = new THREE.Vector3();
      bbox.getCenter(bboxCenter);
      this.sceneManager.set3DViewerCameraPosition(bboxCenter);
    }

    this.polygon2DGroup.children.forEach((c) => {
      c.visible = !is2D;
    });

    this.polygon3DGroup.children.forEach((c) => {
      c.visible = is2D;
    });

    this.setState({
      is2D: !is2D,
    });
  };

  makeReviewBuilding = () => {
    this.parsingOutput.buildings[0].parts.forEach((p) => {
      p.RebuildOutputPolygon();
    });

    let building = makeBlockBuildingData(this.parsingOutput.buildings[0]);

    building.floorHeight = [2.8, 2.8, 2.8, 2.8, 2.8, 2.8, 2.8, 2.8, 2.8, 2.8];
    building.floorStatus.forEach((fs) => {
      fs.coreFloorInfo.forEach((fi) => {
        fi.floorCategory = ["FC_CORE", "FC_CORE", "FC_CORE", "FC_CORE", "FC_CORE", "FC_CORE", "FC_CORE", "FC_CORE", "FC_CORE", "FC_CORE"];
      });
      fs.houseFloorInfo.forEach((hi) => {
        hi.floorCategory = ["FC_HOUSE", "FC_HOUSE", "FC_HOUSE", "FC_HOUSE", "FC_HOUSE", "FC_HOUSE", "FC_HOUSE", "FC_HOUSE", "FC_HOUSE", "FC_HOUSE"];
      });
    });

    return MakeBuildingMesh(building);
  };

  checkSaveState = async () => {
    let fn = this.state.saveName === "" ? "my_building_type" : this.state.saveName;
    let information: string[] = [];
    let state: saveState = saveState.complete;
    if (checkSpecialSymbolInName(fn)) {
      information.push('제목에 사용할 수 없는 특수 문자("/)가 포함되어 있습니다.');
      state = saveState.error;
    }

    let checkedName = await checkFileName(fn, this.state.userID, this.DBTableName);
    if (!checkedName && !this.state.loadDB) {
      information.push("이미 같은 제목을 가진 나의 동평면이 존재합니다.");
      state = saveState.error;
    }

    switch (state as saveState) {
      case saveState.complete:
        this.saveToAWS(fn);
        break;
      case saveState.warning:
        information.push("계속 진행 하시겠습니까??");
        this.showModal("알림", information, buttonNum.twoButton, () => this.saveToAWS(fn));
        break;
      case saveState.error:
        this.showModal("경고", information, buttonNum.oneButton, () => console.log("error"));
        break;
      default:
        break;
    }
  };

  makeSaveMetaFile = async (savePath: string) => {
    let meta = {
      blocks: this.block.map((b) => b.toJson()),
      entities: this.parsingOutput.buildings.map((b) => b.toJson()),
    };

    saveDataToS3(JSON.stringify(meta), savePath, "meta.json", "application/json");
  };

  saveToAWS = async (fn: string) => {
    let uuid = uuid4();
    App.stage !== "prod" && console.log(uuid);
    let globalID, privateID;
    if (this.state.loadDB) {
      globalID = this.state.loadDB.global_id;
      privateID = this.state.loadDB.user_id;
    } else {
      let id = await incrementCounter("my_building_type_id", this.state.userID);
      globalID = id.globalId;
      privateID = id.userId;
    }
    App.stage !== "prod" && console.log(globalID, privateID);
    
    let imageName = `img_large.png`;
    let buildingTemplateName = `${this.state.saveName}.json`;
    let S3SavePath = `${this.S3BucketName}/${this.state.topDirName}/${this.state.secondDirName}`;
    // this.makeSaveMetaFile(S3SavePath);

    let captureBbox = new THREE.Box3();
    captureBbox.expandByObject(this.parsingOutput.buildings[0].renderGroup);

    // capture image
    if (!this.state.is2D) {
      this.Switch2D3D(false);
    }

    let lImgBuf = this.sceneManager.getScreenCapture(256, 256, captureBbox);
    let mImgBuf = this.sceneManager.getScreenCapture(128, 128, captureBbox);
    let sImgBuf = this.sceneManager.getScreenCapture(64, 64, captureBbox);

    // saveDataToS3(lImgBuf, S3SavePath, imageName, "image/png");
    // saveDataToS3(mImgBuf, S3SavePath, "img_middle.png", "image/png");
    // saveDataToS3(sImgBuf, S3SavePath, "img_small.png", "image/png");

    // template data
    this.parsingOutput.buildings[0].parts.forEach((p) => {
      p.RebuildOutputPolygon();
      p.UpdateArea();
    });

    let outputData = makeBlockBuildingData(this.parsingOutput.buildings[0]);
    outputData.building.name = `${this.state.saveName}`;
    App.stage !== "prod" && console.log(outputData.building);

    // dynamoDB data
    let data = new Date().toISOString();
    let dbItem: buildingTypeStruct = {
      stage: App.stage,
      global_id: globalID,
      user_id: privateID,
      uuid: uuid,
      name: fn,
      email: this.state.userID,
      total_exclusive_area: Number(this.state.totalExclusiveAreas),
      total_service_area: Number(this.state.totalServiceAreas),
      core_area: Number(this.state.totalCoreAreas),
      building_area: Number(this.state.buildingArea),
      floor_area: Number(this.state.groundArea),
      common_wall_area: Number(this.state.totalCommonWallAreas),
      houses_number: this.state.houseNumber,
      img_path: `s3://${S3SavePath}/${imageName}`,
      file_path: `s3://${S3SavePath}/${buildingTemplateName}`,
      meta_path: `s3://${S3SavePath}`,
      created_at: data,
      modified_at: data,
      deleted: false,
    };
    App.stage !== "prod" && console.log(dbItem);
    // saveDataToS3(this.fileData, S3SavePath, "file.dxf", "");
    saveDataToS3(JSON.stringify(outputData.building), S3SavePath, buildingTemplateName, "application/json");
    // saveDataToDynamoDB(dbItem, this.DBTableName);

    this.showModal("알림", ["나의 동평면을 저장했습니다."], buttonNum.oneButton, () => console.log("saved"));
  };

  setSelectLayer = (click: ConverterLayer) => {
    if (click.errorLayer) {
      this.showModal("알림", ["문제 있는 레이어를 선택했습니다."], buttonNum.oneButton, () => console.log("error layer"));
      return;
    }

    if (!this.state.selectLayer || click !== this.state.selectLayer) {
      this.setState({ selectLayer: click }, this.recalculateResult);
    } else {
      this.setState({ selectLayer: null }, () => this.setState({ selectLayer: click }, this.recalculateResult));
    }
  };

  setSelectHouse = (click: House) => {
    if (!this.state.selectHouse || click !== this.state.selectHouse) {
      this.setState({ selectHouse: click });
    } else {
      this.setState({ selectHouse: null }, () => this.setState({ selectHouse: click }));
    }
  };

  showModal = (title: string, content: string[], buttonNum: buttonNum, func: () => void) => {
    this.ModalProps.title = title;
    this.ModalProps.content = content;
    this.ModalProps.buttonNum = buttonNum;
    this.ModalProps.positive = func;

    this.setState({
      showModal: !this.state.showModal,
    });
  };

  clickBlockUI = (buildingPart: BuildingPart) => {
    this.setState({ clickedBuildingPart: buildingPart });
  };

  render = () => {
    return (
      <React.Fragment>
        <Modal content={this.ModalProps.content} title={this.ModalProps.title} open={this.state.showModal} buttonNum={this.ModalProps.buttonNum} positive={this.ModalProps.positive}></Modal>
        <SaveViewerModal open={this.state.showSaveModal} buildingObject={this.makeReviewBuilding} positive={this.checkSaveState} />
        <LoadingPage show={this.state.loadingPage} type={ConverterType.myType} />
        <Setting closeModal={() => this.setState({ showSettingModal: false })} open={this.state.showSettingModal} settingData={this.settingData} />
        <ConverterHeader
          type={ConverterType.myType}
          email={App.session.email}
          loadFile={this.loadDXFFile}
          reset={() => {}}
          errorSize={this.state.errorSize}
          warningSize={this.state.warningSize}
          saveFile={() => this.setState({ showSaveModal: !this.state.showSaveModal })}
          openSettingModal={() => this.setState({ showSettingModal: true })}
          showErrorLog={() => this.setState({ errorLog: !this.state.errorLog })}
          showModal={this.showModal}
        />
        <div className="MainBody">
          <div className="areaInfo" hidden={!this.state.is2D}>
            {/* {this.houses.map(h =>
              <div className={`textTest TT${h.id}`} key={h.id}>
                <div>방:{h.name}</div>
                <div>전용 면적: {h.exclusiveArea}㎡</div>
                <div>서비스 면젹: {h.serviceArea}㎡</div>
                <div>벽체고용 면젹: {h.commonWallArea}㎡</div>
              </div>
            )}
            {this.cores.map(c =>
              <div className={`textTest TT${c.id}`} key={c.id}>
                <div>코어:{c.name}</div>
                <div>코어 면젹: {c.area}㎡</div>
              </div>
            )} */}
          </div>

          <div className="information">
            <div className="info shotWidth">
              <div className="infoLabel">세대수</div>
              <div className="inforValue">{this.state.houseNumber}</div>
            </div>
            <div className="info">
              <div className="infoLabel">총 전용 면적</div>
              <div className="inforValue">{this.state.totalExclusiveAreas}㎡</div>
            </div>
            <div className="info">
              <div className="infoLabel">총 서비스 면적</div>
              <div className="inforValue">{this.state.totalServiceAreas}㎡</div>
            </div>
            <div className="info">
              <div className="infoLabel">총 벽체공용 면적</div>
              <div className="inforValue">{this.state.totalCommonWallAreas}㎡</div>
            </div>
            <div className="info">
              <div className="infoLabel">코어 면적</div>
              <div className="inforValue">{this.state.totalCoreAreas}㎡</div>
            </div>
            <div className="info">
              <div className="infoLabel">건축 면적</div>
              <div className="inforValue">{this.state.buildingArea}㎡</div>
            </div>
            <div className="info longWidth">
              <div className="infoLabel">바닥 면적(연면적산출용)</div>
              <div className="inforValue">{this.state.groundArea}㎡</div>
            </div>
            <div className="fileInfo">
              <span className={`dxfIcon ${this.state.loadFile && "loaded"}`}>
                <span className={`text ${this.state.loadFile && "loaded"}`}>dxf</span>
              </span>
              <span className={`fileName ${this.state.loadFile && "loaded"}`}>{(this.state.loadFile && this.state.fileName) || "선택된 파일이 없습니다."}</span>
              <span className={`layerToggle ${this.state.loadFile && "loaded"}`} onClick={() => this.state.loadFile && this.setState({ showLayer: !this.state.showLayer })}>
                레이어 보기
              </span>
            </div>
          </div>
          <div className="RenderView">
            <div className="Scene">
              <div
                className="Canvas"
                ref={(mount) => {
                  this.mount = mount;
                }}
              >
                <DropFileBox functionForButton={this.loadDXFFile} functionForDrop={this.loadFilebyFile} loadFile={this.state.loadFile} type={ConverterType.myType} showSample={this.state.showSample} />
                <div className={`toolBar ${!this.state.loadFile && "hidden"}`}>
                  <div className="switchTabs">
                    <Button className={`switchButton ${this.state.is2D && "enable"}`} onClick={() => this.Switch2D3D(false)}>
                      2D
                    </Button>
                    <Button className={`switchButton ${!this.state.is2D && "enable"}`} onClick={() => this.Switch2D3D(true)}>
                      3D
                    </Button>
                  </div>
                </div>
                <div className={`autosetDiv ${!this.state.showAutoSetting && "hidden"}`}>
                  <Button className="autosetButton" onClick={() => {}}>
                    자동 추가
                  </Button>
                  <div className="autosetMessage">정해진 규칙에 따라 생성된 레이어는 변환 설정값을 자동으로 처리합니다 (상세한 규칙은 메뉴얼을 참고해주세요) </div>
                </div>
              </div>
              {/* <Help className='helpIcon' onClick={() => console.log('help')} /> */}
              <ErrorLog
                show={this.state.errorLog}
                closeLog={() => this.setState({ errorLog: !this.state.errorLog })}
                logs={this.errorLogs}
                warningSize={this.state.warningSize}
                errorSize={this.state.errorSize}
                brightenAllLayer={() => brightenAllLayer(this.state.layers)}
                darkenAllLayer={() => darkenAllLayer(this.state.layers)}
              />
            </div>
            <div className={`mainLayout ${!this.state.loadFile && "hidden"}`}>
              <div className="myTypeLayerLayout">
                {this.parsingOutput.buildings.map((b) => {
                  return (
                    <div key={b.uuid} style={{ cursor: "pointer", color: "white" }}>
                      <div className="name" onClick={() => this.setState({ clickedBuildingPart: b })}>
                        {b.name}
                      </div>
                      {b.parts.map((p) => {
                        return <BlockPanelUI key={p.uuid} compoent={p} clickComponent={this.clickBlockUI} />;
                      })}
                    </div>
                  );
                })}
              </div>
              <div className="functionalLayout">
                <div className="fileNameLayout lbrBorder">
                  <div className="titleText">나의 동평면 제목</div>
                  <input type="text" value={this.state.topDirName} onChange={(e) => this.setState({ topDirName: e.target.value })} placeholder="최상위 타입(diamond)"></input>
                  <input type="text" value={this.state.secondDirName} onChange={(e) => this.setState({ secondDirName: e.target.value })} placeholder="크기 타입(ex: 84)"></input>
                  <input type="text" value={this.state.saveName} onChange={(e) => this.setState({ saveName: e.target.value })} placeholder="동평면 이름"></input>
                </div>
                <div className="bodyLayout lbrBorder">
                  <BuildingPartUI updateArea={this.recalculateResult} compoent={this.state.clickedBuildingPart} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  };
}

import * as THREE from "@teneleven/three";
import {
  CheckColsedPolygon,
  CheckPolygonOverlap,
  ChekPolygonsApart,
  getConverterLayerArea,
  GetJSTSPolygonFormLayer,
  GetJSTSUnionPolygonFormLayer,
  GetLayerOverlapState,
  GetPolygonCentroid,
  JSTSGeoToTHREEGeo,
  switchLayerState,
} from "./CoreAndHouseController";
import {
  ConverterLayer,
  CompletenessType,
  PolylineInfo,
  LineType,
} from "./DataTypes";
import {
  ErrorLogCell,
  ErrorType,
  makeErrorInformation,
  makeWarningInformation,
} from "./ErrorLog";
import { House } from "./House";
import { getErrorLine } from "./MeshMaker";
import userSettingData from "./SettingModal";

const uuid4 = require("uuid/v4");

export class Core {
  id: string;
  core: ConverterLayer | null;
  houses: House[];
  name: string;
  area: number;
  outputPolygon: any[];
  level: boolean[];
  levelHeights: number[];
  finalLines: any[];
  centerOfAllLine: THREE.Vector3;
  complete: CompletenessType;
  ErrorLog: ErrorLogCell[];
  ErrorPolygonGroup: THREE.Group;

  constructor(name: string) {
    this.id = uuid4();
    this.core = null;
    this.name = name;
    this.houses = [];
    this.area = 0;
    this.outputPolygon = [];
    this.level = [true];
    this.levelHeights = [2.8];
    this.finalLines = [];
    this.centerOfAllLine = new THREE.Vector3(0);
    this.complete = CompletenessType.error;
    this.ErrorLog = [];
    this.ErrorPolygonGroup = new THREE.Group();
  }

  addHouseToList = (house: House) => {
    if (this.houses.indexOf(house) > -1) {
      this.deleteHouseWithHouseId(house.id);
    } else {
      if (!house.selected) {
        house.selected = true;
        this.houses.push(house);
      }
    }
    this.sortHouses();
    this.CheckCompleteness();
  };

  deleteHouseWithHouseId = (id: string) => {
    let index = this.houses.findIndex((h) => h.id === id);
    if (index >= 0) {
      this.houses[index].selected = false;
      this.houses.splice(index, 1);
    }
    this.CheckCompleteness();
  };

  deleteCore = () => {
    if (this.core) {
      switchLayerState(this.core);
    }

    while (this.houses.length > 0) {
      this.houses[0].selected = false;
      this.houses.splice(0, 1);
    }

    while (this.ErrorLog.length > 0) {
      this.ErrorLog.splice(0, 1);
    }
  };

  sortHouses = () => {
    this.houses.sort(
      (a, b) => Number(a.name.match(/\d+/g)) - Number(b.name.match(/\d+/g))
    );
  };

  getCoreLayerArea = () => {
    if (this.core) {
      return getConverterLayerArea(this.core);
    } else return 0;
  };

  getSettedLayer = () => {
    let layerArray = [];
    if (this.core) layerArray.push(this.core);
    return layerArray;
  };

  CheckCompleteness = () => {
    // console.log("CheckCompleteness");
    let completeness = CompletenessType.complete;

    while (this.ErrorLog.length > 0) {
      this.ErrorLog.splice(0, 1);
    }

    if (!this.core) {
      completeness = CompletenessType.error;
      this.ErrorLog.push(
        makeErrorInformation(`${this.name}코어의 코어 레이어를 추가해 주세요.`)
      );
    } else {
      // 폴리곤 이격/중복 체크
      if (GetLayerOverlapState(this.core!)) {
        this.ErrorLog.push(
          makeWarningInformation(
            `${this.core!.name}에 폴리곤이 중복되어 있습니다.`
          )
        );
      }

      let corePoly = GetJSTSUnionPolygonFormLayer(this.core!);
      this.houses.forEach((h) => {
        if (h.complete !== CompletenessType.error) {
          let housePoly = GetJSTSUnionPolygonFormLayer(h.wall!);
          if (ChekPolygonsApart([corePoly], [housePoly])) {
            let wallCenter = GetPolygonCentroid(h.wall!);
            let line = getErrorLine(this.centerOfAllLine, wallCenter);
            this.ErrorPolygonGroup.add(line);
            this.ErrorLog.push(
              makeWarningInformation(
                `${h.name}와 ${this.core!.name}가 서로 떨어져 있습니다.`,
                "",
                line
              )
            );
          }
          let overlap = CheckPolygonOverlap([housePoly, corePoly]);
          if (overlap) {
            let areaOffset = userSettingData.myTypeSettingData.layerOverlap
              .enable
              ? 0
              : userSettingData.myTypeSettingData.layerOverlap.value / 100;
            if (overlap.getArea() / this.getCoreLayerArea() > areaOffset) {
              let group = new THREE.Group();
              let mesh = JSTSGeoToTHREEGeo(overlap);
              mesh.visible = false;
              group.add(mesh);
              this.ErrorPolygonGroup.add(group);
              this.ErrorLog.push(
                makeWarningInformation(
                  `${h.name}와 ${
                    this.core!.name
                  }의 폴리곤이 중복되어 있습니다.`,
                  "",
                  group
                )
              );
            }
          }
        }
      });

      // 면적 오차 오류 체크
      let area = this.getCoreLayerArea();
      if (!userSettingData.myTypeSettingData.areaOffset.enable) {
        let settingValue =
          userSettingData.myTypeSettingData.areaOffset.value / 100;
        let inputArea = this.area;
        // let maxAlpha = settingValue * 30; // legacy 코드
        // let minAlpha = 1 / maxAlpha; // legacy 코드
        /*
          Error영역 설정 변경 21.07.07.
          area보다 inputArea가 2배 이상 크거나, 10% 이하 크기인 경우 Error로 판단.
        */
        let maxAlpha = 2; // area의 2배
        let minAlpha = 0.1; // area의 10%크기

        if (inputArea >= area * maxAlpha || inputArea <= area * minAlpha) {
          this.ErrorLog.push(
            makeErrorInformation(
              `${this.name}의 입력면적과 실제 폴리곤 면적이 서로 상이합니다. 단위를 확인 하신 후 다시 진행해 주세요.`,
              `입력 면적: ${this.area.toFixed(2)}㎡, 계산 면적: ${area.toFixed(
                2
              )}㎡, 차이: ${Math.abs(area - this.area).toFixed(2)}㎡`,
              new THREE.Group(),
              this.getSettedLayer()
            )
          );
        }
        if (
          inputArea <= area * (1 - settingValue) ||
          inputArea >= area * (1 + settingValue)
        ) {
          inputArea === area && alert("");
          this.ErrorLog.push(
            makeWarningInformation(
              `${this.name}의 입력면적과 실제 폴리곤 면적이 서로 상이합니다.`,
              `입력 면적: ${this.area.toFixed(2)}㎡, 계산 면젹: ${area.toFixed(
                2
              )}㎡, 차이: ${Math.abs(area - this.area).toFixed(2)}㎡`,
              new THREE.Group(),
              this.getSettedLayer()
            )
          );
        }
      }
    }

    if (this.houses.length === 0) {
      completeness = CompletenessType.error;
      this.ErrorLog.push(
        makeErrorInformation(
          `${this.name}코어의 연결된 세대가 없습니다.`,
          "",
          new THREE.Group(),
          this.getSettedLayer()
        )
      );
    }

    let error = 0,
      waring = 0;
    this.ErrorLog.forEach((el) => {
      if (el.Type === ErrorType.Error) error++;
      if (el.Type === ErrorType.Warning) waring++;
    });

    if (error > 0) this.complete = CompletenessType.error;
    else if (error === 0 && waring > 0)
      this.complete = CompletenessType.warning;
    else this.complete = CompletenessType.complete;

    if (this.complete !== CompletenessType.error) {
      this.core!.polygons.forEach((p) => {
        p.innerMesh.visible = true;
        p.lineMesh.material.color = new THREE.Color(0xffffff);
      });
    } else if (completeness === CompletenessType.error) {
      if (this.core) {
        this.core.polygons.forEach((p) => {
          p.innerMesh.visible = false;
          //@ts-ignore
          p.lineMesh.material.color = new THREE.Color().set(this.core.color);
        });
      }
    }

    this.complete = completeness;
    return completeness;
  };

  setCore = (layer: ConverterLayer | null) => {
    if (!layer) return;
    if (!CheckColsedPolygon(layer)) return;

    let exLayer = this.core;
    if (layer === this.core) {
      this.core = null;
    } else {
      this.core = layer;
      switchLayerState(layer);
      this.centerOfAllLine = GetPolygonCentroid(layer);
    }

    if (exLayer) switchLayerState(exLayer);
    this.makeOutputPolygons();
    this.CheckCompleteness();
  };

  setCoreArea = (value: number) => {
    this.area = value;
    this.CheckCompleteness();
  };

  makeOutputPolygons = () => {
    if (!this.core) return;

    let lines = new Array<PolylineInfo>();

    this.core.polygons.forEach((p) => {
      if (p.vertices.length > 2) {
        for (let i = 0; i < p.vertices.length - 1; i++) {
          lines.push({
            line: new THREE.Line3(p.vertices[i], p.vertices[i + 1]),
            thickness: 0.6,
            type: LineType.LT_COREOUTERWALL,
          });
        }
      }
    });

    this.outputPolygon = lines;
  };
}
